
import FrontPageCSS from './FrontPageContainer.module.css';

type FrontPageProps = {
    children?: any;
}


export const FrontPageContainer = (props: FrontPageProps) => {
    return (
        <div className={FrontPageCSS.div}>
            {props.children}
        </div>
    )
}

