import styles from './DataInputs.module.css';
import React, { useEffect, useState, useRef, useCallback  } from 'react';
//import createWasmModule from './price_opt.js';
import {default as createWasmModule} from './price_opt.js';
import Dropdown from 'react-bootstrap/Dropdown';


type DataInputsProps = {
    data: any;
    updateChartData: any;
    callData : any;
    putData : any;
    setCallData : any;
    setPutData : any;
    spotPriceArray : any;
    setSpotPriceArray : any;
    volatilityPriceArray : any;
    setVolatilityPriceArray : any;
    setCallDataSingle : any;
    setPutDataSingle : any;
    children?: any;
}

export const DataInputs = (props: DataInputsProps) => {
    const {
        data,
        updateChartData,
        setCallDataSingle,
        setPutDataSingle,
        setCallData,
        setPutData,
        setSpotPriceArray,
        setVolatilityPriceArray,
        children
    } = props;



 

    // Local state for each input field (initialized with props.data)
    const [currentAssetPrice, setCurrentAssetPrice] = useState(data[0]);
    const [strikePrice, setStrikePrice] = useState(data[1]);
    const [timeToMaturity, setTimeToMaturity] = useState(data[2]);
    const [volatility, setVolatility] = useState(data[3]);
    const [riskFreeRate, setRiskFreeRate] = useState(data[4]);
    const [minSpotPrice, setMinSpotPrice] = useState(data[5]);
    const [maxSpotPrice, setMaxSpotPrice] = useState(data[6]);
    const [minVolatility, setMinVolatility] = useState(data[7]);
    const [maxVolatility, setMaxVolatility] = useState(data[8]);
    const [modelName, setModelName] = useState("BlackScholes");

    const wasmModuleRef = useRef(null);
    const calculateOptionPriceRef = useRef<((spotPrice: number, strikePrice: number, riskFreeRate: number, volatility: number, timeToMaturity: number, optionType: string, modelName: string) => number) | null>(null);

    
    const calculatePrice = useCallback(() => {
        if (calculateOptionPriceRef.current) {
            
            var optionType = "call";
            var price = calculateOptionPriceRef.current(
                currentAssetPrice,
                strikePrice,
                riskFreeRate,
                volatility,
                timeToMaturity,
                optionType,
                modelName
            );
            setCallDataSingle([price]);

            optionType = "put";
            price = calculateOptionPriceRef.current(
                currentAssetPrice,
                strikePrice,
                riskFreeRate,
                volatility,
                timeToMaturity,
                optionType,
                modelName
            );
            setPutDataSingle([price]);

        } else {
            console.error('WebAssembly module is not yet loaded.');
        }
    }, [currentAssetPrice, strikePrice, riskFreeRate, volatility, timeToMaturity, setPutDataSingle, setCallDataSingle, modelName]);

    const generateGridData = useCallback(() => {
        const numPoints = 5;
        const spotPrices = Array.from({ length: numPoints }, (_, i) =>
            minSpotPrice + (maxSpotPrice - minSpotPrice) * i / (numPoints - 1)
        );
        const volatilities = Array.from({ length: numPoints }, (_, i) =>
            minVolatility + (maxVolatility - minVolatility) * i / (numPoints - 1)
        );
    
        // Update the spotPriceArray and volatilityPriceArray props
        setSpotPriceArray(spotPrices);
        setVolatilityPriceArray(volatilities);
    
        const newGridDataCall = [];
        const newGridDataPut = [];
        for (let i = 0; i < numPoints; i++) {
            for (let j = 0; j < numPoints; j++) {
                const spotPrice = spotPrices[i];
                const volatility = volatilities[j];
    
                if (calculateOptionPriceRef.current) {
                    const callPrice = calculateOptionPriceRef.current(
                        spotPrice,
                        strikePrice,
                        riskFreeRate,
                        volatility,
                        timeToMaturity,
                        "call",
                        modelName
                    );
                    newGridDataCall.push(callPrice);
    
                    const putPrice = calculateOptionPriceRef.current(
                        spotPrice,
                        strikePrice,
                        riskFreeRate,
                        volatility,
                        timeToMaturity,
                        "put",
                        modelName
                    );
                    newGridDataPut.push(putPrice);
                }
            }
        }
        console.log(newGridDataCall);
        setCallData(newGridDataCall);
        setPutData(newGridDataPut);
    }, [minSpotPrice, maxSpotPrice, minVolatility, maxVolatility, strikePrice, timeToMaturity, riskFreeRate, calculateOptionPriceRef, setSpotPriceArray, setVolatilityPriceArray, setCallData, setPutData, modelName]);
    

    // Function to initialize WebAssembly module and perform initial calculations
    const initializeWasmModule = async () => {
        if (!wasmModuleRef.current) {
            const module = await createWasmModule();
            console.log('Module created!');
            wasmModuleRef.current = module;
            calculateOptionPriceRef.current = module.cwrap('calculate_option_price', 'number', [
                'number', 'number', 'number', 'number', 'number', 'string', 'string'
            ]);

            // Perform initial calculations once the module is loaded
            calculatePrice();
            generateGridData();
        }
    };


    // Recalculate the price whenever any input value changes
    useEffect(() => {
        if (calculateOptionPriceRef.current) {
            calculatePrice();
            generateGridData();
        }
    }, [calculatePrice, generateGridData]);

    

    // Function to handle updating all data and sending it to the parent
    const handleUpdate = () => {
        const updatedData = [
            currentAssetPrice,
            strikePrice,
            timeToMaturity,
            volatility,
            riskFreeRate,
            minSpotPrice,
            maxSpotPrice,
            minVolatility,
            maxVolatility
        ];
        updateChartData(updatedData);
    };

    // Increment and decrement handlers
    const handleIncrement = (setter: React.Dispatch<React.SetStateAction<number>>, value: number, amount: number = 0.01) => {
        const newValue = (value + amount).toFixed(2);  // Round to 2 decimal places
        setter(parseFloat(newValue));
        handleUpdate();  // Call this after updating the state
    };

    const handleDecrement = (setter: React.Dispatch<React.SetStateAction<number>>, value: number, amount: number = 0.01) => {
        const newValue = (value - amount).toFixed(2);  // Round to 2 decimal places
        setter(parseFloat(newValue));
        handleUpdate();  // Call this after updating the state
    }; 

    // Call the initialization function immediately
    initializeWasmModule();

    const handleSelect = (eventKey: string | null) => {
        if (eventKey !== null) {
            setModelName(eventKey); // Set the state to the actual variable name
        }
    };

    const displayModelName = () => {
        // Map the internal state to the display value
        switch (modelName) {
          case 'BlackScholes':
            return 'Black Scholes';
          case 'Binomial':
            return 'Binomial';
          case 'MonteCarlo':
            return 'Monte Carlo';
          default:
            return modelName;
        }
      };

    return (
        <div className={styles.div}>
            <div className={styles.header}>
                <h2>{displayModelName()} Model Parameters</h2>
            </div>
            <div className={styles.parametersContainer}>
                <div className={styles.parameterContainer}>
                    <h5>Model Name</h5>
                    <div className={styles.parameter}>

                        <Dropdown onSelect={handleSelect} >
                            <Dropdown.Toggle className={styles.dropdown} id="dropdown-basic">
                            {displayModelName()} {/* Display formatted name */}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className={styles.dropdownMenu} >
                            {/* Set the actual value with eventKey, but display the formatted name */}
                            <Dropdown.Item eventKey="BlackScholes">Black Scholes</Dropdown.Item>
                            <Dropdown.Item eventKey="Binomial">Binomial</Dropdown.Item>
                            <Dropdown.Item eventKey="MonteCarlo">Monte Carlo</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                
                    
                <div className={styles.parameterContainer}>
                    <h5>Current Asset Price</h5>
                    <div className={styles.parameter}>
                        <div className={styles.parameterInputContainer}>
                            <input type="number"
                                className={styles.parameterInput}
                                value={currentAssetPrice}
                                onChange={(e) => {
                                    setCurrentAssetPrice(parseFloat(e.target.value))
                                    handleUpdate();
                                    }}
                                step="0.01"
                            />
                            <div className={styles.buttons}>
                                <button className={styles.buttonLeft}  onClick={() => handleIncrement(setCurrentAssetPrice, currentAssetPrice)}>+</button>
                                <button className={styles.buttonRight}  onClick={() => handleDecrement(setCurrentAssetPrice, currentAssetPrice)}>-</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.parameterContainer}>
                    <h5 >Strike Price</h5>
                    <div className={styles.parameter}>
                        <div className={styles.parameterInputContainer}>
                            <input type="number" className={styles.parameterInput}
                                value={strikePrice}
                                onChange={(e) => {
                                    setStrikePrice(parseFloat(e.target.value))
                                    handleUpdate();
                                    }}/>
                            <div className={styles.buttons}>
                                <button className={styles.buttonLeft}  onClick={() => handleIncrement(setStrikePrice, strikePrice)}>+</button>
                                <button className={styles.buttonRight}  onClick={() => handleDecrement(setStrikePrice, strikePrice)}>-</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.parameterContainer}>
                    <h5>Time to Maturity (Years)</h5>
                    <div className={styles.parameter}>
                        <div className={styles.parameterInputContainer}>
                        <input type="number" className={styles.parameterInput}
                                value={timeToMaturity}
                                onChange={(e) => {
                                    setTimeToMaturity(parseFloat(e.target.value))
                                    handleUpdate();
                                    }}/>
                            <div className={styles.buttons}>
                                <button className={styles.buttonLeft}  onClick={() => handleIncrement(setTimeToMaturity, timeToMaturity)}>+</button>
                                <button className={styles.buttonRight}  onClick={() => handleDecrement(setTimeToMaturity, timeToMaturity)}>-</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.parameterContainer}>
                    <h5 >Volatility</h5>
                    <div className={styles.parameter}>
                        <div className={styles.parameterInputContainer}>
                            <input type="number" className={styles.parameterInput}
                                    value={volatility}
                                    onChange={(e) => {
                                        setVolatility(parseFloat(e.target.value))
                                        handleUpdate();
                                        }}/>
                            <div className={styles.buttons}>
                                <button className={styles.buttonLeft}  onClick={() => handleIncrement(setVolatility, volatility)}>+</button>
                                <button className={styles.buttonRight}  onClick={() => handleDecrement(setVolatility, volatility)}>-</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.parameterContainer}>
                    <h5 >Risk-Free Interest Rate</h5>
                    <div className={styles.parameter}>
                        <div className={styles.parameterInputContainer}>
                            <input type="number" className={styles.parameterInput}
                                        value={riskFreeRate}
                                        onChange={(e) => {
                                            setRiskFreeRate(parseFloat(e.target.value))
                                            handleUpdate();
                                            }}/>
                            <div className={styles.buttons}>
                                <button className={styles.buttonLeft}  onClick={() => handleIncrement(setRiskFreeRate, riskFreeRate)}>+</button>
                                <button className={styles.buttonRight}  onClick={() => handleDecrement(setRiskFreeRate, riskFreeRate)}>-</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        

            <div className={styles.divider}></div>

            <div className={styles.header}>
                <h2>Heatmap Parameters</h2>
            </div>

            <div className={styles.parametersContainer}>
                <div className={styles.parameterContainer}>
                    <h5 >Min Spot Price</h5>
                    <div className={styles.parameter}>
                        <div className={styles.parameterInputContainer}>
                            <input type="number" className={styles.parameterInput}
                                    value={minSpotPrice}
                                    onChange={(e) => {
                                        setMinSpotPrice(parseFloat(e.target.value))
                                        handleUpdate();
                                        }} />
                            <div className={styles.buttons}>
                            <button className={styles.buttonLeft}  onClick={() => handleIncrement(setMinSpotPrice, minSpotPrice)}>+</button>
                            <button className={styles.buttonRight}  onClick={() => handleDecrement(setMinSpotPrice, minSpotPrice)}>-</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.parameterContainer}>
                    <h5 >Max Spot Price</h5>
                    <div className={styles.parameter}>
                        <div className={styles.parameterInputContainer}>
                            <input type="number" className={styles.parameterInput}
                                        value={maxSpotPrice}
                                        onChange={(e) => {
                                            setMaxSpotPrice(parseFloat(e.target.value))
                                            handleUpdate();
                                            }} />
                            <div className={styles.buttons}>
                            <button className={styles.buttonLeft}  onClick={() => handleIncrement(setMaxSpotPrice, maxSpotPrice)}>+</button>
                            <button className={styles.buttonRight}  onClick={() => handleDecrement(setMaxSpotPrice, maxSpotPrice)}>-</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.parameterContainer}>
                    <h5 >Min Volatility</h5>
                    <div className={styles.parameter}>
                        <div className={styles.parameterInputContainer}>
                        <input type="number" className={styles.parameterInput}
                                    value={minVolatility}
                                    onChange={(e) => {
                                        setMinVolatility(parseFloat(e.target.value))
                                        handleUpdate();
                                        }} />
                            <div className={styles.buttons}>
                                <button className={styles.buttonLeft}  onClick={() => handleIncrement(setMinVolatility, minVolatility)}>+</button>
                                <button className={styles.buttonRight}  onClick={() => handleDecrement(setMinVolatility, minVolatility)}>-</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.parameterContainer}>
                    <h5 >Max Volatility</h5>
                    <div className={styles.parameter}>
                        <div className={styles.parameterInputContainer}>
                            <input type="number" className={styles.parameterInput}
                                        value={maxVolatility}
                                        onChange={(e) => {
                                            setMaxVolatility(parseFloat(e.target.value))
                                            handleUpdate();
                                            }} />
                            <div className={styles.buttons}>
                            <button className={styles.buttonLeft}  onClick={() => handleIncrement(setMaxVolatility, maxVolatility)}>+</button>
                            <button className={styles.buttonRight}  onClick={() => handleDecrement(setMaxVolatility, maxVolatility)}>-</button>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            {children}
        </div>
    )
}