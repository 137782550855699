import MyButtonCSS from './MyButton.module.css';


type MyButtonProps = {
    onClick?: () => void;
    children?: string;
}

export const MyButton = (props: MyButtonProps) => {
    return (
    <button className={MyButtonCSS.btn} onClick={props.onClick}>
        {props.children}
    </button>
    )
}


// This should be done using inheritance

export const LightButton = (props: MyButtonProps) => {
    return (
    <button className={MyButtonCSS.lightBtn} onClick={props.onClick}>
        {props.children}
    </button>
    )
}
