import React from 'react';
import ReactDOM from 'react-dom/client';
import 'katex/dist/katex.min.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home  from './pages/Home';
import LLMScienceExam  from './pages/projects/LLMScienceExam';
import AdministrationData from './pages/projects/AdministrationData';
import OptionPricing from './pages/projects/OptionPricing';
import NegotiationAssistance from './pages/projects/NegotiationAssistance';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="projects/llm-science-exam" element={<LLMScienceExam />} />
          <Route path="projects/administration-data" element={<AdministrationData />} />
          <Route path="projects/option-pricing" element={<OptionPricing />} />
          <Route path="projects/negotiation-assistance" element={<NegotiationAssistance />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
