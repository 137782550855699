import styles from './DemoContainer.module.css';
import { DataInputs} from './DataInputs';
import { HeatMap } from './HeatMap';
import { useState } from 'react';



type AboutContainerProps = {
    children?: any;
}

export const DemoContainer = (props: AboutContainerProps) => {
    const [parameters, setParameters] = useState<number[]>( [100.00, 100.00, 1.00, 0.20, 0.05, 80.00, 120.00, 0.10, 0.30]);
    // 5x5 call data
    const [chartDataCall, setChartCallData] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    // 5x5 put data
    const [chartDataPut, setChartPutData] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

    const [spotPriceArray, setSpotPriceArray] =  useState<number[]>([0, 0, 0, 0, 0]);
    const [volatilityPriceArray, setVolatilityPriceArray] =  useState<number[]>([0, 0, 0, 0, 0]);

    const [callDataSingle, setCallDataSingle] = useState<number[]>([0]);
    const [putDataSingle, setPutDataSingle] = useState<number[]>([0]);

    const updateChartData = (newData: number[]) => {
        console.log('Old data: ' + parameters)
        setParameters(newData);
        console.log('New data: ' + newData)
        console.log('Chart data: ' + parameters)
        
    }

    return (
        <div className={styles.div}>
            {/* <SectionHeader title="Science Exam"/> */}

            <div className={styles.content}>
                              
              
                <DataInputs 
                    data={parameters} 
                    updateChartData={updateChartData} 
                    callData={chartDataCall} 
                    putData={chartDataPut} 
                    setCallData={setChartCallData} 
                    setPutData={setChartPutData}
                    spotPriceArray={spotPriceArray}
                    setSpotPriceArray={setSpotPriceArray}
                    volatilityPriceArray={volatilityPriceArray}
                    setVolatilityPriceArray={setVolatilityPriceArray}
                    setCallDataSingle={setCallDataSingle}
                    setPutDataSingle={setPutDataSingle}
                />
                <HeatMap 
                    updateChartData={updateChartData} 
                    data={parameters}
                    callData={chartDataCall}
                    putData={chartDataPut}
                    spotPriceArray={spotPriceArray}
                    volatilityPriceArray={volatilityPriceArray}
                    callDataSingle={callDataSingle}
                    putDataSingle={putDataSingle}
                />

                
              
              
              

            </div>
        </div>
    )
}