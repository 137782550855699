import AboutContainerCSS from './ExamContainer.module.css';
import { ChartContainer } from './ChartContainer';
import { QuestionsAndAnswers } from './QuestionsAndAnswers';
import { useState } from 'react';


type AboutContainerProps = {
    children?: any;
}

export const ExamContainer = (props: AboutContainerProps) => {
    const [chartData, setChartData] = useState<number[]>([0, 0]);

    const updateChartData = (newData: number[]) => {
        console.log('Old data: ' + chartData)
        setChartData(newData);
        console.log('New data: ' + newData)
        console.log('Chart data: ' + chartData)
        
    }

    return (
        <div className={AboutContainerCSS.div}>
            {/* <SectionHeader title="Science Exam"/> */}

            <div className={AboutContainerCSS.content}>
                              
              
              <ChartContainer data={chartData}/>
              <QuestionsAndAnswers updateChartData={updateChartData} chartData={chartData}/>
              
              
              

            </div>
        </div>
    )
}