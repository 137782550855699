import InitialsCSS from './Initials.module.css'

type InitialsProps = {
    children?: string;
}

// onclick function that scrolls to top of page if on root page else 
// redirects to root page.
function handleClick() {
    console.log('Going to top of home page');
    if (window.location.pathname === "/") {
        window.scrollTo(0, 0);
    } else {
        window.location.href = "/";
    }
}

export const Initials = (props: InitialsProps) => {
    return (
    <button className={InitialsCSS.button} onClick={handleClick}>
        {props.children}
    </button>
    )
}