import styles from './ProjectDescriptionOptions.module.css';
import { SectionHeader } from '../../SectionHeader';




type ProjectDescriptionProps = {    
    children?: any;
}
  
  

export const ProjectDescriptionOptions = (props: ProjectDescriptionProps) => {

    return (
        <div className={styles.div}>
            <SectionHeader title="Project Description"/>
            {/*Create div from projectDescriptions*/}


            <div className={styles.content}>
                <div>
                    <h3>Technology Stack</h3>
                    <div className={styles.architectureDiagram}>
                    <img className={styles.projectImage} src={require('../../../assets/portfolio/option-pricing-tech-stack.webp')} width="400px" height="122px" alt="Insolvency monitor" />
                    </div>
                    
                </div>

                <div>
                    <h3>Outline</h3>
                    <p>
                    This project leverages the Black-Scholes model to calculate
                    option prices based on inputs such as underlying asset price,
                    strike price, time to expiration, volatility, and the risk-free interest rate. 
                    The core model is implemented in C++ and compiled into WebAssembly 
                    using Emscripten and CMake. The WebAssembly module is integrated into
                    a React application via a JavaScript wrapper, enabling high-performance 
                    computations. A heatmap visualizes how call and put prices fluctuate based 
                    on volatility and strike price.
                    </p>
                </div>
                <div>
                    <h3>Performance Metric</h3>
                    <p>
                    The key performance metric is model execution time.
                    </p>
                </div>


              
            </div>            
            {props.children}
        </div>
    )
}