import BackgroundCSS from './Background.module.css'

type BackgroundProps = {
    children: React.ReactNode;
}

export const Background = (props: BackgroundProps) => {
    return (
        <div className={BackgroundCSS.div}>
            {props.children}
        </div>
    )
}