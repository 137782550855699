
import HeroInfoContainerCSS from './HeroInfoContainer.module.css';

type HeroInfoContainerProps = {
    children?: any;
}


export const HeroInfoContainer = (props: HeroInfoContainerProps) => {
    return (
        <div className={HeroInfoContainerCSS.div}>
            {props.children}
        </div>
    )
}

