import FrontPageCSS from './FrontPageInfo.module.css';
import { MyButton, LightButton } from '../MyButton';


type FrontPageProps = {
    children?: any;
}

export const FrontPageInfo = (props: FrontPageProps) => {
    const handleClickViewQuiz = () => {
        const section = document.getElementById("ScienceExam");
        if (section){
            console.log(section);
            section.scrollIntoView({behavior: "smooth"} );
            console.log("scrolling to Portfolio");
        }
    }


    

    return (
       
            <div className={FrontPageCSS.div}>
                
                <div className={FrontPageCSS.verticalGap}></div>
                
                <div className={FrontPageCSS.jobTitle}>
                    <h1>
                        <span >Can you ace this  </span>
                        <div></div>
                        <span >Science Exam written by: </span>
                        <div></div>
                        <span className={FrontPageCSS.highlightContainer}>
                            <span className={FrontPageCSS.highlight}>
                            GPT3.5?
                            </span>
                        </span>
                    </h1>

                </div>
                
                {props.children}
                
                <div className={FrontPageCSS.interests}>
                    <h2 className={FrontPageCSS.subheading}>
                    Inspired by the ability for LLM’s to self-benchmark, this project explores the intriguing question of whether smaller question-answering models can outperform much larger question-writing models.
                    </h2>
                    <div className={FrontPageCSS.buttonList}>
                        <MyButton onClick={handleClickViewQuiz}>Yes !</MyButton>     
                        <LightButton onClick={handleClickViewQuiz}>No, but i'll try</LightButton>

                        
                    </div>
                </div>
            </div>
       
         
    )
}