import HeroInfoCSS from './HeroInfo.module.css';
import { MyButton, LightButton } from '../MyButton';


type HeroInfoProps = {
    name: string;
    jobTitle: string;
    children?: any;
}

export const HeroInfo = (props: HeroInfoProps) => {
    const handleClickHireMe = () => {
        window.location.href = "https://www.linkedin.com/in/nikodem-czarlinski/"
    }

    const handleClickViewWork = () => {
        const section = document.getElementById("Portfolio");
        if (section){
            console.log(section);
            section.scrollIntoView({behavior: "smooth"} );
            console.log("scrolling to Portfolio");
        }
    }

    

    return (
       
            <div className={HeroInfoCSS.div}>
                
                <div className={HeroInfoCSS.verticalGap}></div>
                
                <div className={HeroInfoCSS.jobTitle}>
                    <h1>
                        <span>Hello, I'm {props.name}. </span>
                        <div></div>
                        {/* <span className={HeroInfoCSS.highlightContainer}>
                            <span className={HeroInfoCSS.highlight}>
                                {props.jobTitle}.
                            </span>
                        </span> */}
                    </h1>

                </div>
                
                {props.children}
                
                <div className={HeroInfoCSS.interests}>
                    <h2 className={HeroInfoCSS.subheading}>
                        Specialised in Machine Learning and Data Analysis, and based in United Kingdom.
                        Over the past 4 years I've worked with big companies, 
                        student societies and contractors.
                    </h2>
                    <div className={HeroInfoCSS.buttonList}>
                        <MyButton onClick={handleClickHireMe}>Hire Me</MyButton>     
                        <LightButton onClick={handleClickViewWork}>View Work</LightButton>

                        
                    </div>
                </div>
            </div>
       
         
    )
}