import ExperienceContainerCSS from './ExperienceContainer.module.css';
import { SectionHeader } from '../SectionHeader';
import React, {useState} from 'react';


type ExperienceContainerProps = {
    children?: any;
}

export const ExperienceContainer = (props: ExperienceContainerProps) => {
    // Insert information about experience here
    const experienceInfo = [
        {
            title: "Software Engineer",
            company: "Sapphire Inspection Systems",
            date: "Sep 2023 - Present",
            list: [
                "&&& ** Software Development for High-Speed Electronics **" ,
                "Working in the **research and development** team to create bespoke software plug-ins and develop the mature code base.",
                "Analysis of X-Ray images for evaluating processed items within the **food and pharmaceutical** manufacturing industries.",
                "Ensuring end to end overview of project is managed and maintained.",
                "Apply **machine learning** techniques to improve the accuracy of the analysis.",
            ]
        },
        {
            title: "Software Engineer",
            company: "Hercules Cambridge",
            date: "Jul 2021 - Oct 2022",
            list: [
                "&&& ** Project Acquisition and Membership Surge:**" ,
                        "Led efforts to secure ** 4 new projects**, showcasing effective project management and client engagement skills. ",
                        "Transformed the society's reach by orchestrating a remarkable ** 10-fold ** increase in membership, elevating it from 100 to an impressive 1000 members.", 
                        "This achievement was realized through the implementation of an ** automated mailing list ** integration upon user registration and  timeline feature to keep members updated on what is going on in the society.", 
                "&&& ** Frontend Technologies:**",
                        "**JQuery**: Proficient in utilizing JQuery to enhance user interface interactivity and optimize the user experience. ",
                        "**Bootstrap**: Skillfully implemented Bootstrap to develop responsive and visually appealing front-end designs.",
                        "**AJAX and Graphing with JS**: Leveraged AJAX for seamless data retrieval and dynamically updated content.",
                "&&& ** Backend Technologies:**",
                        "**Django**: Demonstrated expertise by constructing robust backend architectures, ensuring smooth data handling, logic implementation, and efficient server-client communication.",
                        "** MySQL **: Designed efficient databases, ensuring reliable data storage, retrieval, and manipulation to support website functionality.",
            ]           
        },
        {

     
            title: "Software Developer",
            company: "Publicis Sapient",
            date: "Jul 2022 - Sep 2022",
            list: [
                "Successfully executed **end-to-end project delivery** for a valued client, showcasing strong project management and communication skills.",
                "Harnessed Java to develop a robust application.",
                "Capitalized on the capabilities of **Google Cloud Platform** to deploy the application, demonstrating mastery in cloud-based technologies.",
                "Incorporated cutting-edge architectural patterns and frameworks including DevOps principles, **microservices architecture**, API integration and ReactJS to ensure fast and scalable systems.",
            ]
        },
        {        
            title: "Equity Research Analyst",
            company: "Finance Focused",
            date: "Jul 2021 - Sep 2021",
            list: [
                "Perform in-depth analysis of ** market sentiment ** indicators associated with stocks.",
                "Indicators determined by applying ** natural language processing ** on news articles and social media posts.",
                "Implement ** algorithmic backtesting ** to evaluate the effectiveness of trading strategies based on the identified indicators.",
            ]
        },
        {        
            title: "Software Engineer",
            company: "J.P. Morgan",
            date: "Feb 2021",
            list: [
                "&&& ** Early Insight into Technology**",
                "Participated in an immersive week-long software engineering program.",
                "Successfuly executed coding tasks focused on developing REST APIs using Java and Spring Boot.",
                "Engaged in a compregensive exploration of software engineering principles, including ** Cybersecurity ** and ** Test Driven Design **. Gained insights into safeguarding code against vulnerabilities like SQL injections.",
                "Designed and implemented a sanction screening module (Python) to assess transaction validity, demonstrated proficiency in data processing (Java) and database management (SQL).",
            ]
        },
    ]

    const [selectedExperience, setSelectedExperience] = useState(0); // 0 is the default selected experience


    return (
        <div className={ExperienceContainerCSS.div}>
            <SectionHeader title="Experience"/>
            <div className={ExperienceContainerCSS.content}>
                <div className={ExperienceContainerCSS.navLinkContainer}>
                    <div className={ExperienceContainerCSS.navLinkDiv}>
                        <div className={ExperienceContainerCSS.navLinkLeftBorder}></div>
                        <div className={ExperienceContainerCSS.navLinkButtonContainer}>
                            {experienceInfo.map((experience, index) => (
                                <button 
                                    key={index}
                                    className={index === selectedExperience ? ExperienceContainerCSS.navLinkButtonPerma : ExperienceContainerCSS.navLinkButton}
                                    onClick={() => {
                                        setSelectedExperience(index);
                                        console.log(index);
                                    }}
                                >
                                    {experience.company}
                                </button>
                               

                            ))} 
                        </div> 
                    </div>
                    
                </div>
                <div className={ExperienceContainerCSS.experienceInfo}>
                    <h2 className={ExperienceContainerCSS.experienceTitle}>
                        {experienceInfo[selectedExperience].title} |
                        <span> </span>
                        <span className={ExperienceContainerCSS.experienceCompany}> 
                            {experienceInfo[selectedExperience].company} 
                        </span>
                    </h2>
                    <h4 className={ExperienceContainerCSS.experienceDate}>
                        {experienceInfo[selectedExperience].date}
                    </h4>
                    <ul className={ExperienceContainerCSS.experienceList}>
                        {experienceInfo[selectedExperience].list.map((item, index) => (
                            <li key={index} 
                                className={(item.startsWith('&&&') ? ExperienceContainerCSS.noBullet: '')}
                            >
                                {item.startsWith('&&&') ? (
                                    item.split('**').map((text, subIndex) => {
                                    if (subIndex % 2 === 0) {
                                        return <span key={subIndex}>{text.substring(3)}</span>;
                                    } else {
                                        return <strong key={subIndex}>{text.substring(1)}</strong>;
                                    }
                                    })
                                   
                                ) : (
                                    item.split('**').map((text, subIndex) => {
                                    if (subIndex % 2 === 0) {
                                        return <span key={subIndex}>{text}</span>;
                                    } else {
                                        return <strong key={subIndex}>{text}</strong>;
                                    }
                                    })
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}