import styles from './ProjectConclusions.module.css';
import { SectionHeader } from '../SectionHeader';




type ProjectConclusionProps = {    
    projectConclusions?: {
        title: string;
        description: string;
    }[];
    children?: any;
}



export const ProjectConclusion = (props: ProjectConclusionProps) => {

    return (
        <div className={styles.div}>
            <SectionHeader title="Project Conclusion"/>
            {/*Create div from projectConclusions*/}
       
            <div className={styles.content}>
                {props.projectConclusions?.map((item, index) => (
                    <div key={index}>
                        <h3>{item.title}</h3>
                        {/* If <br/><br/> found in item.description add a
                            paragraph break else return standard paragraph.*/}
                        {item.description.includes("<br/><br/>") ? (
                            item.description.split("<br/><br/>").map((item, index) => (
                                <p key={index}>{item}</p>
                            ))
                        ) : (
                            <p>{item.description}</p>
                        )}
            
                    </div>
                ))}
            </div>    
            {props.children}
        </div>
    )
}