import ContactCSS from "./ContactContainer.module.css"
import {ReactComponent as Github} from "../../assets/icons/github.svg"
import {ReactComponent as LinkedIn} from "../../assets/icons/linkedin.svg"

type ContactContainerProps = {
    children?: React.ReactNode
}

export const ContactContainer = ({ children }: ContactContainerProps) => {
    return (
        <div className={ContactCSS.div}>
           <div className={ContactCSS.text}>
                <div className={ContactCSS.inTouch}>
                    <h5>What's next</h5>
                    <h2>Get in touch</h2>
                </div>
                
                <p>Don't hesitate to reach out if you have any inquiries. If you're dealing with open source projects, kindly initiate an issue or pull request on GitHub. Moreover, I'm readily available for discussions regarding potential new prospects.</p>
            </div>
            <div className={ContactCSS.followMeOn}>
                <h5>Follow me on</h5>
                <div className={ContactCSS.socialMedia}>
                    <a href="https://github.com/nczarli">
                        <div className={ContactCSS.githubContainer}>
                            <Github/>
                        </div>
                    </a>
                    <a href="https://www.linkedin.com/in/nikodem-czarlinski/">
                        <div className={ContactCSS.linkedinContainer}>
                            <LinkedIn/>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}