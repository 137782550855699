import ContainerCSS from  './ParticleContainer.module.css'

type ParticleContainerProps = {
    children?: React.ReactNode;
}


export const ParticleContainer = (props: ParticleContainerProps) => {
    return (
        <div className={ContainerCSS.particleContainer}>
            {props.children}
        </div>
    )
}