import DotsCSS from  './DotsContact.module.css';


export const DotsContact = () => {
    return (
        <div className={DotsCSS.dotsContainer}>
        </div>
    )
}

