import AdministrationCSS from './AdministrationDataContainer.module.css';
import React, { useState , useMemo} from 'react';
import { MyButton } from '../../MyButton';

type AboutContainerProps = {
    children?: any;
}

export const AdministrationDataContainer = (props: AboutContainerProps) => {

    const [currentCompanyIndex, setCurrentCompanyIndex] = useState<number>(1050);

    const administrationDataSection = document.getElementById('AdministrationData');
   

    const handleBackClick = () => {
        if (currentCompanyIndex > 0) {
            setCurrentCompanyIndex(currentCompanyIndex - 20);
        }
        administrationDataSection?.scrollIntoView({behavior: 'smooth'});
    };
    
    const handleNextClick = () => {
        if (currentCompanyIndex + 20 < companyData.length) {
            setCurrentCompanyIndex(currentCompanyIndex + 20);
        }
        administrationDataSection?.scrollIntoView({behavior: 'smooth'});
       
    };


    const companyData = useMemo(() => [
        {'company_name': `... IN FOR A £ LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `04068016 PLC`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `04806837 REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `05092653 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `07493454 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `08804168 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `1 CHIL (F1) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `1-9 KINGS ROAD LLP`, 'company_status': `In Administration`},
        {'company_name': `100+ LOGISTICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `111 INVEST LIMITED`, 'company_status': `In Administration`},
        {'company_name': `11D REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `123TEACHERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `146 TR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `152 LR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `152A LR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `152S LR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `154 LR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `154A LR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `154B LR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `154S LR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `157 US LIMITED`, 'company_status': `In Administration`},
        {'company_name': `16 CTR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `17 CTR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `19 RM LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `1957 CAPITAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `1957 HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `199 AB LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `20 - 24 (20-22) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `20 - 24 (FB) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `20 - 24 LR (CH) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `20-24 LR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `21K FOOTWEAR LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `21ST ARCHITECTURE LTD`, 'company_status': `In Administration`},
        {'company_name': `254 KILBURN HR LLP`, 'company_status': `In Administration`},
        {'company_name': `27-33 CAROLINE STREET LLP`, 'company_status': `In Administration`},
        {'company_name': `28 FL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `28FSM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `2NDHND LIMITED`, 'company_status': `In Administration`},
        {'company_name': `2PURE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `30 JAMES STREET LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `304 CLOTHING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `316 ENGINEERING LTD.`, 'company_status': `In Administration`},
        {'company_name': `33 LEGAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `35 QR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `36 PS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `360 PERSONNEL LTD`, 'company_status': `In Administration`},
        {'company_name': `365 TUTOR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `38 JS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `39-45 GRANSDEN AVENUE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `3F PELLETS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `3R GLOBAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `4 EARTH INTELLIGENCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `4 SEASONS BEER TENT CO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `4D PHARMA PLC`, 'company_status': `In Administration`},
        {'company_name': `4D PHARMA RESEARCH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `5 CHURCHILL PLACE MANAGEMENT COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `50 UGR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `52 MP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `55 - 57 CR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `56 TR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `6 BONES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `67 AS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `7BONE GERVIS PLACE LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `7BONE HOLDINGS (1) LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `7BONE HOLDINGS LTD`, 'company_status': `In Administration`},
        {'company_name': `8 BALL MAIL ORDER LTD`, 'company_status': `In Administration`},
        {'company_name': `8 OUTDOOR MEDIA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `9 WHOLESALE LTD`, 'company_status': `In Administration`},
        {'company_name': `95 NS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `96 QHR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `98 LR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `98 QHR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `A & A PROPERTY REFURBISHMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `A & A TRAINING LTD`, 'company_status': `In Administration`},
        {'company_name': `A & D JOINERY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `A & F KING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `A C PLC`, 'company_status': `In Administration`},
        {'company_name': `A DRAW F LIMITED`, 'company_status': `In Administration`},
        {'company_name': `A F SMART REPAIRS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `A MARKETING VOCATION LTD`, 'company_status': `In Administration`},
        {'company_name': `A SKIPS & WASTE LTD`, 'company_status': `In Administration`},
        {'company_name': `A. LEVY & SON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `A.C. CANOE PRODUCTS (CHESTER) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `A.C.E. (STONEHAVEN) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `A.D. WALKER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `A.V BROWNE ADVERTISING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `A1 SUPA SKIPS LTD`, 'company_status': `In Administration`},
        {'company_name': `A2023 REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `A2B PLASTICS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `AA2023 REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AAA AA ALL AREAS MAINTENANCE LTD.`, 'company_status': `In Administration`},
        {'company_name': `AAA AA ALL BIRMINGHAM MAINTENANCE LTD`, 'company_status': `In Administration`},
        {'company_name': `AAA AA ASTERICK MAINTENANCE LTD`, 'company_status': `In Administration`},
        {'company_name': `AB REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ABACUS PRINT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ABBEY OFFICE SOLUTIONS (HAMPSHIRE) LTD`, 'company_status': `In Administration`},
        {'company_name': `ABBEY VIEW HOMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ABC ALPHA BUSINESS CENTRES UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ABC ALPHA BUSINESS CENTRES VI UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ABC HNW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ABCELL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ABERLA M&E LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ABERLA SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ABERLA UTILITIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ABERYSTWYTH MARINA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ABS RESTORATION LTD`, 'company_status': `In Administration`},
        {'company_name': `ABSOLS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ABSURD BIRD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AC DATA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACB DEVELOPMENT LTD`, 'company_status': `In Administration`},
        {'company_name': `ACCENTHANSEN LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ACCENTIA FRANCHISE CONSULTANTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACCESS 2 FUNDING SPECIALISTS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ACCESS COMMERCIAL INVESTORS 4 PLC`, 'company_status': `In Administration`},
        {'company_name': `ACCLAIM UPHOLSTERY COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACCOUNT TECHNOLOGIES HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACCOUNT TECHNOLOGIES SOFTWARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACCUMULATE CAPITAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACCUMULATE COMMERCIAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACCUMULATE LONDON SW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACCUMULATE RESIDENCES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACE DRAINS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACE FACILITIES MANAGEMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACELERON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACG CONTRACTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACKIO (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACL ASTUTE 2006 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACL ASTUTE 2022 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACORN COUNTRY CAPITAL LTD`, 'company_status': `In Administration`},
        {'company_name': `ACP LEISURE LTD`, 'company_status': `In Administration`},
        {'company_name': `ACTIVE ELECTRICAL SERVICES (STAMFORD) LTD`, 'company_status': `In Administration`},
        {'company_name': `ACTIVE MAINTENANCE SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACTIVE SOLUTIONS UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACTIVE WEAR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACTON COACHWORKS SP LTD`, 'company_status': `In Administration`},
        {'company_name': `ACTUAL EXPERIENCE PLC`, 'company_status': `In Administration`},
        {'company_name': `ACUITY PROFESSIONAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACUITY SELECTIVE FINANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ACUMEN CARE SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ADAM JONES AND SONS (BLACKHEATH) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ADAMO COMMONWOOD MANOR LTD`, 'company_status': `In Administration`},
        {'company_name': `ADAMO PRECISION ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ADAMO RIVERCROFT HOTEL LTD`, 'company_status': `In Administration`},
        {'company_name': `ADAPTABLE CONSULTANCY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ADAPTIVE MODULES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ADASTRA ACCESS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ADDSPACE FURNITURE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ADEO CONSTRUCTION SERVICES LTD`, 'company_status': `In Administration`},
        {'company_name': `ADIWELL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ADM PRECISION TOOLS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ADMEDO LTD`, 'company_status': `In Administration`},
        {'company_name': `ADS JOINERY DESIGN LTD`, 'company_status': `In Administration`},
        {'company_name': `ADVANCED CRATES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ADVANCED DIGITAL INNOVATION (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ADVANCED TOOL MANUFACTURE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ADVANCIS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `AE AEROSPACE (BIRMINGHAM) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AE AEROSPACE LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `AERO TECHNICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AEROCARE INTERNATIONAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AFFINITI FINANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AFFINITY CORPORATION LTD`, 'company_status': `In Administration`},
        {'company_name': `AFFORDABLE FITNESS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AFG REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AFREN NIGERIA HOLDINGS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `AFRICAN MINERALS ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AFRICAN MINERALS TRADING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AFX MARKETS LTD`, 'company_status': `In Administration`},
        {'company_name': `AG REALISATIONS LLP`, 'company_status': `In Administration`},
        {'company_name': `AG SAFEBOOTHS UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AGE CHECK CERTIFICATION SERVICES LTD`, 'company_status': `In Administration`},
        {'company_name': `AH SCARBOROUGH HEALTH PARK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AIMPRIZE LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `AIMROK LEICESTER INVESTMENT LTD`, 'company_status': `In Administration`},
        {'company_name': `AIR COOL ENGINEERING MIDLANDS LTD.`, 'company_status': `In Administration`},
        {'company_name': `AIRBASE INTERIORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AIRTEK WORLD LTD`, 'company_status': `In Administration`},
        {'company_name': `AJUK REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AL REALISATIONS 2022 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AL REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALAN BURNS ROOFING SUPPLIES LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `ALBANY BRENT GROUP LTD`, 'company_status': `In Administration`},
        {'company_name': `ALDER BRIDGE ASSOCIATION FOR STEINER-WALDORF EDUCATION`, 'company_status': `In Administration`},
        {'company_name': `ALDERDALE SEATING LTD`, 'company_status': `In Administration`},
        {'company_name': `ALDO UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALERCE ADVISORS LTD`, 'company_status': `In Administration`},
        {'company_name': `ALEX THORP TRANSPORT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALEXANDER GRACE LAW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALEXANDER INGLIS AND SON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALEXANDERS DISCOUNT LTD`, 'company_status': `In Administration`},
        {'company_name': `ALITECH PRECISION LTD`, 'company_status': `In Administration`},
        {'company_name': `ALL FOUNDATIONS (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALL IN BLACK LTD`, 'company_status': `In Administration`},
        {'company_name': `ALL REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALL RETAIL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALL SOFAS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ALL STAR (MANCHESTER) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALL STAR (SHEPHERDS BUSH) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALL STAR (TRUMAN) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALL STAR LEISURE (GROUP) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALL STAR LEISURE LTD`, 'company_status': `In Administration`},
        {'company_name': `ALL-COUNTIES SUPPLIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALLAN SNOW LTD`, 'company_status': `In Administration`},
        {'company_name': `ALLENS CRANKSHAFTS LTD`, 'company_status': `In Administration`},
        {'company_name': `ALLIANCE TRANSPORT TECHNOLOGIES LTD`, 'company_status': `In Administration`},
        {'company_name': `ALLIED GLAZING SYSTEMS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ALLIUM LAW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALLMA CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALLMA HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALLOY FUSION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALLSEAS GLOBAL PROJECT LOGISTICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALLSOPP & BURKE (SEDGLEY) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `ALLTEKS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALMONDSBURY CARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALMOR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALOM PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALOMORA GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALPHA HOMES (LEICESTER) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ALPHA TECHNOLOGY (INT) LTD`, 'company_status': `In Administration`},
        {'company_name': `ALPHABET BREWING LTD`, 'company_status': `In Administration`},
        {'company_name': `ALPINE ELEMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALTITUDE SELECT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALUMINIUM FRANCE INDUSTRIES UK LTD`, 'company_status': `In Administration`},
        {'company_name': `ALVIS HOUSE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ALW GROUP (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AMBIENT FOODS LTD`, 'company_status': `In Administration`},
        {'company_name': `AMBIVENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AMOS COMMERCIAL LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `AMPLIFY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AMPOWERUK LTD`, 'company_status': `In Administration`},
        {'company_name': `AMT COFFEE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ANDREW'S HOMES (ABERDEEN) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ANDREWS INFINITY PARTNERSHIP LLP`, 'company_status': `In Administration`},
        {'company_name': `ANDROMEDA ENTERPRISES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ANDRON HANDLING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ANGLIA TRAILBLAZERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ANGLO-ITALIAN ENTERPRISES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ANIL ESTATES LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `ANNAFIELD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `APEX AERO LTD`, 'company_status': `In Administration`},
        {'company_name': `APEX BSJ LIMITED`, 'company_status': `In Administration`},
        {'company_name': `APEX PORTFOLIO (RIPON) TWO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `APEX RIDES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `APPLEBY CASTLE (OUTBUILDINGS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `APPLEDORE FINANCE LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `APPLETREE COTTAGE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `APPLETREE FARM CRESSING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `APPLIED FUSION LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `AQUA SOLUTIONS REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AQUAHEAT PLUMBING HEATING AND GAS SERVICES LTD`, 'company_status': `In Administration`},
        {'company_name': `AQUASTRADA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AQUATECH (UK) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `AQUILA FACILITIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AR (SURVEYORS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARBORETUM DEVON (RLH) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARCADIAN LIVING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARCH REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARCHAVANT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARCHITECTURAL FABRICATIONS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ARCTIC CIRCLE LTD.`, 'company_status': `In Administration`},
        {'company_name': `ARDULA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARENA TOURS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ARGO (CARGOMASTERS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARISHI MEDIA TECHNOLOGIES LTD`, 'company_status': `In Administration`},
        {'company_name': `ARJO WIGGINS FINE PAPERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARJOWIGGINS CHARTHAM MILL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARJOWIGGINS GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARJOWIGGINS SCOTLAND LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARJOWIGGINS TRANSLUCENT PAPERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARL 011 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARL O09 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARM AUTOMOTIVE YORKSHIRE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARMATUS RISKS HOLDINGS LTD`, 'company_status': `In Administration`},
        {'company_name': `ARNAOUTI PITTA BREAD BAKERY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARRACO GLOBAL MARKETS LTD`, 'company_status': `In Administration`},
        {'company_name': `ARRANGED MUSICAL OPTIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `ARRIVAL SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ART PROJECTS FOR SCHOOLS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARTHOUSE HOLDING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARTHOUSE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARTISAN H (KINGS WATERFRONT) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARTISAN H1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARTISAN H2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ARTISAN INVESTMENT GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ASHCROFT DEMOLITION (CUMBRIA) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ASHDOWN LYONS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ASHLEY ELECTRICAL WHOLESALERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ASHRIDGE CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ASK INCLUSIVE FINANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ASK INCLUSIVE FINANCE SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ASK PRODUCTS LTD`, 'company_status': `In Administration`},
        {'company_name': `ASPEN PHOENIX NEWCO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ASPENLINK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ASPIRE DEVELOPMENTS PARTNERSHIP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ASPIRE PLATFORMS LTD`, 'company_status': `In Administration`},
        {'company_name': `ASPIRE SUSSEX LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ASPIRE-IGEN GROUP LTD`, 'company_status': `In Administration`},
        {'company_name': `ASSET ACADEMY LTD`, 'company_status': `In Administration`},
        {'company_name': `ASSET REMARKETING SERVICES LTD`, 'company_status': `In Administration`},
        {'company_name': `ASSOCIATED CONCRETE REPAIRS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `ASTON CLINTON DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ATAG CABLE SOLUTIONS (UK) LTD`, 'company_status': `In Administration`},
        {'company_name': `ATG - IT. LTD`, 'company_status': `In Administration`},
        {'company_name': `ATHLETES UNLIMITED LTD`, 'company_status': `In Administration`},
        {'company_name': `ATLANTIC HEALTHCARE PLC`, 'company_status': `In Administration`},
        {'company_name': `ATLANTIC HOSPITALITIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ATLAS BUILDING & CIVIL ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ATLAS TRAFFIC MANAGEMENT LTD`, 'company_status': `In Administration`},
        {'company_name': `ATLAS WAY REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ATM READING REALISATIONS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ATMOSVR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ATN BANGLA UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ATOM LABS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ATRIUM FITNESS LTD`, 'company_status': `In Administration`},
        {'company_name': `AUDEN GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AUDOS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AURORA PROJECTS (UK) LTD.`, 'company_status': `In Administration`},
        {'company_name': `AURUM GLOBE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AUTHENTIC ALEHOUSES LTD`, 'company_status': `In Administration`},
        {'company_name': `AUTHENTIC ALEHOUSES TRADING LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `AUTO CAR SOR LTD`, 'company_status': `In Administration`},
        {'company_name': `AUTO PLAS (NORTH WEST) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AUTO RESERVATION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AUTOMATIC ENGINEERS (HINCKLEY) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AUTOMATIC LOUNGE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AUTORESTORE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AVANTI COMMUNICATIONS GROUP PLC`, 'company_status': `In Administration`},
        {'company_name': `AVANTI COMMUNICATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AVENUE CLOSE FREEHOLD LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `AVNI KENSINGTON HOTEL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AVONSIDE GROUP SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AVONSIDE ROOFING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AVPE DURHAM LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `AW BRANDING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AW CREATIVE PAPERS GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AW ESTATES ENGLAND LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AW ESTATES HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AW ESTATES SCOTLAND LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AWESOME MERCHANDISE LTD`, 'company_status': `In Administration`},
        {'company_name': `AXIAL SYSTEMS LTD.`, 'company_status': `In Administration`},
        {'company_name': `AXIOM INCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AXIOM MARKETING SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AXIS SIGNS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AZZURRI RESTAURANTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `AZZURRI TRADING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `B B & M (MIDLAND) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `B J MOODY GROUNDWORKS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `B.A.W. PRECISION ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `B.J. ELECTRICAL WHOLESALERS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `B.W.R. (TRANSPORT) LTD.`, 'company_status': `Administration Order`},
        {'company_name': `B13 TECHNOLOGY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BABBAGE CONSTRUCTION LTD`, 'company_status': `In Administration`},
        {'company_name': `BABY DAYS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `BABY KOOKY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BABY TV LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BADEKABINER LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BAGELMAN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BAGSHOT MANOR DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BAKED IN LTD`, 'company_status': `In Administration`},
        {'company_name': `BALLINAFAD PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BALMANNO HOUSE`, 'company_status': `In Administration`},
        {'company_name': `BALTIC PROPERTY HOLDINGS 2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BALTIC PROPERTY HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BAM HOLDCO (2) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BAM HOLDCO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BAM PARENTCO (2) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BAM PARENTCO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BAM PROPCO (2) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BAM PROPCO (BURNSIDE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BAM PROPCO (JOHNSTONE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BAM PROPCO (MOORPARK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BAM PROPCO (NEWARK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BAM PROPCO (SPIERS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BAM PROPCO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BAMBOO SYSTEMS GROUP LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BANHAM GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BANHAM POULTRY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BANK RESTAURANT COMPANY LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BARDSLEY CONSTRUCTION HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BARDSLEY CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BARDSLEY GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BARLOWS SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BARNSLEY GLASS & GLAZING LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BARRY REED LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BARRY WOOD PLANT HIRE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BARTHOLDI LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BAS CASTINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BASILDON DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BASILDON UNITED FOOTBALL, SPORTS & LEISURE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BASYM LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BATES LAUNDRIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BATES OF LONDON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BAYSCAPE RESIDENTIAL (NUMBER 2) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BBP SECURITY SERVICES & TRAINING LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BBS GRANITE CONCEPTS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BCQ GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BCS REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BCT OUTDOORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BDBUILD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BDL TOOL & DIE ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BEACON HILL CARAVAN PARK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BEACONSFIELD FOOTWEAR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BEARMACH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BEAUMONT MORGAN DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BEBBINGTON & WILSON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BEDLAM BREWERY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BEECH CONSTRUCTION PARTNERSHIP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BEHEREN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BELL STEELS AND INDUSTRIAL SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BELLA VISTA DISTILLERY LTD`, 'company_status': `In Administration`},
        {'company_name': `BELLCROWN ASSOCIATES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BELMONT FILMHOUSE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BELVOIRTEX LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `BEMACO STEEL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BENCH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BENCHMARK LEISURE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BENSON LAND & MARINE LTD`, 'company_status': `In Administration`},
        {'company_name': `BERKSHIRE ASSETS (WEST LONDON) LTD`, 'company_status': `In Administration`},
        {'company_name': `BERWICK STREET SECURITIES LLP`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `BETA DISTRIBUTION PLC`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BETACAP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BEXVILLE LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `BGHL REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BH PRECISION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BIDMEAD COOK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BIDVINE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BIER NUTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BIERKELLER MANAGEMENT COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BIFF'S KITCHEN LTD`, 'company_status': `In Administration`},
        {'company_name': `BIG HOME SHOP LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BIG SMOKE PUB CO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BILLING AQUADROME LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BILLING ASSETS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BINGHILL ESTATES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BIO PACK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BIO-BEAN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BIO-KINETIC EUROPE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BIRD BOX ENTERTAINMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BIRKDALE MANUFACTURING GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BIRKDALE RETIREMENT VILLAGE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BIRMINGHAM HIGHWAYS HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BIRMINGHAM INNOVATIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `BISNEY REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BITUMINA INDUSTRIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BLACK CAPITAL PARTNERS LTD`, 'company_status': `In Administration`},
        {'company_name': `BLACK MURPHY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BLACK SHEEP BARS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BLACKCLIFFE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BLACKSPACE SECURITY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BLANKSTONE SINGTON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BLEACH SALONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BLEIKER'S SMOKE HOUSE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BLENDWORTH HOLDINGS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BLENDWORTH INTERNATIONAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BLF REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BLUE 02 LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BLUE CASTLE BUSINESS SERVICES LTD`, 'company_status': `In Administration`},
        {'company_name': `BLUE CHIP MARKETING (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BLUE FROG CONTRACT PACKING LTD`, 'company_status': `In Administration`},
        {'company_name': `BLUE GROUP UK RETAIL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BLUE LETTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BLUE OCEAN BIDCO 3 LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BLUE OCEAN BROOKWOOD LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BLUE OCEAN MIDCO LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BLUE OCEAN TOPCO HOLDING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BLUE SQUARE PROPERTY MAINTENANCE LTD`, 'company_status': `In Administration`},
        {'company_name': `BLUEGEN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BLUEGREEN ENERGY SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BLUEROCK DIAMONDS PLC`, 'company_status': `In Administration`},
        {'company_name': `BLUESPOT FURNITURE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BM RETAIL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BMM HEATERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BMS FUNDING LTD`, 'company_status': `In Administration`},
        {'company_name': `BOB & LUSH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BOB SPENCER (HIRE) LTD`, 'company_status': `Administration Order`},
        {'company_name': `BOILER PLAN (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BOLTRO DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BON APPETIT STRATFORD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BONAR YARNS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BONDS ESTATE AGENT (ONLINE) LTD`, 'company_status': `In Administration`},
        {'company_name': `BONVILSTON VALE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BOOP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BORDER STEELWORK STRUCTURES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BORDON PARK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BORROW A BOAT LTD`, 'company_status': `In Administration`},
        {'company_name': `BOSTON PRIME LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BOSUNSTONE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BOUJEE RESTAURANT AND BAR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BOUNCE FOODS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BOUNTY JOY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BOURNE ARLINGTON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BOURNE ASSET MANAGEMENT LTD`, 'company_status': `In Administration`},
        {'company_name': `BOWTHORPE DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BOXALL SERVICE & REPAIR LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BOXCAR BREWERY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BOXVN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BOYES REES ARCHITECTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BPL REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BPL1 LTD`, 'company_status': `In Administration`},
        {'company_name': `BRACKNELL PROPERTY LLP`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BRACKNELL ROOFING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRADFORD NORTHERN RUGBY LEAGUE FOOTBALL CLUB (1964) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRADLEY DYER MEDIA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRADLEY FURNITURE LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `BRADLEYS OF GRIMSBY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRADSHAW LEGAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRADSHAW LUXURY APPLIANCES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRAEFORGE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRAINTREE (B3F) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRAINTREE LEISURE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRAINTREE PROPERTIES (B3) LLP`, 'company_status': `In Administration`},
        {'company_name': `BRAINTREE PROPERTIES LLP`, 'company_status': `In Administration`},
        {'company_name': `BRAMPTONS LIFT MANUFACTURERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRAND EVENTS TM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRANKIN DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BREATHE EV LTD`, 'company_status': `In Administration`},
        {'company_name': `BREEZE ENERGY SUPPLY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRETON PARK RESIDENTIAL HOMES LTD`, 'company_status': `In Administration`},
        {'company_name': `BREW BY NUMBERS LTD.`, 'company_status': `In Administration`},
        {'company_name': `BREWDOWN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRIAN CLEGG (EDUCATIONAL PRODUCTS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRIAN SHERIDAN CARS (HOUNSLOW) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `BRICK BREWERY LTD`, 'company_status': `In Administration`},
        {'company_name': `BRICKS K5 CAPITAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRIDGES ANTONINE LLP`, 'company_status': `In Administration`},
        {'company_name': `BRIDSTOCK GATE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRIERSTONE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRIGG MARINA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRIGHTBOROUGH CAPITAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRIGHTON VIBES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRINKLOW MARINA LTD`, 'company_status': `In Administration`},
        {'company_name': `BRISTOL & LONDON PLC`, 'company_status': `In Administration`},
        {'company_name': `BRIT-POL LTD`, 'company_status': `In Administration`},
        {'company_name': `BRITAIR SOUTHWEST LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRITANNIA SITE SOLUTIONS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BRITISH MIDLAND REGIONAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRITISH POLAR ENGINES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRK 001 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BROACH HILL GARAGE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BROADWAY FIBRE LLP`, 'company_status': `In Administration`},
        {'company_name': `BROADWAY PARTNERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BROADWAYS STAMPINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BROADWING LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `BROMFORD INDUSTRIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BROMPTON CROSS CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRON RELEASING UK LTD`, 'company_status': `In Administration`},
        {'company_name': `BRON STUDIOS UK LTD`, 'company_status': `In Administration`},
        {'company_name': `BROOK STREET PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BROOKS CYCLES & LEISURE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BROOKSLAND LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRS GROUNDWORKS LTD`, 'company_status': `In Administration`},
        {'company_name': `BRUNCHCO UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRUNLEA PRINT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BRYMOR CONSTRUCTION LTD.`, 'company_status': `In Administration`},
        {'company_name': `BRYMOR GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BS AND M REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BSB RETAIL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BSCL REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BSL REALISATIONS (2013) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BUCKINGHAM GROUP CONTRACTING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BUCKS CONCRETE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BUCKS DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BUDEMEADOWS COUNTRY PARK LTD`, 'company_status': `In Administration`},
        {'company_name': `BUDGET SKIPS AND RECYCLING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BUILD MODULAR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BUILDING PRODUCT SOLUTIONS LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `BUILT4LEARNING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BULB ENERGY LTD`, 'company_status': `In Administration`},
        {'company_name': `BULLIMORE UNDERWRITING AGENCY LTD.`, 'company_status': `In Administration`},
        {'company_name': `BUREAU MOVE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BUREAU WORKSPACE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BURGUARD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BURLEIGHS GIN LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BURLEIGHS HOLDINGS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `BURNTISLAND FABRICATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BURRY PORT MARINA LTD`, 'company_status': `In Administration`},
        {'company_name': `BURY CATHOLIC PREPARATORY SCHOOL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BUSINESS ENTERPRISE TECHNOLOGIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BUTTY SHOP LIMITED(THE)`, 'company_status': `Administration Order`},
        {'company_name': `BUXTON & HIGH PEAK SECURITY SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BUY 2 LET CARS LTD`, 'company_status': `In Administration`},
        {'company_name': `BV9 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BW REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BWLON EXPERIENCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `BX INTERNATIONAL HEALTHCARE LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `BYM CAPITAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `C & E STRUCTURES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `C & N PLUMBING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `C J SERVICES UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `C R A T E MEDIA ASSOCIATES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `C.C. FIRE ENGINEERING LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `C.L.C REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `C.P.E. PRECISION ENGINEERING COMPANY LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `C1 REALISATIONS (2020) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `C4C 2022 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CADENCE (EUROPE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CADWE REALISATIONS 2021 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CAFFE KIX LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CALDER METAL SPINNING COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CALDICOT FLEET MAINTENANCE RECOVERY LTD.`, 'company_status': `In Administration`},
        {'company_name': `CALON ENERGY (SEVERN) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CALON ENERGY (SUTTON BRIDGE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CALON ENERGY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CALVERLEY HOUSE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CAM REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CAM-TECH ENGINEERING (COVENTRY) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CAMBRIDGE COMMUNICATION SYSTEMS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CAMDEN TOWN CLUBS LTD`, 'company_status': `In Administration`},
        {'company_name': `CAMEL CREEK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CAMM FISHING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CAMPMUIR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CAMVAC LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CAMVO 37 LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CANBURG LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CANBURY CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CANDIDSKY LTD`, 'company_status': `In Administration`},
        {'company_name': `CANDY COTTAGE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CANON GARTH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CANTALIS LTD`, 'company_status': `In Administration`},
        {'company_name': `CAPARO INDUSTRIES PLC`, 'company_status': `In Administration`},
        {'company_name': `CAPHAN TRADING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CAPITAL DATA MANAGEMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CAPITAL DEVELOPMENTS WATERLOO LTD`, 'company_status': `In Administration`},
        {'company_name': `CAPITAL GROWTH GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CAPITAL KARTS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CAPITAL PHYSIO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CAPNOVA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CAPREOLUS FINE FOODS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CAR & VAN MATS LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CARBIDE DIES (BIRMINGHAM) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CARD & PARTY STORE (CASH & CARRY) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CARD AND PARTY WORLD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CARD EXPRESS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CARDEN PLACE (GENERAL PARTNER) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CARDIFF MARINE SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CARE CORPORATION LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `CARGOLOGICAIR LTD.`, 'company_status': `In Administration`},
        {'company_name': `CARING RETIREMENT HOMES LTD`, 'company_status': `In Administration`},
        {'company_name': `CARLAUREN GROUP LTD`, 'company_status': `In Administration`},
        {'company_name': `CARLAUREN PROPERTY HOLDINGS LTD`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `CARLAUREN TECHNOLOGY LTD`, 'company_status': `In Administration`},
        {'company_name': `CARLAUREN TRAVEL LTD`, 'company_status': `In Administration`},
        {'company_name': `CARLTON RESOURCE SOLUTIONS HOLDINGS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CARLYLE LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CARMEL BUILDING SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CARMICHAEL INTERNATIONAL LTD`, 'company_status': `In Administration`},
        {'company_name': `CARRISBROOKE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CARTERS HAULAGE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CASABLANCA CLOTHING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CASH PROCESSING SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CASH TERMINAL SYSTEMS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `CASHEURONET UK, LLC`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CASSELLIE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CASTLE BUSINESS FINANCE LTD`, 'company_status': `In Administration`},
        {'company_name': `CASTLE PRODUCTIONS (FARNHAM) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `CASTLE VIEW PROPERTIES UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CASTLEHOUSE CONSTRUCTION LTD`, 'company_status': `In Administration`},
        {'company_name': `CASTLEOAK HOLDINGS (CARDIFF GATE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CASTLEOAK OFFSITE MANUFACTURING LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CATERING SIXFIVE LTD`, 'company_status': `In Administration`},
        {'company_name': `CAVERSHAM FINANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CB REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CBR02 LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CCFB REALISATIONS 2022 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CCL35 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CCO REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CCS AND SON LTD`, 'company_status': `In Administration`},
        {'company_name': `CCTV MONITORING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CD REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CDL REALISATIONS 2022 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CDSL GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CEI ELECTRICAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CELEBRO STUDIOS LTD`, 'company_status': `In Administration`},
        {'company_name': `CELERITY COMMUNICATIONS LLP`, 'company_status': `In Administration`},
        {'company_name': `CELERITY INFORMATION SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CEMINVEST LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CENTRAL BUILDING CONTRACTORS (GLASGOW) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CENTRAL CAB CARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CENTRAL CRAIGAVON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CENTRAL ESSEX INTERIORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CENTRAL SECURITY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CENTRE FOR TECHNOLOGY LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `CENTRE PLANT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CENTURY CAPITAL PARTNERS NO. 1 LTD`, 'company_status': `In Administration`},
        {'company_name': `CERVEST LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CESTRIAN IMAGING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CFC FOOD PARTNERS (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CFC FOOD PARTNERS LLP`, 'company_status': `In Administration`},
        {'company_name': `CFJL PROPERTY PARTNERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CGC COURIERS LTD`, 'company_status': `In Administration`},
        {'company_name': `CGT REALISATIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `CH1234 LLP`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `CHADDERTON TOTAL-CARE UNIT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHAIN REACTION CYCLES LTD`, 'company_status': `In Administration`},
        {'company_name': `CHAIN REACTION CYCLES RETAIL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHALLEN COMMERCIAL INVESTIGATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHANGE RECRUITMENT GROUP LTD`, 'company_status': `In Administration`},
        {'company_name': `CHAPEL RIVERSIDE DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHAPMAN BLACK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHARLES MORGAN TRANSPORT LTD`, 'company_status': `In Administration`},
        {'company_name': `CHARLES ROBERTS CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHARLOTTE STREET AESTHETIC CLINIC LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHARNWAIT MANAGEMENT LTD`, 'company_status': `In Administration`},
        {'company_name': `CHARNWOOD PAINT SPECIALISTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHARWORTH HOMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHAS SMITH GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHATHAM WORKS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHEETWOOD ESTATES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHEEVERS POOLE LTD`, 'company_status': `In Administration`},
        {'company_name': `CHELFORD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHEM-DRY FRANCHISING LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CHERRY CAPITAL HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHERRY LENDING PROPERTY SUBSIDIARY 1 LTD`, 'company_status': `In Administration`},
        {'company_name': `CHESHUNT LAKESIDE DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHESNEY'S LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHESTER PROPERTIES EBBW VALE 1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHESTER PROPERTIES EBBW VALE 2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHESTER PROPERTIES FESTIVAL PARK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHEVERTON MUSIC LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `CHEVIOT HOLDINGS LTD`, 'company_status': `In Administration`},
        {'company_name': `CHI-CHI COLLECTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHICHESTER STONEWORKS (2014) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHIEFER & CO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHILLED REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHIP CHIP HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHOICE STATIONERY SUPPLIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHOLCO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHOONGADUNGA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHRISTCHURCH MARINA PARK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHRISTIAN INTERIORS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `CHRISTOPHER JACKSON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHRYSALIS SALES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHURCH LAKE LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CHURCHFIELD HOMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHURCHLAKE CARE LTD`, 'company_status': `In Administration`},
        {'company_name': `CHURCHLAKE HOLDINGS LTD`, 'company_status': `In Administration`},
        {'company_name': `CHURCHLAKE NE HOLD CO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CHX.LIFE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CI REALISATIONS 2022 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CINDERELLA CLEANING CONTRACTORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CINEWORLD GROUP PLC`, 'company_status': `In Administration`},
        {'company_name': `CINRAM NOVUM LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CIP HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CIRCULAR 1 HEALTH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CIRCULARITY SCOTLAND LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CITRUS ISLEWORTH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CITY CREDIT CAPITAL (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CITY EQUITIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CITY FINANCIAL INVESTMENT COMPANY LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CITY LINK LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CITY LIVING DEVELOPMENTS (IPSWICH) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CJ EMPLOYMENT SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CJCLOCKWORKS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CK ACQUISITIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CK REALISATIONS 2022 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLADDING DESIGN SERVICES LTD`, 'company_status': `In Administration`},
        {'company_name': `CLADDING SERVICES EASTERN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLAPHAM TAVERN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLAREMONT CENTRE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLAREMONT PEN DEVELOPMENT LTD`, 'company_status': `In Administration`},
        {'company_name': `CLARICO RECRUITMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLARK RETAIL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLARKE FUSSELLS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CLARKE NICHOLLS MARCEL (CIVIL & STRUCTURAL ENGINEERS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLASS ONE RECRUITMENT SOLUTIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `CLASSIC COUNTRY HOTELS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLASSIC FURNITURE GROUP LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CLEAR SURGICAL LTD.`, 'company_status': `In Administration`},
        {'company_name': `CLEARANCE SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLEAVER SCIENTIFIC LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLEMENT DICKENS & SON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLEMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLEVELAND BRIDGE UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLEVER-COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLIVE CHRISTIAN FURNITURE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLOCKFAIR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLOUDIQ LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLOUDVIEW (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLOUDVIEW HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLS FINANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CLSL 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CM REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CMA PRODUCTIONS UK LTD`, 'company_status': `In Administration`},
        {'company_name': `CMA SERIES HOLDINGS UK LTD`, 'company_status': `In Administration`},
        {'company_name': `CMME GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CMS (KIDDERMINSTER) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CMS ENVIRO SYSTEMS LTD.`, 'company_status': `In Administration`},
        {'company_name': `CN PLUMBING & HEATING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CN PROJECTS LTD`, 'company_status': `In Administration`},
        {'company_name': `CNG ELECTRICITY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CNIM UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CNX UTILITIES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `COALVILLE GLASS & GLAZING CO. LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COASTBRIGHT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COBSEN-DAVIES ROOFING (SOUTHERN) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COCOON WEALTH LLP`, 'company_status': `In Administration`},
        {'company_name': `CODAN RUBBER LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `COGENT PASSENGER SEATING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COGENT PROJECTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COGKING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COGRESS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COHEN GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COILCOLOR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COINO UK LTD.`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `COLAMET LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COLDCO1 LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `COLEMANS SOLICITORS LLP`, 'company_status': `In Administration`},
        {'company_name': `COLESHILL RETAIL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COLIN MEAR ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COLIN MEAR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COLORADO ENERGY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COLOURPOINT LITHO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COLOURWORLD LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `COMBINED TREE SERVICES - TREE SURGEONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COMPASS BUILDERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COMPLETE UTILITIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COMPOUND PHOTONICS GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COMPOUND PHOTONICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COMPOUND PHOTONICS UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CONAIR (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CONCEPT BRICKWORK LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `CONCORD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CONDER ALLSLADE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CONDITIONED ENVIRONMENT MECHANICAL PROJECTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CONDITIONED ENVIRONMENT MECHANICAL SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CONNECTMETOALAWYER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CONSERVATORY BLINDS 4 LESS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CONSTRUCTION TRADE SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CONSUMA PAPER PRODUCTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CONSUMER CHOICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CONTACT TRANSPORT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CONTAINERCARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CONTINENTAL PLANT AND EQUIPMENT LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `CONTINENTAL TRADE AND COMMODITY SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CONTRACTORCARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CONTROLLED MAINTENANCE (HOLDINGS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CONTROLLED MAINTENANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COOK & LUCAS FROZEN (HOLDINGS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COOK & LUCAS FROZEN (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COOKIES & CONES LTD`, 'company_status': `In Administration`},
        {'company_name': `COOL MOVERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COOLFRUIT LTD`, 'company_status': `In Administration`},
        {'company_name': `COOLMORE LAND LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COOLTHERM INSTALLATION SERVICES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `COOPLANDS DIRECT LTD`, 'company_status': `In Administration`},
        {'company_name': `COPA FIN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CORIOLIS TECHNOLOGIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CORNERSTONE BUILDING SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CORNICHE CONSTRUCTION LTD`, 'company_status': `In Administration`},
        {'company_name': `CORPORATE & PROFESSIONAL PENSIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CORPORATE COMMERCIAL COLLECTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CORPORATE ROAD SOLUTIONS 24:7 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CORPORATE SOLUTIONS (LOGISTICS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CORYLOS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COTSWOLD MANUFACTURING LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `COTSWOLDGATE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COTTAGE INDUSTRIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COUNTESSWELLS DEVELOPMENT (NO.1) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COUNTESSWELLS DEVELOPMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COUNTRY ATTIRE HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COUNTRY ATTIRE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COUNTRY LIFE UPHOLSTERY LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `COUNTRYSIDE REFURB LTD`, 'company_status': `Administration Order`},
        {'company_name': `COUNTRYWIDE HOMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COUNTY TOWER PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COURTYARD DEVELOPMENTS (NEWCASTLE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `COVENTRY CITY OF CULTURE TRUST`, 'company_status': `In Administration`},
        {'company_name': `COVER STRUCTURE LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `COX + COX MAIL ORDER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CPB REALISATIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `CPL REALISATIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `CPS DRIVELINK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CPSPV58 LTD`, 'company_status': `In Administration`},
        {'company_name': `CRAFT RETAIL INTERNATIONAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CRAFTS & WOOLLENS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `CRAIGARD CARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CRANLEY CLINIC LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CREATE CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CREATE FINANCIAL MANAGEMENT LLP`, 'company_status': `In Administration`},
        {'company_name': `CREMLON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CROSS TRANSPORT LTD`, 'company_status': `In Administration`},
        {'company_name': `CROSSLANDS PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CROSSLANE (HUNTINGDON STREET NOTTINGHAM) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CROSSLANE STUDENT DEVELOPMENTS UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CROSSLEYS ACCIDENT REPAIR CENTRE LTD`, 'company_status': `In Administration`},
        {'company_name': `CROSSPOINT LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `CROUCH LOGISTICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CROUCHER NEEDHAM (ESSEX) LLP`, 'company_status': `In Administration`},
        {'company_name': `CROWN PEST CONTROL & ENVIRONMENTAL SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CRUDEN CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CRUDEN GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CRUDEN PROPERTY SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CRYSTAL CLEAR GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CRYSTAL CLEARANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CRYSTAL CROFTDENE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CRYSTAL EUROPE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CS WIND UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CSDL REALISATIONS 2022 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CSF MEDICAL COMMUNICATIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `CSG FM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CT ASSET REALISATIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `CT PLUS (YORKSHIRE) COMMUNITY INTEREST COMPANY`, 'company_status': `In Administration`},
        {'company_name': `CT PROPERTY LTD`, 'company_status': `In Administration`},
        {'company_name': `CTBO LTD`, 'company_status': `In Administration`},
        {'company_name': `CULLINGFORD CARPETS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CULTURA GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CUMBRIA NURSING SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CURER-CHEM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CUROT CONTRACTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CURVEBALL MEDIA LTD`, 'company_status': `In Administration`},
        {'company_name': `CUSSONS TECHNOLOGY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CUSTOMISED ELECTRICAL SYSTEMS LTD`, 'company_status': `In Administration`},
        {'company_name': `CX REINSURANCE COMPANY LIMITED.`, 'company_status': `In Administration`},
        {'company_name': `CY THIRSK LTD`, 'company_status': `In Administration`},
        {'company_name': `CYANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `CYGNUS ESTATES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `D & A ARCHITECTURAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `D & P LOVELL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `D C M TYRES (WESSEX) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `D REALISATION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `D&A PROJECTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `D&A RECRUITMENT LTD`, 'company_status': `In Administration`},
        {'company_name': `D&R FRIPP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `D2 ELECTRICAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DA SPAGO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DAKO CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DAKRO ENVIRONMENTAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DALIGAS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DALKEITH APARTMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DAMSON CONSULTANCY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DAMSONETTI UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DANESCOURT ROOFING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DANESFIELD HOUSE HOTEL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DARTMOUTH GENERAL CONTRACTORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DARWEN ASSET MANAGEMENT LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `DATATRIAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DAVID JAMESON SERVICES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `DAVID MORIN (BUILDERS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DAVIDSON & MORRISON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DAVID’S BRIDAL UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DAWNFRESH SEAFOODS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DAWNUS CONSTRUCTION HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DAWNUS DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DAWNUS GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DAWNUS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DAWNUS SOUTHERN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DB DENMAN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DB PROP 3 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DB SOHO PROP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DBS CLOTHING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DBS EUROPE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DBS ONLINE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DC BARS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DC REALISATIONS 1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DC REALISATIONS 2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DC REALISATIONS 3 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DC REALISATIONS 4 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DCB (KENT) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DCP CAROLINE 2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DCP CAROLINE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DD FRAMES LTD`, 'company_status': `In Administration`},
        {'company_name': `DEAD VIBEY LEISURE LTD`, 'company_status': `In Administration`},
        {'company_name': `DEADLINE TOOLING SERVICES LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `DEAL PARTNERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DEANPRINT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DECORWISE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DEERS LEAP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DEFABS ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DELAMARE ESTATE(CHESHUNT)LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DELIGHTFUL DESSERTS FRANCHISE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DELUXE DESIGNS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `DEMRADENE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DENCO THERMAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DENZEL POWER LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `DEPARTMENT STORES REALISATIONS (PROPERTIES) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DEPARTMENT STORES REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DEPOT ENGINEERING COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DERIGON LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `DESIGNER CHILDRENSWEAR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DETRAFFORD (REGIMENT) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DETRAFFORD CITY GARDENS LTD`, 'company_status': `In Administration`},
        {'company_name': `DETRAFFORD GALLERY GARDENS BLOCK A LTD`, 'company_status': `In Administration`},
        {'company_name': `DETRAFFORD NO1 CASTLEFIELD LTD`, 'company_status': `In Administration`},
        {'company_name': `DETRAFFORD SKY GARDENS LTD`, 'company_status': `In Administration`},
        {'company_name': `DETRAFFORD ST GEORGES GARDENS LTD`, 'company_status': `In Administration`},
        {'company_name': `DETRAFFORD WAVELENGTH LTD`, 'company_status': `In Administration`},
        {'company_name': `DEVELOPMENT INTELLIGENCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DEVELOPMENT THREE LTD`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `DEVERON CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DEVERON HOMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DEWCO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DHOKE 1 LTD`, 'company_status': `In Administration`},
        {'company_name': `DHR BUSINESS SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DI REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DIAMOND COMMERCIAL RECRUITMENT LTD`, 'company_status': `In Administration`},
        {'company_name': `DIAMOND GLOBAL TRADING & INVESTMENTS LTD`, 'company_status': `In Administration`},
        {'company_name': `DICKINSONS (PLUMBING & HEATING CONTRACTORS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DIGIVAULT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DILLONS STORES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DIRECT MARKET SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DIRTY TRADING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DISIGN HOUSE MAIDSTONE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DISMANTLING & ENGINEERING LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `DISSINGTON LENDING COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DIVISION ONE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DJS (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DLUX DEVELOPMENTS LTD`, 'company_status': `In Administration`},
        {'company_name': `DMD OPERATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DMD RECRUITMENT LTD`, 'company_status': `In Administration`},
        {'company_name': `DMH CONTRACTORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DNA (NESTLES SQUARE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DOG STAY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DOISY & DAM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DOLFIN FINANCIAL (UK) LTD`, 'company_status': `In Administration`},
        {'company_name': `DOLPHIN LIFTS MIDLANDS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DOMINION ATM BANKING SYSTEMS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DOMO DEVELOPMENTS LTD`, 'company_status': `In Administration`},
        {'company_name': `DON PEPE TAPAS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `DON TARMACADAM LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `DONCASTER ENTERPRISE PARK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DONCASTER PHARMACEUTICALS GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DOOMSDAY CO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DORMEO UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DORSET CARAVAN PARK 3 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DORSET CARAVAN PARK 5 LTD`, 'company_status': `In Administration`},
        {'company_name': `DORSET HEIGHTS CARAVAN PARK LTD`, 'company_status': `In Administration`},
        {'company_name': `DOUGLAS & GORDON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DREAMSPORT LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `DRINKS BROKERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DRIVELINE ENGINEERING SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DRL REALISATIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `DROGO DEVELOPMENTS LTD`, 'company_status': `In Administration`},
        {'company_name': `DRYNKS UNLIMITED LTD.`, 'company_status': `In Administration`},
        {'company_name': `DS STRUCTURES LTD`, 'company_status': `In Administration`},
        {'company_name': `DSC REALISATIONS (2008) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `DT ENGINEERING (NORTH WEST) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DUKINFIELD GC MANAGEMENT COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DUNHAM-BUSH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DUNNETTCRAVEN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DUNTON PARK CARAVAN SITES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DUOFORM PRECISION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DURISOL UK LTD.`, 'company_status': `In Administration`},
        {'company_name': `DURSTON GROUP LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `DUTCHY INVESTMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DUTTON CONTRACTORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DVC RESTAURANTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DWPF SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `DYNAMIC M&E LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `DYSON DIECASTINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `E J CIVILS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `E REALISATIONS 2020 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `E-NOVA HEALTHCARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `E-NOVA TRADING LTD`, 'company_status': `In Administration`},
        {'company_name': `E.L. COOK ELECTRICAL LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `E.LEGAL TECHNOLOGY SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EAD SOLICITORS LLP`, 'company_status': `In Administration`},
        {'company_name': `EAGLE INTERNATIONAL DISTRIBUTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EAGLEMOSS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EARTHY FOODS & GOODS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EAST LONDON PUB CO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EAST LONDON TAVERNS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EAST SUSSEX LANDSCAPES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EAST WEST INSURANCE COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EASTBOURNE HOUSE LTD`, 'company_status': `In Administration`},
        {'company_name': `EASTCHURCH HOLIDAY CENTRE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EASTERN ATLANTIC HELICOPTERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EASTGATE MANOR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EBOR CONCRETES LTD`, 'company_status': `In Administration`},
        {'company_name': `EC 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EC3 BROKERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ECLIPSE COMMUNICATIONS GERMANY GMBH`, 'company_status': `In Administration`},
        {'company_name': `ECLIPSE DISTRIBUTION SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ECLIPSE GENERICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ECNAVLA 8 UK LTD`, 'company_status': `In Administration`},
        {'company_name': `ECO MECHANICAL SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ECOGROVE HOMES LTD`, 'company_status': `In Administration`},
        {'company_name': `ECOSSE MOTORCYCLES LTD`, 'company_status': `In Administration`},
        {'company_name': `ECOTEC SERVICES LTD`, 'company_status': `In Administration`},
        {'company_name': `ECS PETERBOROUGH 1 LLP`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `EDGE ARCHITECTURE + DESIGN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EDGE DBS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EDUCATIONAL SYSTEMS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EDWARDS VENTURES (UK) LTD`, 'company_status': `In Administration`},
        {'company_name': `EFAM LTD`, 'company_status': `In Administration`},
        {'company_name': `EG 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EGERTON LODGE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EIDER VF LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EISENMANN U.K. LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EK DEVCO LTD`, 'company_status': `In Administration`},
        {'company_name': `ELDON STREET HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ELECRO LTD.`, 'company_status': `In Administration`},
        {'company_name': `ELECTRIC RIDER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ELECTROSAFE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ELECTRUEPART LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ELEMMIRE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ELEVATED ENGINEERING SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ELIGA SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ELITE BUILDING CONTRACTORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ELITE CONTRACT LIFTING AND PLANT MOVEMENT LTD`, 'company_status': `In Administration`},
        {'company_name': `ELITE FOOD PARTNERS LTD`, 'company_status': `In Administration`},
        {'company_name': `ELITE SECURITY (NW) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ELLERINGTON ENGINEERING LTD`, 'company_status': `In Administration`},
        {'company_name': `ELLI FINANCE (UK) PLC`, 'company_status': `In Administration`},
        {'company_name': `ELLICON CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ELLON PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ELMEX LONDON LUTON AIRPORT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ELMINGTON HOMES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ELPHINSTONE ESTATES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ELYSIUM GROUP DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EM2020 REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EMB REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EMD REALISATIONS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `EMERALD GLOBAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EMINENT GRP LTD`, 'company_status': `In Administration`},
        {'company_name': `EMIRA DEVELOPMENTS LTD`, 'company_status': `In Administration`},
        {'company_name': `EMPATHY UK FUNERAL PLANS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EMPIRE CINEMAS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EMPIRE TBR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EMPYREAN SMA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ENERGY GENERATOR HIRE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ENERGY GENERATOR SALES LTD`, 'company_status': `In Administration`},
        {'company_name': `ENERGY HAULAGE LTD`, 'company_status': `In Administration`},
        {'company_name': `ENERGY HOLDINGS (NO. 4) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `ENERGY HOLDINGS (NO. 5) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `ENERGY HOLDINGS (NO.2) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `ENERGY KEY TRADING (1993) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `ENERGY PRODUCTION HIRE LTD`, 'company_status': `In Administration`},
        {'company_name': `ENGINEERING PRECISION SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ENGRAVE IT ONLINE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ENSIGN COMMUNICATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ENTERPRISE BUSINESS TECHNOLOGIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ENTICE ENERGY SUPPLY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ENVIRO BUILDING SOLUTIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `ENVIROTECH (UK) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `ENVISAGE ASSOCIATES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EP23 LLP`, 'company_status': `In Administration`},
        {'company_name': `EPICURIA LONDON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EPICURIA LORD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EPR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EQUUS PRODUCTS AND SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ERNEST BROOKS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EROS INTERNATIONAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ES MANUFACTURING LTD`, 'company_status': `In Administration`},
        {'company_name': `ES REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ESR 2022 PLC`, 'company_status': `In Administration`},
        {'company_name': `ESSINGTON CEMETERY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ETRA LTD`, 'company_status': `In Administration`},
        {'company_name': `ETS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EUROMOTIVE (KENT) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EUROPEAN SKYTIME LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `EUROSCOT RENTALS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EUROTEC GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EVCL CHILL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EVCL ONE LTD`, 'company_status': `In Administration`},
        {'company_name': `EVCL THREE LTD`, 'company_status': `In Administration`},
        {'company_name': `EVCL TWO LTD`, 'company_status': `In Administration`},
        {'company_name': `EVERSMART ENERGY LTD`, 'company_status': `In Administration`},
        {'company_name': `EXCEL BUILDING & DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EXETER NUMBER 1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EXHIBITIONS HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EXITROUTE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EXPERT SURVEYING SERVICES LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `EXPLORATION LTD`, 'company_status': `In Administration`},
        {'company_name': `EXSEL DYTECNA LTD.`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `EXTENTIA GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `EXTERGEO INDUSTRIES LTD`, 'company_status': `In Administration`},
        {'company_name': `EXTRUPOL PACKAGING LTD`, 'company_status': `In Administration`},
        {'company_name': `F (2023) REALISATION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `F (REALISATIONS) 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `F. W. E. REALISATIONS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `F.H. GILMAN & CO`, 'company_status': `In Administration`},
        {'company_name': `F.H.S. DISTRIBUTION LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `FACILITIES REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FAIRBRIDGE (RESTORED) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FAIRFIELD VIEW CARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FAIRNET COMMERCIAL SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FAIRPRINT LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `FALCI CONSTRUCTION LTD`, 'company_status': `In Administration`},
        {'company_name': `FALCON INVESTMENT LTD`, 'company_status': `In Administration`},
        {'company_name': `FAMEDIAM LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `FAMOUSLY PROPER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FAN DATA POOLS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FARADAY PRINTED CIRCUITS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FARE BAR & CANTEEN LTD`, 'company_status': `In Administration`},
        {'company_name': `FARMDROP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FARRELLYS METAL POLISHERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FAST FASHION COLLECTIONS INTERNATIONAL LTD`, 'company_status': `In Administration`},
        {'company_name': `FAST SALE TODAY LTD`, 'company_status': `In Administration`},
        {'company_name': `FB REALISATIONS 2017 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FBE REALISATIONS 2021 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FC HOMES LTD.`, 'company_status': `In Administration`},
        {'company_name': `FC REALISATIONS 8 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FCL CONSUMER FINANCE LTD`, 'company_status': `In Administration`},
        {'company_name': `FDL REALISATIONS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `FEAST FIT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FEERICK LTD`, 'company_status': `In Administration`},
        {'company_name': `FEL GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FERGUSON MARINE LTD`, 'company_status': `In Administration`},
        {'company_name': `FEROX RESOURCING LTD`, 'company_status': `In Administration`},
        {'company_name': `FESTICKET LTD`, 'company_status': `In Administration`},
        {'company_name': `FIDO FINANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FIELD AND CIVIL ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FIENNES RESTORATION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FIERCE PC LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FILMHOUSE TRADING LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `FILMWAVE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FINABLR PLC`, 'company_status': `In Administration`},
        {'company_name': `FINELINE ALUMINIUM DESIGN LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `FINSOL SYSTEMS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FIRBER ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FIRE CONTROL (GLASGOW) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `FIRECLAD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FIREFLY ENERGI ORKNEY LTD`, 'company_status': `In Administration`},
        {'company_name': `FIRST CHOICE DESIGN AND DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FIT-OUT GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FITNESS FIRST (CURZONS) LTD.`, 'company_status': `In Administration`},
        {'company_name': `FITNESS SYSTEMS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `FITTS LTD`, 'company_status': `In Administration`},
        {'company_name': `FJ ESTATES LTD`, 'company_status': `In Administration`},
        {'company_name': `FJ RESIDENTIAL (430 FULHAM PALACE ROAD) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FL LINCS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FL MEAT REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FLAME HEATING SPARES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FLEET LEISURE LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `FLEET STREET LIVERPOOL LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `FLETTON QUAYS HOTEL LTD`, 'company_status': `In Administration`},
        {'company_name': `FLEXENABLE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FLEXI OFFICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FLEXIBLE TECHNOLOGY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FLEXTRUDE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FLIGHTS OF FANTASY CREATIVE PLAY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FLIX FACILITIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FLOORING YORKSHIRE LTD`, 'company_status': `In Administration`},
        {'company_name': `FLOORS TO WALLS LTD`, 'company_status': `In Administration`},
        {'company_name': `FLORCRAFT LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `FLOWERCARD INTERNATIONAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FLUIDIC ANALYTICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FLYBE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FLYLOGIX LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FM VENTILATION SERVICES LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `FOCUSED SERVICES GROUP LTD`, 'company_status': `In Administration`},
        {'company_name': `FOG FELLOW DESIGNS LTD`, 'company_status': `In Administration`},
        {'company_name': `FOOD CONTAINERS MANUFACTURING UK LTD`, 'company_status': `In Administration`},
        {'company_name': `FOOD INNOVATIONS (MANUFACTURING) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FOOD INNOVATIONS BAKING GROUP LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `FOOD INNOVATIONS HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FOOTFALLS AND HEARTBEATS (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FORGE FARMS LIVESTOCK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FORM MANUFACTURING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FORMATION FURNITURE LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `FORMOSA ACCESS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `FORMULA K INTERNATIONAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FORT CARAVAN PARK LTD`, 'company_status': `In Administration`},
        {'company_name': `FORTE BAILEY (SOUTHBOROUGH 2) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FORTHPLUS PENSIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FORTISSIMO CHOCOLATES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FORTNIGHT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FORTOAK ROLLS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FORTRESS CAPITAL PARTNERS LTD.`, 'company_status': `In Administration`},
        {'company_name': `FORTUNA PLUS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FORWARD CLINICAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FORWARDFORCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FORZA PROJECTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FOUNDA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FOXDALE ENGINEERING SERVICES LTD`, 'company_status': `In Administration`},
        {'company_name': `FOXTROT REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FPL2021 LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `FRAMES CONSERVATORIES DIRECT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FRANCIS GILMARTIN & CO. LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `FRANK B THORNTON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FRANK BROWN & SON LUTON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FRANK'S ICE CREAM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FRANKLIN EV LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FREE WIND ISA BOND LTD`, 'company_status': `In Administration`},
        {'company_name': `FREEHAND 2010 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FREIGHT MANAGEMENT EUROPEAN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FRENO BRAKES LTD`, 'company_status': `In Administration`},
        {'company_name': `FRENSHAM COUNTRY PARK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FRESH PASTURES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FRESH WILLOW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FRITCHER 2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FRJ REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FRONTGRID LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FRONTLINE HEALTHCARE LTD`, 'company_status': `In Administration`},
        {'company_name': `FROST BURGERS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `FRUEHAUF LTD`, 'company_status': `In Administration`},
        {'company_name': `FRY LAW LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `FSDC PLC`, 'company_status': `In Administration`},
        {'company_name': `FTREE REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FULL HOUSE (INTERIORS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FULL HOUSE PROPERTIES LTD`, 'company_status': `In Administration`},
        {'company_name': `FULLER DAVIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FUNDINGSECURE LTD`, 'company_status': `In Administration`},
        {'company_name': `FUNDRAISING INNOVATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FURMANAC LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FURNESS HOUSE DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FURNESS QUAY PHASE 1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FUTURAMA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FUTURE (CARRYDUFF) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FUTURE (DAREDUFF HILL) LTD`, 'company_status': `In Administration`},
        {'company_name': `FUTURE (DRIGG) LTD`, 'company_status': `In Administration`},
        {'company_name': `FUTURE (DUNCAN) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FUTURE (PENNYGILLAM) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FUTURE (POMEROY) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FUTURE (THOMSON) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FUTURE (WIND GEN) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FUTURE ENGINEERING RECRUITMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FUTURE RENEWABLES ECO PLC`, 'company_status': `In Administration`},
        {'company_name': `FUTURE TECHNOLOGY SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FUTUREFUEL OPERATIONS (214) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FUTUREFUEL OPERATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FUTUREFUEL RENEWABLES II PLC`, 'company_status': `In Administration`},
        {'company_name': `FUTUREFUEL RENEWABLES PLC`, 'company_status': `In Administration`},
        {'company_name': `FW REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FYRE RESTAURANTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `FYSHE HORTON FINNEY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `G FORCE GROUP LTD`, 'company_status': `In Administration`},
        {'company_name': `G P MOLD HEATING AND PLUMBING ENGINEERING LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `G REALISATIONS 2022 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `G SQUARED GAMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `G-FORCE LOGISTICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `G. D. COOPER & CO LTD`, 'company_status': `In Administration`},
        {'company_name': `G.C.G. SHOTBLASTING SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `G.L.T.C. LIMITED`, 'company_status': `In Administration`},
        {'company_name': `G.W.BUTLER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `G13R LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GABLE ESSEX LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GAILYGLEN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GAMALLT READY MIXED CONCRETE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GARDNERS NW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GARENNE SHOPFITTING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GAROB OSWALD ROAD LTD`, 'company_status': `In Administration`},
        {'company_name': `GARROCH INVESTMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GARTH BAKERY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GASCO UTILITIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GASOL PLC`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `GATLEY FLUID POWER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GAUDI LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GAUDI REGULATED SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GB FRUIT COMPANY PLC`, 'company_status': `In Administration`},
        {'company_name': `GBG EUROPE FOOTWEAR & ACCESSORIES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `GBQ INVESTMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GCH REALISATIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `GDC ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GECKO DIRECT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GELLAW NEWCO 203 LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `GENERAL CONSOLIDATED LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GENERAL SUBSIDIARY 2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GENUS UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GEORGE & DRAGON (BUCKS) LTD`, 'company_status': `In Administration`},
        {'company_name': `GEORGE S. FORMAN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GEORGES TRADITION (BELPER) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `GEORGES TRADITION LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `GEOTHERMAL INTERNATIONAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GERMAN LISTED BONDS PLC`, 'company_status': `In Administration`},
        {'company_name': `GET ME TO THE ALPS LTD`, 'company_status': `In Administration`},
        {'company_name': `GETSETGO! FAMILY CLUBS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GFL REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GIEVES & HAWKES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GIFTS 4 ALL OCCASIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GILWOOD (FABRICATORS) COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GLASGOW CAR MOVERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GLASGOW CHIROPRACTIC LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `GLASSHUS FACADES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GLENDINE DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GLISSER LTD`, 'company_status': `In Administration`},
        {'company_name': `GLOBAL DESIGN SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GLOBAL DOORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GLOBAL FINE FOODS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GOALS SOCCER CENTRES PLC`, 'company_status': `In Administration`},
        {'company_name': `GOBSMACK HOLDINGS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `GODFREY - SYRETT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GOLA WOOD WORKS LTD`, 'company_status': `In Administration`},
        {'company_name': `GOLDEN LEAS HOLIDAY PARK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GOLF ROAD INSTRUMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GOODWIN'S CONSTRUCTION SERVICES GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GOSSIP CLOTHING LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `GOURMET INTERNATIONAL LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `GPC SIPP LTD`, 'company_status': `In Administration`},
        {'company_name': `GRAFT SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GRAHAM JONES CRANES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GRAHAM NEIL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GRANDVIEW HOUSE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GRANIT-OPS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GRANLEAF SQUARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GRANT ESTATES LTD.`, 'company_status': `In Administration`},
        {'company_name': `GRANTLEY HOMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GRAPHOPRINT LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `GRASSFIELDS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GREASBROUGH RESIDENTIAL & NURSING HOME LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `GREAT BRITISH FARMS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GREAT BRITISH PRAWNS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GREAVES DATA SYSTEMS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `GREEN 2 DELIVERY LTD`, 'company_status': `In Administration`},
        {'company_name': `GREEN BIOFUELS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GREEN LIFE BUILDINGS LTD`, 'company_status': `In Administration`},
        {'company_name': `GREEN WAY FOODS LTD`, 'company_status': `In Administration`},
        {'company_name': `GREENJINN LTD`, 'company_status': `In Administration`},
        {'company_name': `GREENLIGHT CREDIT LTD`, 'company_status': `In Administration`},
        {'company_name': `GREENOVER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GREENSILL CAPITAL (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GREENSILL CAPITAL MANAGEMENT COMPANY (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GREENSTORC LTD`, 'company_status': `In Administration`},
        {'company_name': `GRESHAM LAND AND ESTATES (CONSTRUCTION) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GRESWOLDE CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GREYDAY LLP`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `GREYHOUND ACQUICO LTD`, 'company_status': `In Administration`},
        {'company_name': `GRIFF CHAINS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GRIFF HOLDINGS LTD`, 'company_status': `In Administration`},
        {'company_name': `GRINDALE LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `GRL REALISATIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `GRONO LAWNS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GROSVENOR BRIDGING LOANS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GROUNDWORK COMMUNITY FORESTS NORTH EAST DEVELOPMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GROUPMOVE LTD`, 'company_status': `In Administration`},
        {'company_name': `GROWELL HORTICULTURE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GROWTH ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GROWTH POWER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GRŴP WRW CYF`, 'company_status': `In Administration`},
        {'company_name': `GS CONSTRUCTION ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GS STORC POWER I (UK) LTD`, 'company_status': `In Administration`},
        {'company_name': `GSCU REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GTR AGGREGATES & RECYCLING LTD`, 'company_status': `In Administration`},
        {'company_name': `GUILDFORD (B4F) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GUILDFORD PROPERTIES LLP`, 'company_status': `In Administration`},
        {'company_name': `GULMAR BADARO MARINE INVESTMENT S.A.`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `GULMAR CONDOR MARINE INVESTMENT S.A.`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `GULMAR EAGLE MARINE INVESTMENT S.A.`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `GULMAR ENERGY S.A`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `GULMAR FALCON MARINE INVESTMENT S.A.`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `GUN TAVERN (SPITALFIELDS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GURDLAND LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `GWE REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `GWKS LTD`, 'company_status': `In Administration`},
        {'company_name': `GWLAWYER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `H GREAVES & SON (MEAT PRODUCTS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `H M PROJECT DEVELOPMENTS LTD`, 'company_status': `In Administration`},
        {'company_name': `H. B. PROJECTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `H. MITTON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `H.BEARDSLEY LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `H.I. QUALITY STEEL CASTINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `H.P.A.S. LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HACFIELD LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `HACINDY PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HACKWOOD HOMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HADDON & JAMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HADFIELDS THE BAKERY LTD`, 'company_status': `In Administration`},
        {'company_name': `HAL TELECOM SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HALL WOOD FOREST DEVELOPMENTS LTD`, 'company_status': `In Administration`},
        {'company_name': `HALLETTS GROUP LTD`, 'company_status': `In Administration`},
        {'company_name': `HALLMARK SHEET METAL CO. LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HALO RECRUIT LTD`, 'company_status': `In Administration`},
        {'company_name': `HALSALL GLASS NW LTD`, 'company_status': `In Administration`},
        {'company_name': `HAM BAKER GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HAM BAKER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HAMILTON MAYDAY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HAMON UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HAMSARD 2363 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HAMSARD 3225 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HANHAA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HANKINSON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HANOVER AIDS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `HAPPY HADDOCK, SHANKLIN LTD`, 'company_status': `In Administration`},
        {'company_name': `HARDACRE INVESTMENTS (WEMBDON) LTD`, 'company_status': `In Administration`},
        {'company_name': `HARDY AMIES LONDON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HARLOW ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HARPERS ENVIRONMENTAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HARRIS PYE POWER SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HARRISON JORGE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HARRISONS ELECTRICAL SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HARTHAM PARK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HARTLEY PENSIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HARTMANN CAPITAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HARWELL ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HASLAM ENTERPRISES LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `HAWKRENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HAWKSMITH INSTALLATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HAWLEY PLACE SCHOOL LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `HAYDEN HOMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HAYDON BRIDGE PHARMACY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HAYLING ISLAND HOLIDAY PARK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HAYNES MANAGED SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HAYRE INVESTMENTS & DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HAYRE INVESTMENTS ( EALING ) LTD.`, 'company_status': `In Administration`},
        {'company_name': `HAYRE INVESTMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HBL REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HBR (TW) MF LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HC FACILITY MANAGEMENT IN ADMINISTRATION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HC REALISATIONS 2023 LTD`, 'company_status': `In Administration`},
        {'company_name': `HCC REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HCG REALISATIONS 2021 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HCT GROUP`, 'company_status': `In Administration`},
        {'company_name': `HCT GROUP OPERATIONS COMMUNITY INTEREST COMPANY`, 'company_status': `In Administration`},
        {'company_name': `HEALTH MADE EASY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HEALTH OPTIMISING (UK) LTD`, 'company_status': `In Administration`},
        {'company_name': `HEALTH STORES (WHOLESALE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HEALTHIER TASTIER FOOD LTD`, 'company_status': `In Administration`},
        {'company_name': `HEATH SPRINGS AND COMPONENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HEATHLAND SCHOOL LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `HELIX FITNESS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `HELP ME STOP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HENDERSON SITE SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HENLEY CONSTRUCT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HENLEY CONSTRUCTION & DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HENLEY HOMES PUBLIC LIMITED COMPANY`, 'company_status': `In Administration`},
        {'company_name': `HENLEYS AGGREGATES LTD`, 'company_status': `In Administration`},
        {'company_name': `HENRAD PLUMBING & HEATING LTD`, 'company_status': `In Administration`},
        {'company_name': `HENRY CONSTRUCTION PROJECTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HEPS SELLCO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HERBIVORE RESTAURANTS LTD`, 'company_status': `In Administration`},
        {'company_name': `HERITAGE HOTELS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HERMITAGE MEWS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `HERRINGTON MEWS LTD`, 'company_status': `In Administration`},
        {'company_name': `HESELWOOD & GRANT SOLICITORS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `HEWORTH GREEN DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HEXTRANSFORMA HEALTHCARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HEYLAND & WHITTLE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HEYWOODS ESTATE AGENTS & SURVEYORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HF DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HF STORES REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HGEC CAPITAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HH LAW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HI-LINE CONTRACTORS S.W. LTD.`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `HIDE STATIONERY LTD`, 'company_status': `In Administration`},
        {'company_name': `HIGH STREET GRP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HIGH STREET SOLICITORS LTD`, 'company_status': `In Administration`},
        {'company_name': `HIGH TENSILE ROOFING SYSTEMS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `HIGH WYCOMBE CINEMA NUMBER TWO 2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HIGHLAND LOUGHBOROUGH LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `HILL & ABBOTT LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `HILL & STANDARD DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HILL & SZROK LTD`, 'company_status': `In Administration`},
        {'company_name': `HILLEND VIEW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HINCROFT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HIPPOCAMPUS MARINE LTD`, 'company_status': `In Administration`},
        {'company_name': `HIPPOCAMPUS NEARSHORE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HIREWELL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HITCHAM HOMES LTD`, 'company_status': `In Administration`},
        {'company_name': `HL REALISATIONS (2023) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HODDERS LAW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HODGSON ACOUSTICS COMPANY LTD`, 'company_status': `In Administration`},
        {'company_name': `HOLESHOT MOTOCROSS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HOLLINBAY CONSULTANCY SERVICES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `HOME CONTROL SOLUTIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `HONEST BREW LTD`, 'company_status': `In Administration`},
        {'company_name': `HOPUK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HORIZON CONTROLS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HORNCASTLE EXECUTIVE TRAVEL LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `HORTON ESTABLISHMENTS LTD`, 'company_status': `In Administration`},
        {'company_name': `HOTCHA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HOTEL 52 (SEA) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HOTLINES EUROPE LTD`, 'company_status': `In Administration`},
        {'company_name': `HOUGH ESTATES (MANAGEMENT) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HOUNSLOW PROPERTY DEVELOPMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HOUSE COLLECTION LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `HOUSE OF FUN NURSERY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HOUSE OF JORDAN LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `HOUSEOLOGY DESIGN GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HOWARD HUNT (CITY) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HOWARD HUNT MAIL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HOWARD RUSSELL CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HR PHARM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HS BAGS LTD`, 'company_status': `In Administration`},
        {'company_name': `HSHANSEN LTD`, 'company_status': `In Administration`},
        {'company_name': `HSN CARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HUFFEY GROUP LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `HUGG HOMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HUGHES ROBERTS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `HULK SCAFFOLDING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HUMAC CIVIL ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HUME CAPITAL SECURITIES PLC`, 'company_status': `In Administration`},
        {'company_name': `HUNTON PARK LTD`, 'company_status': `In Administration`},
        {'company_name': `HW RLS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HYDRA INTERNATIONAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HYTECH GLASS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `HYTHE VENEERING COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `I D C LIMITED`, 'company_status': `In Administration`},
        {'company_name': `I.D.D.E.A. LTD`, 'company_status': `In Administration`},
        {'company_name': `IBP MARKETS LTD`, 'company_status': `In Administration`},
        {'company_name': `ICD ENERGY GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ICE PROCESSING TECHNOLOGIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ICEGRADE GROUP LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ICON OFFICE DESIGN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `IDAQ NETWORKS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `IDEAL MODULES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `IDHL LTD`, 'company_status': `In Administration`},
        {'company_name': `IF TRADE CO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `IFL REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `IGD HOLDINGS REALISATIONS LLP`, 'company_status': `In Administration`},
        {'company_name': `IGD REALISATIONS LLP`, 'company_status': `In Administration`},
        {'company_name': `IGD SERVICES REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `IGENE LONDON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `IGH NATIONWIDE REACTIVE MAINTENANCE (SOUTH) LIMITED`, 'company_status': `VOLUNTARY ARRANGEMENT / ADMINISTRATIVE RECEIVER`},
        {'company_name': `IKAN PAPER CRAFTS LTD`, 'company_status': `In Administration`},
        {'company_name': `IL GUSTO SPIRITS LTD`, 'company_status': `In Administration`},
        {'company_name': `ILA-SPA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ILEC LTD.`, 'company_status': `In Administration`},
        {'company_name': `ILKE HOMES HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ILKE HOMES LAND LIMITED`, 'company_status': `In Administration`},
        {'company_name': `IMPACT MODULAR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `IMPACT TRAINING & EMPLOYMENT LTD`, 'company_status': `In Administration`},
        {'company_name': `IMPERIAL BATHROOMS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `IMPERIUM CONTRACTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `IN RETIREMENT SERVICES (REVERSIONS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `IN-TIME WATCH SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `IN2ITIVE BUSINESS GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INCLARITY COMMUNICATIONS LTD.`, 'company_status': `In Administration`},
        {'company_name': `INDEPENDENT PROPERTY SURVEYS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INDEPENDENT-NETWORKS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INDIGO MICHAEL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INDIVIDUAL RESTAURANT COMPANY LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `INDUSTRIAL COATING SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INFARM - INDOOR URBAN FARMING GMBH`, 'company_status': `In Administration`},
        {'company_name': `INFINITY DEVELOPMENTS LIVERPOOL LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `INFLUENCER MARKETING COMMUNICATIONS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `INFRARED UK LION GP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INFRARED UK LION NOMINEE 1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INFRARED UK LION NOMINEE 2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INLAND (STB) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INLAND FINANCE LTD`, 'company_status': `In Administration`},
        {'company_name': `INLAND HOMES (ESSEX) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INLAND HOMES 2013 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INLAND HOMES DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INLAND HOMES PLC`, 'company_status': `In Administration`},
        {'company_name': `INLAND LIFESTYLE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INLAND LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INLAND PARTNERSHIPS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INLAND PROPERTY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INLINE MOVES LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `INNOSIL (UK) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `INNOVISION EVENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INNU-SCIENCE RH (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INOVIA INTERNATIONAL LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `INSPIRED SUTTON LTD`, 'company_status': `In Administration`},
        {'company_name': `INSTA HIRE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INSTRUCT PAY LTD`, 'company_status': `In Administration`},
        {'company_name': `INTEGRA ENERGY (UK) LTD.`, 'company_status': `In Administration`},
        {'company_name': `INTEGRAL BULK PACKAGING LTD`, 'company_status': `In Administration`},
        {'company_name': `INTEGRATED PROCESS TECHNOLOGY LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `INTERACTIVE OFFICE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INTERFACE CONTRACTS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `INTERGUIDE IOW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INTERIOR CONTRACTS (KITCHENS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INTERMET REFRACTORIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INTERNATIONAL FURNITURE DIRECT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INTERNATIONAL RESORTS MANAGEMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INTERNET 3 COMMUNICATIONS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `INTERNET FUSION GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INTERNET FUSION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INTILERY.COM LTD`, 'company_status': `In Administration`},
        {'company_name': `INTU PROPERTIES PLC`, 'company_status': `In Administration`},
        {'company_name': `INTU RETAIL SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INTU RS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `INTU SHOPPING CENTRES PLC`, 'company_status': `In Administration`},
        {'company_name': `INVESTAR DEVELOPMENTS LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `IOG PLC`, 'company_status': `In Administration`},
        {'company_name': `IOL REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `IOTATECH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `IOW VIEW CARAVAN PARK LTD`, 'company_status': `In Administration`},
        {'company_name': `IP REALISATIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `IRVINE CARE LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ISIS MODEL MANAGEMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ISL LTD`, 'company_status': `In Administration`},
        {'company_name': `ISO SPACES SOUTH WEST LTD`, 'company_status': `In Administration`},
        {'company_name': `ISOCO4 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ISS (HR SERVICES) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ISS GROUP HOLDINGS ONE LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ISUPPLY DRIVERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ISUPPLY INDUSTRIAL LTD`, 'company_status': `In Administration`},
        {'company_name': `IT RESTAURANT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ITEC PACKAGING (CHESTER-LE-STREET) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ITEC PACKAGING (MANSFIELD) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `IVL REALISATIONS 2022 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `IVORY HILL RECRUITMENT LTD`, 'company_status': `In Administration`},
        {'company_name': `IWL REALISATIONS 2023 LTD`, 'company_status': `In Administration`},
        {'company_name': `IXAKA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `J & B DISASTER MANAGEMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `J & G GLOBAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `J & J HARRISON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `J & J STANLEY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `J A FOX LTD`, 'company_status': `In Administration`},
        {'company_name': `J C FUTURE ESTATES LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `J D COOLING SOUTH EAST LIMITED`, 'company_status': `In Administration`},
        {'company_name': `J M SCULLY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `J R RETAIL BAWTRY LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `J ROTHERHAM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `J. TOMLINSON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `J.B. BENNETT (CONTRACTS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `J.C. ROOK & SONS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `J.E. GIBBINGS & SONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `J.ERNEST WATSON LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `J.L. SWEETMAN & SONS LTD`, 'company_status': `In Administration`},
        {'company_name': `J.M.K CIVIL ENGINEERING LTD`, 'company_status': `In Administration`},
        {'company_name': `J.O.G. CONTRACTORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JA HEALTH (CHIROPRACTIC) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JAB BOXING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JABBERWOCKY NURSERY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JABERO CONSULTING LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `JACK BRODIE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JACK MEDIA NATIONAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JACK MOODY RECYCLING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JACKSONS THE BAKERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JAD TRANS SCOTLAND LTD`, 'company_status': `In Administration`},
        {'company_name': `JAEGER RETAIL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JAMES FOWLER PHARMACY LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `JAMES KILLELEA & COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JAMESON PRINT FINISHERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JAR FINANCIAL MANAGEMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JAS TIMBER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JASMINE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JB ELECTRICAL & MECHANICAL SERVICES LTD`, 'company_status': `In Administration`},
        {'company_name': `JB GLOBAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JBE MECHANICAL . ELECTRICAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JCL 1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JCL 2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JCL 3 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JCL 4 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JCL 5 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JD CLASSICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JDH PROPERTY FINANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JDI WINDOWS LTD`, 'company_status': `In Administration`},
        {'company_name': `JEHU GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JEHU PROJECT SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JENKINS GARAGES (WALES) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JESSELLA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JET ROLLER SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JEWEL BUILDING CONTRACTORS LTD`, 'company_status': `In Administration`},
        {'company_name': `JEWELL OF WATFORD (1986) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `JFN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JJMP FINANCIAL SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JLG GROUP PLC`, 'company_status': `In Administration`},
        {'company_name': `JLW ELECTRICAL CONTRACTORS LTD`, 'company_status': `In Administration`},
        {'company_name': `JM ENGINEERING (SCARBOROUGH) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JMG REALISATIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `JNL WASTE SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JOESWOOD ESTATES LTD`, 'company_status': `In Administration`},
        {'company_name': `JOHN ABBOTT (FLOORING CONTRACTORS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JOHN BAXTER & SONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JOHN DENT (CHEMISTS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JOHN ESKENAZI LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JOHN HILL COACH SALES AND SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JOHN MUNROE GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JOHN PARKER & SON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JOHN SAXON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JOHNS CROSS CONVERSIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JOHNSTON'S CLASSIC LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `JOINERY PLUS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `JONATHAN'S TOYS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JOUBERE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JOULES DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JOULES GROUP PLC`, 'company_status': `In Administration`},
        {'company_name': `JOULES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JOYCE CONSTRUCTION & CIVILS LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `JPA TRANSPORT SERVICES LTD`, 'company_status': `In Administration`},
        {'company_name': `JPM2 LTD`, 'company_status': `In Administration`},
        {'company_name': `JR & AL SMITH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JRDM REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JRDP REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JTG REALISATIONS 2022 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JUNO MONETA CAPITAL MANAGEMENT LTD`, 'company_status': `In Administration`},
        {'company_name': `JUNO MONETA GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JUPITER MARKETING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JUST CASH FLOW PLC`, 'company_status': `In Administration`},
        {'company_name': `JUST COSTS BUDGETS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JUST COSTS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `JUST COSTS MANAGEMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JUST HYPE LTD`, 'company_status': `In Administration`},
        {'company_name': `JUST IN TIME (BFD) LTD`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `JUST RECRUIT GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JUSTICE SUPPORT SERVICES (NORTH KENT) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JVIP AP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JVIP DEVELOPMENTS LTD`, 'company_status': `In Administration`},
        {'company_name': `JVIP EA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JVIP GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JVIP LA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JVIP PB LIMITED`, 'company_status': `In Administration`},
        {'company_name': `JW-G CONSTRUCTION LTD`, 'company_status': `In Administration`},
        {'company_name': `JWT COMMERCIAL LTD`, 'company_status': `In Administration`},
        {'company_name': `K REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KAIM TODNER SOLICITORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KALANG LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KANO COMPUTING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KAPEX CONSTRUCTION LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `KARAKURI LTD`, 'company_status': `In Administration`},
        {'company_name': `KAREN MILLEN FASHIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KAREN MILLEN HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KARHOO INC.`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `KASTERLEE UK LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `KAVANAGH & MANNION (MANCHESTER) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KAYS TRAFFIC MANAGEMENT SYSTEMS LTD.`, 'company_status': `In Administration`},
        {'company_name': `KBOX GLOBAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KD GROUP OF COMPANIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KD MAINTENANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KDR TALENT SOLUTIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `KEASH PROPERTIES (PENTONVILLE ROAD) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KEC DEVELOPMENT COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KEIRAN BROWN BUILDING SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KEN READ & SON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KENADS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KENT DRAINAGE LTD`, 'company_status': `In Administration`},
        {'company_name': `KENYON ROAD HAULAGE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KENYON WAREHOUSING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KERRY IRELAND INVESTMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KERSFIELD DEVELOPMENTS (LBW) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `KESBURY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KESSLERS INTERNATIONAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KETTLE AND TALBOT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KETTLE INTERIORS UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KFS REALISATIONS 2018 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KH GLOBAL TRADING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KH RESOURCING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KID UNKNOWN HOLDINGS LTD`, 'company_status': `In Administration`},
        {'company_name': `KIDS FUNTIME BEDS LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `KIDZ ABOUT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KIN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KIN SERIES HOLDING UK LTD`, 'company_status': `In Administration`},
        {'company_name': `KITCHENCRAFT STUDIOS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `KITCHENS PLUS LTD.`, 'company_status': `In Administration`},
        {'company_name': `KITSUNE ASSOCIATES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KNBY LND OP3 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KNIGHTS OF OLD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KNIGHTSBRIDGE REAL ESTATE ASSET MANAGEMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KNIGHTSBRIDGE RETAIL PARTNERS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `KNIGHTSGATE (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KNP LOGISTICS GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KOKOBA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KOMPLI HOLDINGS PLC`, 'company_status': `In Administration`},
        {'company_name': `KOMPLI-GLOBAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KRUSH GLOBAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KUHNEL GRAPHICS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `KURT ORBAN PARTNERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KYLEMORE HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `KYLEMORE SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `L & N D DEVELOPMENT AND DESIGN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `L S ENTERPRISES LTD`, 'company_status': `In Administration`},
        {'company_name': `L. & D. PLUMBING & TILING SERVICES LTD.`, 'company_status': `In Administration`},
        {'company_name': `L.B.J. FABRICATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `L.G. KIMBER (ENGINEERING) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `L.KAHN MANUFACTURING COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LA LAW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LAB (LONDON) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LADBURY ENTERPRISES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `LADYBANK HOMES LTD`, 'company_status': `In Administration`},
        {'company_name': `LAGAN BUILDING CONTRACTORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LAGAN CONSTRUCTION GROUP HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LAGAN CONSTRUCTION GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LAGAN WATER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LAKELAND CARE GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LAKELAND CARE SERVICES  LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LAKER BUILDING AND FENCING SUPPLIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LAN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LANCASHIRE PROPERTY INVESTMENT FIND LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LANCE HOMES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `LAND PROMOTIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `LAND TRANSPORT (WEST) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `LANDLET LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LANE END DEVELOPMENTS CONSTRUCTION LTD`, 'company_status': `In Administration`},
        {'company_name': `LANGAN GROUP LTD`, 'company_status': `In Administration`},
        {'company_name': `LANGFORD SERVICES GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LANTOOM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LASCO EUROPE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LASER ELECTRICAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LATIN GROWERS DIRECT LTD`, 'company_status': `In Administration`},
        {'company_name': `LAUNDRY DIRECT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LAURISTON DEVELOPMENTS LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `LAVA MAYFAIR CLUB LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LAW DIRECT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LAWTON HOTELS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LAYTON PROPERTY DEVELOPMENTS LTD`, 'company_status': `In Administration`},
        {'company_name': `LB HOLDINGS INTERMEDIATE 2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LB UK RE HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LBH RAIL & CIVIL ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LBMS NO.1 LTD`, 'company_status': `In Administration`},
        {'company_name': `LC MANAGEMENT SERVICES (SCOTLAND) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LCM ENVIRONMENTAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LE CHARDON D'OR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LEADSHARP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LEARNING ENTERPRISES ORGANISATION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LEASOTEL LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `LEATHER TO LOVE FOREVER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LEBC GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LECI CONSTRUCTION LTD`, 'company_status': `In Administration`},
        {'company_name': `LECI GROUNDWORKS LTD`, 'company_status': `In Administration`},
        {'company_name': `LEDGEREDGE LTD`, 'company_status': `In Administration`},
        {'company_name': `LEE SYMS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LEEDS CITY APARTMENTS LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `LEEDS WINDOW CENTRE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LEHMAN BROTHERS (PTG) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LEHMAN BROTHERS HOLDINGS PLC`, 'company_status': `In Administration`},
        {'company_name': `LEHMAN BROTHERS INTERNATIONAL(EUROPE)`, 'company_status': `In Administration`},
        {'company_name': `LEHMAN BROTHERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LENDSWIFT BRIDGING SECURITY LTD`, 'company_status': `In Administration`},
        {'company_name': `LENDSWIFT BRIDGING UK LTD`, 'company_status': `In Administration`},
        {'company_name': `LENDY LTD`, 'company_status': `In Administration`},
        {'company_name': `LENLYN U.K. LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LENNOX ESTATES (BRANKSOME) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LESCIP R LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `LESKO PARK AND LEISURE HOMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LETHENDY CHELTENHAM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LEVERGATE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LEWIS'S LIVERPOOL LLP`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `LEYTOP YARNS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `LIBERTY INTERNATIONAL GROUP TREASURY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LIBERTY INTERNATIONAL HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LIBERTY SIPP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LIFE BEGINS FILM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LIFEBOOK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LIFESKILLS SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LIFESTYLE SITES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LIFT TRUCK RENTALS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LIFX UK LTD`, 'company_status': `In Administration`},
        {'company_name': `LIME GROVE TUFFLEY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LIMELIGHT SPORTS LTD`, 'company_status': `In Administration`},
        {'company_name': `LINDHURST ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LION HOUSE NURSING HOMES LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `LITETASK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LITTLE MISTRESS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LITTLE STARS NURSERY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LITTLEBERRIES LLP`, 'company_status': `In Administration`},
        {'company_name': `LIVE TECH GAMES LTD`, 'company_status': `In Administration`},
        {'company_name': `LIVESMART U.K. LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LIVIA'S HEALTH FOODS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LJM COMPLIANCE SOLUTIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `LJS FOOD CHELMSFORD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LJS FOOD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LJS FOOD SWINDON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LJS FOOD WORCESTER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LL REALISATIONS 2017 LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `LLB REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LLOYD FRASER (BULK LIQUIDS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LLOYD FRASER (DISTRIBUTION) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LLOYD FRASER HOLDINGS COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LLOYD FRASER LOGISTICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LLOYD FRASER PRIMARY LOGISTICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LOCK TAVERN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LODENT PRECISION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LOGIE GLAZING AND BUILDING SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LOGLECDISSOL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LOK DEVELOPMENTS 06 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LONDON & SILVER DEVELOPMENTS LLP`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `LONDON CAPITAL & FINANCE PLC`, 'company_status': `In Administration`},
        {'company_name': `LONDON CITY SHOPPING CENTRE LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `LONDON DOCKSIDE LTD`, 'company_status': `In Administration`},
        {'company_name': `LONDON GROUP LLP`, 'company_status': `In Administration`},
        {'company_name': `LONDON INVESTMENTS CONSULTANCY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LONDON IRISH HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LONDON IRISH SCOTTISH RICHMOND LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LONDON KWS CENTRE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LONDON LUTON CHAPEL STREET STUDENT ACCOMMODATION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LONDON LUTON NEW STREET STUDENT ACCOMMODATION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LONDON OIL & GAS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LONDON SOUTH WEST SW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LONDON WIPER COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LONGSTREET COMMUNICATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LONGTON STORAGE & TRANSPORT LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `LONTEX INDUSTRIES LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `LORD NELSON LIVERPOOL LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `LORENZO (NORTH EAST) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LOSCOE CHILLED FOODS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LOU'S TYRES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LOUGH SHORE DEVELOPMENT LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `LOVATT TRANSPORT & WAREHOUSING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LOVE HEMP LTD`, 'company_status': `In Administration`},
        {'company_name': `LOVE LANE BREWERY LTD`, 'company_status': `In Administration`},
        {'company_name': `LOVELL SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LOWATER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LOWLEY ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LOYOLA HALL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LP REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LP19 LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `LPM PLANT HIRE & SALES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `LPM PLANT SALES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `LPS NORWICH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LRA REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LS SOMERSET LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LSDL REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LSHD REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LTS POWERED ACCESS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LTV UK HOLDINGS LTD`, 'company_status': `In Administration`},
        {'company_name': `LUGANO DISSINGTON ESTATE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LUMLEY CASTLE HOTEL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LUNA HOTELS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LUTTERWORTH SQUASH CLUB LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `LUXTRIPPER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LUXURY FOR LESS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LUXURYSHAKES LTD`, 'company_status': `In Administration`},
        {'company_name': `LVR CAPITAL LTD`, 'company_status': `In Administration`},
        {'company_name': `LVS SMALL PLASTIC PARTS LTD`, 'company_status': `In Administration`},
        {'company_name': `LW&P (MONKS ELEIGH) LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `LX REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LYSANDER LAW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `LYTE INDUSTRIES (WALES) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `M & A PHARMACHEM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `M & D (LEISURE) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `M & S CARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `M IGOE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `M ROGERSON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `M&C RENDERSYSTEMS LTD`, 'company_status': `In Administration`},
        {'company_name': `M&CO TRADING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `M&J PARTNERSHIP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `M&S PLASTICS (EUROPE) LTD`, 'company_status': `In Administration`},
        {'company_name': `M.H. ELCOCK AND SON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `M.PRICE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAATS TECH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MABLE COMMERCIAL FUNDING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MACASPIN DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MACDONALD & TAYLOR HEALTHCARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MACK CIVILS AND CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MACLEAN AND SPEIRS GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MACLOCH CONTRACTS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `MADDISON BUSINESS SYSTEMS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MADE.COM DESIGN LTD`, 'company_status': `In Administration`},
        {'company_name': `MADESTEIN (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MADOX HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAGNUS GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAGUIRE CONCRETE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAINLINE SAFETY LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `MAINS HOUSE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAINTAINING ENVIRONMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAJESTIC BINGO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAKE LEMONADE AGENCY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAKEHAPPEN GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MALIN INDUSTRIAL CONCRETE FLOORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MALTINGS DEVELOPMENTS BURTON ON TRENT LTD`, 'company_status': `In Administration`},
        {'company_name': `MAMMA DOUGH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAN'S CATERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MANAGEBEST LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `MANAGED SERVICED OFFICES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `MANCHESTER GIANTS LTD`, 'company_status': `In Administration`},
        {'company_name': `MANDARIN CONSULTING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MANTISSON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MANXMAN LEISURE LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `MAP MARKETING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAP TAVERNS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAPIL MIDCO 1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAPLE PROPERTIES (HEADINGLEY) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAPLE SECURITIES (UK) LTD`, 'company_status': `In Administration`},
        {'company_name': `MAPLIN ELECTRONICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAR ESTATES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MARA SEAWEED LTD`, 'company_status': `In Administration`},
        {'company_name': `MARK A. BALL FENCING AND LANDSCAPING LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `MARK HARRIS FURNITURE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MARK STEWART LTD`, 'company_status': `In Administration`},
        {'company_name': `MARK WEBSTER DESIGNS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MARK WEBSTER HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MARKLINE CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MARSON FABRICS (LONDON) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MARTIN COWMAN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MARTIN MCCOLL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MARTIN RETAIL GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MASS COMPUTER SYSTEMS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `MASSEY'S FOLLY DEVELOPMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MATERIALS I.P. LTD`, 'company_status': `In Administration`},
        {'company_name': `MATRIX DEPUTIES LTD`, 'company_status': `In Administration`},
        {'company_name': `MATTHEW BODY PLANT HIRE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MATTHEW CORNISH TRANSPORT LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `MATTHEW SNOWDEN ELECTRICAL CONTRACTORS LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `MATZ FOODSS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MATZ MEDICAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAXIM LIFTING SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAXWELL AYLWIN & CO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAYFAIR OUTLET LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAYFAIR TELECOM PLC`, 'company_status': `In Administration`},
        {'company_name': `MAYFLOWER ALLIANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MAYNE COACHES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MB LEGAL SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MB MASONRY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MCCARTHY CONTRACTORS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `MCCLOSKEY AND O'KANE BUILDING COMPANY LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `MCCLURE NAISMITH LLP`, 'company_status': `In Administration`},
        {'company_name': `MCCOLL'S RETAIL GROUP PLC`, 'company_status': `In Administration`},
        {'company_name': `MCCORMACK DEMOLITION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MCCORMACK SITE SERVICES (IRELAND) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MCE INSURANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MCGILL & CO LTD`, 'company_status': `In Administration`},
        {'company_name': `MCGILL FACILITIES MANAGEMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MCIOD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MDCT REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MDL ROOFING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ME CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ME CRONIN LTD`, 'company_status': `In Administration`},
        {'company_name': `MEARS HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MECHANICAL & ELECTRICAL SOLUTIONS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `MECHANICAL FACILITIES SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MECTEC ENGINEERING LTD`, 'company_status': `In Administration`},
        {'company_name': `MED INNOVATIONS AND CONSULTING LTD`, 'company_status': `In Administration`},
        {'company_name': `MEDERCO (HUDDERSFIELD) LTD`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `MEDERCO BLOCK A LTD`, 'company_status': `In Administration`},
        {'company_name': `MEDIAPORT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MEDIPATROL LTD`, 'company_status': `In Administration`},
        {'company_name': `MEDITERRANEAN FOODS (LONDON) LTD`, 'company_status': `In Administration`},
        {'company_name': `MEDWAY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MEGABOOTH LTD`, 'company_status': `In Administration`},
        {'company_name': `MELLISS AVE DEVCO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MELTON CONSTABLE INVESTMENTS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `MERCH CMT LTD`, 'company_status': `In Administration`},
        {'company_name': `MEREWAY KITCHENS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MERIDIAN REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MERLIN SUPPLY CHAIN SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MERRIOTT CHARD LTD`, 'company_status': `In Administration`},
        {'company_name': `MERSTEN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MERTHYR TYDFIL FOOTBALL CLUB LIMITED`, 'company_status': `In Administration`},
        {'company_name': `METAL FABRICATION (BUCKS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `METALAST LIMITED`, 'company_status': `In Administration`},
        {'company_name': `METNOR CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `METNOR GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `METRIC TECHNIQUE LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `METTRR TECHNOLOGIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MF GLOBAL UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MF GLOBAL UK SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MG METAL DESIGN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MGL REALISATIONS (2022) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MGR REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MHA AUCHLOCHAN`, 'company_status': `In Administration`},
        {'company_name': `MI-SPACE (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MIB FACADES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MICHAEL CULSHAW & CO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MICHAEL J. LONSDALE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MICHAEL PAUL HOLIDAYS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MICHAEL ROSE & BAYLIS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MICHAEL'S PITTA BREAD BAKERY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MID CONTRACTING AND CONSULTING LTD`, 'company_status': `In Administration`},
        {'company_name': `MID HOLDING CO UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MID KENT ROOFING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MIDAS CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MIDAS GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MIDDLETON MOBILITY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MIDLAND PROPERTY INVESTMENT FUND LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MIG ASSET MANAGEMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MIGHTPLAN LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `MIKE THOMPSON (STAFFORD) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MILFORD ON SEA PARK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MILL GREEN FACILITIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MILLER CONSTRUCTION LTD`, 'company_status': `In Administration`},
        {'company_name': `MILLER GARDNER LTD`, 'company_status': `In Administration`},
        {'company_name': `MILTON PORTFOLIO OP CO 3 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MIMRAM ENGINEERING SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MINERVA PEOPLE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MINTO PROPERTIES 1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MINTO PROPERTIES 2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MIRACLES BY DESIGN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MISCELLANEOUS STUDIOS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MISHPOCHA TOURING LLP`, 'company_status': `In Administration`},
        {'company_name': `MITCHELL ENGINEERING GROUP LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `MITTON AFTERCARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MITTON ELECTRICAL SERVICES LTD`, 'company_status': `In Administration`},
        {'company_name': `MITTON MECHANICAL SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MIZEN DESIGN/BUILD LTD.`, 'company_status': `In Administration`},
        {'company_name': `MJ REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MJL MIDLANDS LTD`, 'company_status': `In Administration`},
        {'company_name': `MJW LAW LTD`, 'company_status': `In Administration`},
        {'company_name': `MK BRANDS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MKA REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MLGO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MLM TUBES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MMM RETAIL PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MOBILESERV LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MOBLOX LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MOCOCO UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MODA FURNISHINGS LTD`, 'company_status': `In Administration`},
        {'company_name': `MODAL ELECTRONICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MODULE-AR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MOLANA VIEW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MOLD SYSTEMS (EUROPE) LTD`, 'company_status': `In Administration`},
        {'company_name': `MONARCH AIRCRAFT ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MONARCH INDUSTRIES PLC`, 'company_status': `Administration Order`},
        {'company_name': `MONEYTHING (SECURITY TRUSTEE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MONEYTHING CAPITAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MONK FRYSTON HALL HOTEL LTD`, 'company_status': `In Administration`},
        {'company_name': `MONKS & CRANE INDUSTRIAL GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MONNEO LTD`, 'company_status': `In Administration`},
        {'company_name': `MONNOW VALLEY CHP PLC`, 'company_status': `In Administration`},
        {'company_name': `MONO CONSULTANTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MONSOON VENUES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MONTAGE FILMS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `MONTEZUMA'S CHOCOLATES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MONTEZUMA'S DIRECT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MONTEZUMA'S LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MONTREAUX REDHILL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MOORE LARGE & CO. LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MORELANE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MORGAN FOOD LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `MORGARD COURT LTD`, 'company_status': `In Administration`},
        {'company_name': `MORRIS MCLELLAN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MORSES CLUB LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MOSSVALE MAINTENANCE AND SEALING SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MOTORCARE (ALNWICK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MOTTIK INTERNATIONAL LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `MOUNT GROUP STUDENT NATEX LTD`, 'company_status': `In Administration`},
        {'company_name': `MOUNTAIN ASH LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `MOUNTVIEW HOTELS LTD`, 'company_status': `In Administration`},
        {'company_name': `MOVINGFEAST LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MOYALLEN PROPERTIES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `MOYALLEN WOKING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MPT GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MPWR SPORTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MRB CLEANING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MSC CONSULTING AND SALES SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MSP GATHER LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `MST LEGAL LTD`, 'company_status': `In Administration`},
        {'company_name': `MTA PERSONAL INJURY SOLICITORS LLP`, 'company_status': `In Administration`},
        {'company_name': `MTO LOGISTICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MULBURY HOMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MULLER EV LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `MULTI METALS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MUST HAVE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MVISION PRIVATE EQUITY ADVISERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MWR PROPERTY (CHARLES HILL) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MWR PROPERTY (HIGHGATE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MY PLANET ONLINE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MY REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MYFRESH PREPARED PRODUCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `MYLIFE DIGITAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `N REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `N.B. PUBLISHING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `N054 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `N059 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NALU HOMES SPV 101 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NANT Y GAER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NARYA HART LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `NATIONWIDE FRAME SERVICES (NORTH WEST) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NAYLORS FINANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NCRQ LTD`, 'company_status': `In Administration`},
        {'company_name': `NCS SUPPORT SOLUTIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `NEAL INVESTMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NEC GRAPH - FIX LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NEEDLESMART LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `NELSON DISTRIBUTION LTD`, 'company_status': `In Administration`},
        {'company_name': `NEMESIS BIOSCIENCE LTD`, 'company_status': `In Administration`},
        {'company_name': `NEON CLUB PRODUCTIONS, LTD`, 'company_status': `In Administration`},
        {'company_name': `NEOS AUTOMATION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NEOS AUTOMOTIVE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NEOS COMPOSITE SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NEOS COMPOSITES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NEOS CONSULTANCY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NEOS ENGINEERING LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `NEOS INTERNATIONAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NEOS NUCLEAR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NEOS SUPERFORM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NEOS TECHNOLOGIES LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `NEUTRON EQUIPMENT LTD`, 'company_status': `In Administration`},
        {'company_name': `NEW GENERATION COURIER LOGISTICS LTD`, 'company_status': `In Administration`},
        {'company_name': `NEW HEIGHTS CONSTRUCTION LTD`, 'company_status': `In Administration`},
        {'company_name': `NEW HOMES FLOORING LTD`, 'company_status': `In Administration`},
        {'company_name': `NEW OFJ NORTH EAST LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NEWCASTLE CHIROPRACTIC LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `NEWCASTLE GLASSWORKS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NEWCASTLE GLASSWORKS MANAGEMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NEWCO1964 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NEWCOURT RESIDENTIAL (SPV 9) LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `NEWCOURT RESIDENTIAL (WADHURST) LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `NEWCOURT RESIDENTIAL LTD`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `NEWFOX LTD`, 'company_status': `In Administration`},
        {'company_name': `NEWPORT BUILDING SERVICES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `NEWTYLE TRADING COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NEXEN LIFT TRUCKS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NEXT GEN SOLICITORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NEXUS INDEPENDENT FINANCIAL ADVISERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NEXUS INVESTMENT MANAGERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NFR TW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NGL REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NI RETAILERS LTD`, 'company_status': `In Administration`},
        {'company_name': `NIBNIBS LTD`, 'company_status': `In Administration`},
        {'company_name': `NICHOLSON & BASS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `NIGEL FREDERICKS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NIXARTE (NO 20) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NLH LOGISTICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NMC HEALTH PLC`, 'company_status': `In Administration`},
        {'company_name': `NMCN PLC`, 'company_status': `In Administration`},
        {'company_name': `NMCN SUSTAINABLE SOLUTIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `NO DIG DRAINAGE SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NO. TEN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NOAHS BOX LTD`, 'company_status': `In Administration`},
        {'company_name': `NOEL REGAN & SONS BUILDING & CIVIL ENGINEERING CONTRACTORS UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NORDIC STYLE SWEDEN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NORMAN & UNDERWOOD (GLAZING SYSTEMS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NORMAN & UNDERWOOD CONSERVATION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NORMAN & UNDERWOOD GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NORMAN AND UNDERWOOD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NORSTEAD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NORTEL NETWORKS UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NORTH BOARHUNT 1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NORTH NORTH WEST LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NORTH NOTTS LOGISTICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NORTH SHROPSHIRE VOLUNTARY ACTION`, 'company_status': `In Administration`},
        {'company_name': `NORTH WEST BIOMASS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NORTHCARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NORTHCORE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NORTHCOTE OPERATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NORTHEY TECHNOLOGIES LTD.`, 'company_status': `In Administration`},
        {'company_name': `NOURA BRASSERIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NOVA GROUP PRODUCTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NOVALOANS LTD`, 'company_status': `In Administration`},
        {'company_name': `NPR MANAGEMENT SERVICES LTD`, 'company_status': `In Administration`},
        {'company_name': `NR CAPRICORNUS LLP`, 'company_status': `In Administration`},
        {'company_name': `NR CRUX LTD`, 'company_status': `In Administration`},
        {'company_name': `NR LYRA LTD`, 'company_status': `In Administration`},
        {'company_name': `NRI CIVILS LTD`, 'company_status': `In Administration`},
        {'company_name': `NSK FOODS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NU SPACE HOMES (ECKINGTON) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NU-TRACK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NUC REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NUMBERS (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NURSERY CONNECTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NURSING 2000 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NUTRAFORMIS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `NUTRICULTURE UK LTD`, 'company_status': `In Administration`},
        {'company_name': `NWE. (MARLOW) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `O&C LTD`, 'company_status': `In Administration`},
        {'company_name': `O'KEEFE CONSTRUCTION (GREENWICH) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `O'REILLY CONTRACT SCAFFOLDING LTD`, 'company_status': `In Administration`},
        {'company_name': `O2R LTD`, 'company_status': `In Administration`},
        {'company_name': `OAIRO ALLIANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OAK ARTISANS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OAK FLOORING SUPPLIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OAKAM LTD`, 'company_status': `In Administration`},
        {'company_name': `OAKHAM GRANGE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OAKTARGET GARMENTS LTD`, 'company_status': `In Administration`},
        {'company_name': `OASIS AND WAREHOUSE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OASIS FASHIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OASIS FASHIONS RETAIL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OBSGYNCARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OCEA GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OCEAN FLEET SEAFOOD LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `OCEAN FOUR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OCTEVO HOUSING SOLUTIONS LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `OCULIS CONSTRUCTION CONSULTANTS LTD`, 'company_status': `In Administration`},
        {'company_name': `ODX INNOVATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ODYSCAP EDINBURGH 1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OILCHART UK LTD`, 'company_status': `In Administration`},
        {'company_name': `OKEFENOKEE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OLD PARK LANE CAPITAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OLD1 LTD`, 'company_status': `In Administration`},
        {'company_name': `OLD2 LTD`, 'company_status': `In Administration`},
        {'company_name': `OLD3 LTD`, 'company_status': `In Administration`},
        {'company_name': `OLDCO1339885127092023 LTD`, 'company_status': `In Administration`},
        {'company_name': `OLDCOOR LTD`, 'company_status': `In Administration`},
        {'company_name': `OLDDUCK5 LTD`, 'company_status': `In Administration`},
        {'company_name': `OLYMPIUS DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OM & AH LONDON LTD`, 'company_status': `In Administration`},
        {'company_name': `OMADA INVESTMENT MANAGEMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OMNIA FLATBREAD BAKERY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OMNIDEX LASER LTD`, 'company_status': `In Administration`},
        {'company_name': `ON BRAND INTERNATIONAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ONE BLACKFRIARS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ONE ISLINGTON PLAZA LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ONE LEEDS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ONE LEGAL SERVICES (TRADING AS ONE LEGAL) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ONE LIFE FUNERAL PLANNING LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ONE PLUS COMPUTER SYSTEMS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `ONE-O-ONE GARAGE LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `ONEFARM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ONLY EGGLESS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ONTO HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ONTO TECH 1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ONTO TECH 2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ONTO TECH 3 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ONTO TECH 4 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OPAL COMPUTERS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `OPTIMA CARS LTD`, 'company_status': `In Administration`},
        {'company_name': `OPTIMA ENERGY MANAGEMENT HOLDINGS LTD`, 'company_status': `In Administration`},
        {'company_name': `OPTIMUM-LOGISTICS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `OPTIMUS SERVICES LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `OPTIPLAN KITCHENS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OR MULTIMEDIA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OR PRODUCTIVITY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ORBIT ENERGY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ORBIT FLOAT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ORCHARD HOUSE FOODS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ORE SIZER (UK) LTD.`, 'company_status': `In Administration`},
        {'company_name': `OREX  CONSULTANCY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ORIGINAL LEISURE LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `ORION WINDOWS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ORRDONE FARMS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ORTHIOS (ANGLESEY) TECHNOLOGIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ORTHIOS FEEDSTOCK (ANGLESEY) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ORTHIOS OTECH5 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OSL RAIL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OSO FIBER UK LTD`, 'company_status': `In Administration`},
        {'company_name': `OSV LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OTG PRODUCTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OTRO CLUB LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OTRO GLOBAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OUR POWER ENERGY SUPPLY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OUT OF TOWN GROUP LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `OUTCO SURFACING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OUTREACH CREATIVE LTD`, 'company_status': `In Administration`},
        {'company_name': `OVAL VENUES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OVERSON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OXFORD CONTENT AND COMMUNICATIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `OXTO LTD`, 'company_status': `In Administration`},
        {'company_name': `OXYGEN BIDCO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OXYGEN FREEJUMPING ACTON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `OXYGEN FREEJUMPING DERBY LTD`, 'company_status': `In Administration`},
        {'company_name': `OXYGEN FREEJUMPING SOUTH LTD`, 'company_status': `In Administration`},
        {'company_name': `P & M FABRICATIONS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `P J FENCING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `P J S MOULDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `P N T CONTRACTORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `P T C (SOUTH EAST) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `P&C ENGLAND LIMITED`, 'company_status': `In Administration`},
        {'company_name': `P. & D. ENGINEERING SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `P. V. FRANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `P.C.G.I. PRO-PLUMB LIMITED`, 'company_status': `In Administration`},
        {'company_name': `P.D.R. CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `P.T.J. PROPERTIES LTD`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `P.W. HOMES LTD`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `P3ECO (BICESTER) HIMLEY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PACALIS (SDV) LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `PACKET BOAT LANE LTD.`, 'company_status': `In Administration`},
        {'company_name': `PACKITSAFE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PADDLES GLOBAL LTD`, 'company_status': `In Administration`},
        {'company_name': `PADDOCKS HOUSE LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `PADSTOW HEIGHTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PAGAZZI LIGHTING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PAGE PIPELINE SUPPLIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PAINTBOX BANBURY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PAINTBOX GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PAINTINGS FRAMES ETC LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PALL MALL OFFICES LTD`, 'company_status': `In Administration`},
        {'company_name': `PALM COURT (TORQUAY) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PALMER & HARVEY (HOLDINGS) PLC`, 'company_status': `In Administration`},
        {'company_name': `PALMER & HARVEY MCLANE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PANACEA FIRE & SECURITY LTD`, 'company_status': `In Administration`},
        {'company_name': `PANACHE 1 LONDON ROAD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PANACHE LEASING LTD`, 'company_status': `In Administration`},
        {'company_name': `PANEL PLAN (REALISATIONS) LTD`, 'company_status': `In Administration`},
        {'company_name': `PANTHER PARTNERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PARADIGM WELLINGTON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PARAGON PRECISION ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PARANOID EXPEDITION ENGINEERING LTD`, 'company_status': `In Administration`},
        {'company_name': `PARC MONACO HOLDINGS PLC`, 'company_status': `Administration Order`},
        {'company_name': `PARCELS TO FREIGHT LTD`, 'company_status': `In Administration`},
        {'company_name': `PARK MEDICAL LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `PARK PLACE PLAZA LTD`, 'company_status': `In Administration`},
        {'company_name': `PARK REGIS BIRMINGHAM LLP`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `PARKERS BAKERY LTD`, 'company_status': `In Administration`},
        {'company_name': `PARKES UTILITIES SURFACING LTD`, 'company_status': `In Administration`},
        {'company_name': `PARQUET HOUSE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PARRS FOODS LTD`, 'company_status': `In Administration`},
        {'company_name': `PARRY AND WELCH SOLICITORS LLP`, 'company_status': `In Administration`},
        {'company_name': `PARSONS PEEBLES GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PARSONS PEEBLES SERVICE (HOLDING) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PARSONS PEEBLES SERVICE (READING) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PARSONS PEEBLES SERVICE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PARTY PIECES HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PASSENGER LIFT SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PASSMONDS HOUSE LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `PAULIAN SERVICES LTD`, 'company_status': `In Administration`},
        {'company_name': `PAWLEY & MALYON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PAYEME LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PCI PROPERTY HOLDINGS LTD`, 'company_status': `In Administration`},
        {'company_name': `PDA PLASTICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PEACOCKS CENTRE`, 'company_status': `In Administration`},
        {'company_name': `PEAR TREE DRIVE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PELUCID LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PEMBAR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PENDLE ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PENINSULA PRINT & DESIGN LTD`, 'company_status': `In Administration`},
        {'company_name': `PENNINE (LONDON) LTD.`, 'company_status': `In Administration`},
        {'company_name': `PENNINE MANOR HOTEL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PENSION ADVICE SPECIALISTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PEOPLE'S ENERGY (SUPPLY) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PERCIVALE LTD`, 'company_status': `In Administration`},
        {'company_name': `PERFORMANCE SPRINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PERMITTED DEVELOPMENTS INVESTMENTS NO 6 LTD`, 'company_status': `In Administration`},
        {'company_name': `PETER BADCOCK (MOTOR SERVICING) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `PETER LAWLESS ROAD PLANING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PETROLEUM PIPE COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PETROPAVLOVSK PLC`, 'company_status': `In Administration`},
        {'company_name': `PFW REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PH CRAWLEY LTD`, 'company_status': `In Administration`},
        {'company_name': `PH LEICESTER 1 LTD`, 'company_status': `In Administration`},
        {'company_name': `PH LEICESTER LTD`, 'company_status': `In Administration`},
        {'company_name': `PH REALISATIONS 2020 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PHARMASERVE (NORTH WEST) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PHARMATUBE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PHHL REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PHILIPS TRUST CORPORATION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PHILLIPS CHEMISTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PHILLIPS HOMES (RIPON) LTD`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `PHILLIPS-UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PHOENIX 4 FABRICATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PHOENIX INSURANCE MANAGEMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PHOENIX WORLDWIDE LOGISTICS LTD`, 'company_status': `In Administration`},
        {'company_name': `PHONEME LTD`, 'company_status': `Administration Order`},
        {'company_name': `PHONES 4 U GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PHONES 4U LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PHONES4U FINANCE PLC`, 'company_status': `In Administration`},
        {'company_name': `PHONETAP LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `PHOSPHORUS ACQUISITION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PHOSPHORUS HOLDCO PLC`, 'company_status': `In Administration`},
        {'company_name': `PHYSIOMEDICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PHYSIOROOM LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `PICCADILLY INTERNATIONAL LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `PIER CONSULTING LTD`, 'company_status': `In Administration`},
        {'company_name': `PILKINGTONS 2021 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PIMPERNELS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PINDAR SCARBOROUGH LTD`, 'company_status': `In Administration`},
        {'company_name': `PINDORIA SOLICITORS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `PINE DEVELOPMENTS (CAMBRIDGE) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `PINE DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PING PONG LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PINSON WHOLESALE LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `PIPE & PILING SUPPLIES LTD.`, 'company_status': `In Administration`},
        {'company_name': `PITTARDS PLC.`, 'company_status': `In Administration`},
        {'company_name': `PIXEL GC LTD`, 'company_status': `In Administration`},
        {'company_name': `PJSD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PKA LEGAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PL PARKVIEW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PLANET X LTD`, 'company_status': `In Administration`},
        {'company_name': `PLANET-U ENERGY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PLANT AND BEAN LTD`, 'company_status': `In Administration`},
        {'company_name': `PLAS FARM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PLATINUM COMMERCIAL MANAGEMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PLATINUM PROPERTY PARTNERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PLAY CENTRES LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `PLEXUS LAW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PLEXUS LEGAL LLP`, 'company_status': `In Administration`},
        {'company_name': `PLEXUS NORTH LLP`, 'company_status': `In Administration`},
        {'company_name': `PLL 1 REALISATIONS 2021 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PLUM TREE COUNTRY PARK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PLUMBPROUD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PLUSCRATES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PMH REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PO REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `POCKET RENTING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `POD REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `POETS HOUSE LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `POLARIS MEDICAL SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `POLARIS WASTE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `POLECLEAR LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `POLGANNEL SOLUTIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `POLICY ADMINISTRATION SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `POOLE INVESTMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `POPLAR FARM RETREAT LTD`, 'company_status': `In Administration`},
        {'company_name': `POPPIES (SOHO) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PORT DINORWIC MARINA LTD`, 'company_status': `In Administration`},
        {'company_name': `PORT LOOP (SUBCO 1) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PORT LOOP HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PORT LOOP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PORTABLE CENTRES LTD`, 'company_status': `In Administration`},
        {'company_name': `PORTBOND LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PORTFOLIO PROPERTY PARTNERS LTD`, 'company_status': `In Administration`},
        {'company_name': `PORTMAN LOGISTICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `POURCARESSE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `POWER 1 ELECTRICAL CONTRACTORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `POWER BY BRITISHVOLT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `POWER EPOS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `PPG REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PPL REALISATIONS (LEEDS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PREACT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PREFECT EQUIPMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PREMIER (C&D) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PREMIER SOLICITORS LLP`, 'company_status': `In Administration`},
        {'company_name': `PREMIER STATIONERY PRODUCTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PREMIER TRAMPOLINE YORK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PRESS GAZETTE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PRESS TO PRINT LTD`, 'company_status': `In Administration`},
        {'company_name': `PRESTBURY PROPERTY DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PRESTIGE INVESTMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PRESTIGE PERSONAL CARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PRESTIGE PROPERTY IMPROVEMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PRESTON CONSTRUCTION (UK) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `PRESTON FARM MANAGEMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PRESTON GUILD HALL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PRESTON PAVING (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PRETZELS UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PRG RECRUITMENT LLP`, 'company_status': `In Administration`},
        {'company_name': `PRICE TECHNICAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PRIDE PLANNING LTD`, 'company_status': `In Administration`},
        {'company_name': `PRIMADOLLAR OPERATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PRIME NOBLE PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PRIME RESORT DEVELOPMENT LTD`, 'company_status': `In Administration`},
        {'company_name': `PRIME THERMAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PRIMROSE HOUSE LTD`, 'company_status': `In Administration`},
        {'company_name': `PRINSEGATE DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PRO-BOLT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PRO-DRIVER LOGISTICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PRO-FIT WINDOWS & DOORS (YEOVIL) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PROCAM TAKE 2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PROCTER BROS. LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `PRODRILL ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PROFICIENT CLEANING LTD`, 'company_status': `In Administration`},
        {'company_name': `PROGENY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PROJECT ANGEL BIDCO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PROJECT7 CONSTRUCTION LTD`, 'company_status': `In Administration`},
        {'company_name': `PROMECH TECHNOLOGIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PROPERTY ALTERNATIVE HOLDINGS LTD`, 'company_status': `In Administration`},
        {'company_name': `PROPERTYBOURSE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PROQUO AI LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PROSPEROUS LIFE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PROTECTIVE ENVIRONMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PROTECTOR NUMBER 1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PROVINCIAL HOTELS & INNS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PROVOST MANAGEMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PS REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PSL HOUSING LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `PSL2021 REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PSM LONDON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PSR CONTRACT SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PSYMPLICITY LTD`, 'company_status': `In Administration`},
        {'company_name': `PT PLASTICS LTD.`, 'company_status': `In Administration`},
        {'company_name': `PT REALISATIONS 2020 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PT2 REALISATIONS 2020 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PULSE FLEXIBLE PACKAGING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PUMPFIELDS REGENERATION COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PURE HEAVENLY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PURE LEGAL COSTS CONSULTANTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PYCOM LTD`, 'company_status': `In Administration`},
        {'company_name': `PYRAMID CONSTRUCTION (U.K.) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `PYRAMID SELF STORAGE LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `Q.A. (WELD TECH) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `Q.C. HOMES KIRKINTILLOCH LLP`, 'company_status': `In Administration`},
        {'company_name': `Q.E.D. DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `QA D NETWORKS LTD`, 'company_status': `In Administration`},
        {'company_name': `QH (UK) REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `QOVARIAN HOLDINGS LTD`, 'company_status': `In Administration`},
        {'company_name': `QSP RESIDUAL RECOVERIES SERVICES COMPANY`, 'company_status': `In Administration`},
        {'company_name': `QUALIA CARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `QUANTA LAW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `QUANTUM 4 INTERNATIONAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `QUANTUM 4 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `QUANTUM GEOTECHNICAL LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `QUANTUM STRATEGIC SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `QUAY BRAKE TESTING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `QUAYSIDE WEST LIMITED`, 'company_status': `In Administration`},
        {'company_name': `QUDENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `QUE STEEL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `QUELEX LIMITED`, 'company_status': `In Administration`},
        {'company_name': `QUENCH (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `QUEST DIGITAL LTD`, 'company_status': `In Administration`},
        {'company_name': `QUICK MOVE LTD`, 'company_status': `In Administration`},
        {'company_name': `QUINN INFRASTRUCTURE SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `QUINN RADIATORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `QUINTO CRANE & PLANT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `R & W LIMITED`, 'company_status': `In Administration`},
        {'company_name': `R I DESIGN & BUILD LTD`, 'company_status': `In Administration`},
        {'company_name': `R O'DONNELL PLANT & CIVIL LTD`, 'company_status': `In Administration`},
        {'company_name': `R WARRINGTON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `R&W CIVIL ENGINEERING LTD`, 'company_status': `In Administration`},
        {'company_name': `R. & A. DEVELOPMENTS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `R. & S. TONKS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `R. L. DAVIES & SON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `R.D. FRANKS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `R10 CONSULTING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RADIUS2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RADSHAPE SHEET METAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RAEDEX CONSORTIUM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RAILSBANK TECHNOLOGY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RALPH & RUSSO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RAM PRINT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RAM SAFETY SOLUTIONS UK LTD`, 'company_status': `In Administration`},
        {'company_name': `RAMCO ACQUISITION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RAMCO TUBULAR SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RANACREST LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `RAP SPIDERWEB LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RAPID PRODUCT DEVELOPMENT LTD`, 'company_status': `In Administration`},
        {'company_name': `RAPID RESPONSE MEDICAL SERVICES LTD`, 'company_status': `In Administration`},
        {'company_name': `RAVA PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RAVENSWORTH PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RAWDON ASSET FINANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RAWSON BROOK GROUP HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RAYMOND BUSHELL (LETTINGS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RAYMOND BUSHELL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RBG REALISATIONS 2020 LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `RCA HOTELS LTD`, 'company_status': `In Administration`},
        {'company_name': `RCD ELECTRICAL (YORKSHIRE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RDF ENERGY NO.1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RE INTERNATIONAL IMPORT-EXPORT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `READTEX SERVICES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `REAL (HIGH LANE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REAL BREWING PUB COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REAL EATING COMPANY CAFES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REAL LSE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REALISATIONS (CM) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RECONI LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RECYCLING TECHNOLOGIES GROUP PLC`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `RECYCLING TECHNOLOGIES LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `RED FOX COMMERCIAL HOLDINGS LTD`, 'company_status': `In Administration`},
        {'company_name': `RED KEY CONCEPTS DESIGN & BUILD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RED SHED 433 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REDFERN STEVENS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REDHALL GROUP PLC`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `REDHILL RESIDENTIAL PARK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REDLANDS DISTRIBUTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REDLEAF VI (ASHTON) GP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REDLEAF VI (ASHTON) NOMINEE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REDSTONE ASSOCIATES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REGAL HOLIDAY HOMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REGENCY SPINNING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REGENERATION (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REGENT ENVELOPES (SALES) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REGENT ENVELOPES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REGIS MUTUAL MANAGEMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REHEAT INTERNATIONAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RELENTLESS NUMBER 4 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REMY AUTOMOTIVE UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RENEWABLE ENERGY WASTE SOLUTIONS UK PLC`, 'company_status': `In Administration`},
        {'company_name': `RENEWABLE SOLUTIONS TEAM LTD`, 'company_status': `In Administration`},
        {'company_name': `RENOUF RESTAURANTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RENT 2 OWN CARS LTD`, 'company_status': `In Administration`},
        {'company_name': `REPAIR & CAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REPAIR MAINTENANCE & OVERHAUL LTD`, 'company_status': `In Administration`},
        {'company_name': `REPUBLIC COSMETICS UK LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `RES HOMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RESICARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RESOLVING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RESOLVING UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RESOURCE RECOVERY SOLUTIONS (DERBYSHIRE) HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RESOURCE RECOVERY SOLUTIONS (DERBYSHIRE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RESOURCEFUL EARTH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RESPITE ROOMS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RESTORATION HUNTS WHARF LTD`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `RETENTION SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REVELES ENERGY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REVO LEISURE SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `REYKER SECURITIES PLC`, 'company_status': `In Administration`},
        {'company_name': `REZATEC LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RFBML REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RHINO WASTE SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RHINO'S PLANT HIRE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RHYS DAVIES & SONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RICE BRANDS LTD`, 'company_status': `In Administration`},
        {'company_name': `RICHARDSON ROOFING (INDUSTRIAL) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RICHMANN WALSH ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RICHMOND TRANSPORT LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `RIDDLESWORTH HALL PREPARATORY SCHOOL LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `RIDGEWOOD CONSTRUCTION LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `RIMSTOCK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RIPMAX LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RISE MANAGEMENT CONSULTING LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `RIVER BAR (DERBY) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `RIVERSIDE TRUST`, 'company_status': `In Administration`},
        {'company_name': `RIZEN HOMES LTD.`, 'company_status': `In Administration`},
        {'company_name': `ROADBRIDGE UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROBERT JAMES INTERIORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROBINSON MANUFACTURING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROBINSON STRUCTURES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROCHE DESIGNS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROCKCASTLE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROCOCO CHOCOLATES LONDON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RODUS DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROGENE TRAILER PARTS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `ROHAN UK SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROLI LTD.`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ROMA LEATHER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROMANSLEIGH DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROO ENGINEERING LTD`, 'company_status': `In Administration`},
        {'company_name': `ROOFMAC (PROPERTY) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROOFMAC LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROOM ONE CONSTRUCTION LTD`, 'company_status': `In Administration`},
        {'company_name': `ROPE ACCESS MANAGEMENT SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROPEWORX LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROSE COTTAGE FARM LTD`, 'company_status': `In Administration`},
        {'company_name': `ROSEMARY WATER LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ROTHERHITHE ENTERPRISES LTD`, 'company_status': `In Administration`},
        {'company_name': `ROULSTONE INTERNATIONAL LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `ROUND HOUSE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROYALE PARK ESTATES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROYALE PARK HOME ESTATES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROYALE PARK HOMES LTD`, 'company_status': `In Administration`},
        {'company_name': `ROYALE PARKS (CHESHIRE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROYALE PARKS (CHRISTCHURCH) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROYALE PARKS (DEVON) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROYALE PARKS (DOLTON) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROYALE PARKS (DORSET) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROYALE PARKS (RECULVER) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROYALE PARKS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROYALE RESORTS 1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ROYALE RESORTS 2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RP REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RP2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RPS PRECISION ENGINEERING LTD`, 'company_status': `In Administration`},
        {'company_name': `RRL ACCESS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RRS (UK) LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `RRSS REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RSL REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RSM TOPJOBS LTD`, 'company_status': `In Administration`},
        {'company_name': `RUNWAVE LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `RUSH WORLDWIDE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RVA SURVEYORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RYAN FAMILY ESTATES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RYCON STEELS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `RYEDALE LOGISTICS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `S & I GROUNDWORKS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `S & L SERVICES (STOKE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `S C LYONS HAULAGE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `S D SAMUELS (SPECIAL PROJECTS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `S H 26 R LIMITED`, 'company_status': `In Administration`},
        {'company_name': `S L R (UK) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `S M ROOFING CONTRACTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `S RETAIL REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `S W B ENTERPRISES LTD`, 'company_status': `In Administration`},
        {'company_name': `S&P REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `S.D. ELECTRICAL AND MECHANICAL SERVICES LTD.`, 'company_status': `In Administration`},
        {'company_name': `S.D. TAYLOR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `S.J.B. INTERNATIONAL LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `S.TWO CONSULT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SAASLEADS.IO LTD`, 'company_status': `In Administration`},
        {'company_name': `SABRE STRUCTURES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `SAC HARDWARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SAFE HANDS PLANS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SAFE PERMANENT ACCESS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SAFEGUARD SVP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SAFETY CONTROL AND SUPPLIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SAINTS TRANSPORT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SALTEES PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SALTIRE INNS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `SALTNESS LTD`, 'company_status': `In Administration`},
        {'company_name': `SALVAGE IS SALVATION LTD`, 'company_status': `In Administration`},
        {'company_name': `SAM CARMICHAEL LOGISTICS LTD`, 'company_status': `In Administration`},
        {'company_name': `SAMBA PARTNERSHIP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SAN PAOLO RESTAURANTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SANDERSON PRECISION PRODUCTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SANIA (PH) LTD`, 'company_status': `In Administration`},
        {'company_name': `SANIA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SAPIENT COMMERCIAL ALUMINIUM GLAZING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SAPPHIRE CONTROLS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SARCON (NO. 177) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SATIS.AI LTD`, 'company_status': `In Administration`},
        {'company_name': `SAVANTS COVENANT ADVISORY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SAVANTS RESTRUCTURING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SAVING STREAM SECURITY HOLDING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SBBS BUILDING & SCAFFOLDING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SBBS HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SBERBANK CIB (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SBL CAMDEN 22 LTD`, 'company_status': `In Administration`},
        {'company_name': `SBL REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SC REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SCARLET TELEVISION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SCCL OPERATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SCENT GLOBAL LTD`, 'company_status': `In Administration`},
        {'company_name': `SCENTRICS INFORMATION SECURITY TECHNOLOGIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SCHOOL MILK UK LTD`, 'company_status': `In Administration`},
        {'company_name': `SCL OPERATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SCL REALISATIONS 2021 LTD`, 'company_status': `In Administration`},
        {'company_name': `SCM CONTRACTORS (SOUTHERN) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SCOTCH & SODA RETAIL UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SCOTIA BINDING SUPPLIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SCOTT AND WHITLAM LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `SCOTT PARK PRESTIGE HOMES LTD`, 'company_status': `In Administration`},
        {'company_name': `SCOTTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SCS LOGISTICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SD PAINTING CONTRACTORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SDN REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SE GROUP LTD`, 'company_status': `In Administration`},
        {'company_name': `SE-TEK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SEALAND COMMERCIAL PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SEAVIEW 123 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SECURE MORTGAGE CORPORATION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SECURITY PROTECTION GROUP LTD`, 'company_status': `In Administration`},
        {'company_name': `SEEDLING FINANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SEEWOO GLASGOW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SEIMTEC LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SELAZAR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SELESTI LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SELSDON ESTATES OP CO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SEMCO-UK GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SENTINEL BROADCAST LTD`, 'company_status': `In Administration`},
        {'company_name': `SENZER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SEODRAP LLP`, 'company_status': `In Administration`},
        {'company_name': `SEPHTON TRANSPORT LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `SERENE BEDS AND FURNITURE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SERVICESCALE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SETH LOVIS & CO SOLICITORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SEVCO 5112 LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `SEVEN TECHNOLOGIES GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SEVEN TECHNOLOGIES HOLDINGS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `SFG (NW) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SFPL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SHACKLETON, WINTLE & LANE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SHADE REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SHADOWPLAY SERIES HOLDINGS UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SHANTI ESTATES UNLIMITED, LTD`, 'company_status': `In Administration`},
        {'company_name': `SHARP INNOVATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SHAYLOR GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SHAYLOR HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SHELBY FINANCE LTD`, 'company_status': `In Administration`},
        {'company_name': `SHELF COMPANY 2002 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SHELLFISH EXPRESS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SHEPHERD COX HOTELS (GRANTHAM) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SHEPHERDSWELL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SHERWOOD COURT (NEWARK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SHERWOOD ELECTRICAL SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SHERWOOD OAK HOLDINGS LTD`, 'company_status': `In Administration`},
        {'company_name': `SHERWOOD OAK HOMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SHILLINGTON COLLEGE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SHISHO 2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SHOP FRONT (GROUP) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SHOWCASE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SIERRA ENERGY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SIGMACON (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SIGN NATURE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SIGN PLUS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SIGNATURE CAR PARK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SIGNATURE EDEN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SIGNATURE LIVING HOTEL LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `SIGNATURE LIVING LIFESTYLES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SIGNATURE LIVING PRESTON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SIGNATURE LIVING RESIDENTIAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SIGNATURE SHANKLY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SIGNATURE VICTORIA MILL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SILICON PRACTICE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SILVA TIMBER PRODUCTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SILVAN SELECT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SILVER CLOUDS LATER LIFE PLANNING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SILVERBOND ENTERPRISES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SIMON LEE GALLERY LTD`, 'company_status': `In Administration`},
        {'company_name': `SIMPLE ENERGY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SIMPLY FRESH TRAVEL LTD`, 'company_status': `In Administration`},
        {'company_name': `SITEBATCHED LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SKETCHLEY ENGINEERING CONSULTANTS LTD`, 'company_status': `In Administration`},
        {'company_name': `SKILLS MATTER LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `SKIN & TONIC LONDON LTD`, 'company_status': `In Administration`},
        {'company_name': `SKIPTON AND CRAVEN ACTION FOR DISABILITY`, 'company_status': `In Administration`},
        {'company_name': `SKIRLIE REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SKY APARTMENTS 2018 LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `SKYLARK GOLF & COUNTRY CLUB LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SLACK & PARR (INVESTMENTS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SLICETHEPIE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SM REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SMARTWAY ENTREPRENEURS LTD`, 'company_status': `In Administration`},
        {'company_name': `SMILE STORES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SMILESITE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SMITH BROTHERS (CONTRACTING) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SMITH, LAW & SHEPHERDS I.F.A LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SMOKE CLUB LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SNM PIPELINES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SNOWS BUSINESS FORMS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SOCIAL ENERGY LTD`, 'company_status': `In Administration`},
        {'company_name': `SOCIAL ENERGY SUPPLY LTD`, 'company_status': `In Administration`},
        {'company_name': `SODA FOLK LTD`, 'company_status': `In Administration`},
        {'company_name': `SOHO MUSIC CONSULTING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SOLLER FOUR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SOLO CAPITAL PARTNERS LLP`, 'company_status': `In Administration`},
        {'company_name': `SOLO GROUP (HOLDINGS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SOLO GROUP SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SOLSBURY SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SOLUBLUE LTD.`, 'company_status': `In Administration`},
        {'company_name': `SOMERSET ALES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SOMERSET GADGETS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SOMETHING CRAFTY LTD`, 'company_status': `In Administration`},
        {'company_name': `SONCO UK BIDCO LTD.`, 'company_status': `In Administration`},
        {'company_name': `SONDER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SOURCE PERSONNEL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SOUTH EASTERN ELECTRICAL PLC`, 'company_status': `In Administration`},
        {'company_name': `SOUTH WEST BATTERIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SOVA CAPITAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SPA EPOS SOLUTIONS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `SPAFIELD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SPANISH RESTAURANT GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SPARK ENERGY SYSTEMS LTD`, 'company_status': `In Administration`},
        {'company_name': `SPARKLESTARS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SPECIALISED CASTINGS LTD.`, 'company_status': `In Administration`},
        {'company_name': `SPECIALIST CARS OF MALTON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SPECIALIST SITE SERVICES LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `SPECIALITY FLATBREADS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SPECIALITY STORES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SPECTRUM PRINTING SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SPEEDCLAD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SPEEDMARCH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SPICE NATION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SPILLER & POWELL ENTERPRISES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `SPINKS THE CHEMISTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SPIRE LEATHER COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SPIRIT OF 48 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SPORTS NATIONWIDE LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `SPORTSSIDEKICK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SPRING CHICKEN DIRECT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SQ-M2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SQG REALISATIONS LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `SQUARE SHEET LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SSL 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ST ANNES LOCATION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ST JOHNS TW 2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ST JOHNS TW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ST LEDGER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STAMFORD PAPER COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STAND SAFE LTD`, 'company_status': `In Administration`},
        {'company_name': `STANIAN TRANSPORT LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `STANLEY MILL STROUD LTD`, 'company_status': `In Administration`},
        {'company_name': `STANTON BIKES LTD`, 'company_status': `In Administration`},
        {'company_name': `STAR LEISURE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STAR LOGISTICS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `STARKOLD BUILDING SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STARLEAF LTD`, 'company_status': `In Administration`},
        {'company_name': `STARTERMODE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STATMD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STATURE PR LTD`, 'company_status': `In Administration`},
        {'company_name': `STAXOWEB LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `STC MAINTENANCE LTD`, 'company_status': `In Administration`},
        {'company_name': `STEADS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `STEELSTRUCK LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `STEF CONCEPTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STELCON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STEPEK TRAVEL LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `STEPWELL (UK) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `STERLING FINANCIAL PRINT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STERLING SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STERLING TRUST CORPORATION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STEVE PALMER ELECTRICAL LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `STEVE PORTER TRANSPORT LTD`, 'company_status': `In Administration`},
        {'company_name': `STEVENAGE CIRCUITS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STILEBRIDGE GROUNDWORK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STILTON CATERING BUTCHERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STOCKTON HAULAGE IRELAND LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `STONE PLUS UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STONEWALL (TW) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STORMHARBOUR SECURITIES LLP`, 'company_status': `In Administration`},
        {'company_name': `STORMHARBOUR SECURITIES UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STORTFORD SHEET METAL LTD`, 'company_status': `In Administration`},
        {'company_name': `STOUR SIDE DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STOWIC LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `STR LOGISTICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STRAND CAPITAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STRAND PLAZA LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `STRAWBERRY PLACE DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STREAMLINE PRESS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STREETTEAM SOFTWARE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STRONG ENERGY RECOVERY LTD`, 'company_status': `In Administration`},
        {'company_name': `STROUD HEIGHTS CARAVAN PARK LTD`, 'company_status': `In Administration`},
        {'company_name': `STRUCTAL (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STRÖM TALENT UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STS (CLIPSTONE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STUDIO RETAIL GROUP PLC`, 'company_status': `In Administration`},
        {'company_name': `STUDIO TRI LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STURMINSTER UK LTD`, 'company_status': `In Administration`},
        {'company_name': `STYLE GROUP HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STYLE GROUP UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `STYLES & WOOD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SUGGEST LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `SUK OLDCO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SUK RETAIL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SUNDEALA LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SUNGARD AVAILABILITY SERVICES (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SUNNINGDALE HOUSE (HERITAGE FARM THREE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SUNNINGDALE HOUSE DEVELOPMENTS (EVANS FIELDS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SUNNINGDALE HOUSE DEVELOPMENTS (NORTH FORELAND) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SUNNINGDALE HOUSE DEVELOPMENTS (PLOVER ROAD) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SUNNINGDALE HOUSE DEVELOPMENTS (SANDGATE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SUNNINGDALE HOUSE DEVELOPMENTS (TW ONE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SUNNINGDALE HOUSE DEVELOPMENTS (WALMER) LTD`, 'company_status': `In Administration`},
        {'company_name': `SUNNINGDALE HOUSE DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SUNTOURS OF WITNEY LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `SUPPER DELIVERIES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `SUREGREEN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SURFDOME SHOP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SURVEY ROOFING GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SUSTAINABLE MARINE ENERGY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SUTTON BRIDGE POWER SYSTEMS (LONDON) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SV INNS LTD`, 'company_status': `In Administration`},
        {'company_name': `SVEN CHRISTIANSEN PLC`, 'company_status': `In Administration`},
        {'company_name': `SW TRADING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SWADDLE MICRO BREWERY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SWALA (PAEM) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SWAN STAFF RECRUITMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SWANGATE DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SWANSONS (LIGHTING AND ELECTRICAL) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SWC REALISATIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `SWEET BROTHERS EVENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SWIFT SCAFFOLD (MIDLANDS) LTD`, 'company_status': `In Administration`},
        {'company_name': `SWIM SMOOTH LTD`, 'company_status': `In Administration`},
        {'company_name': `SWITCH CONSULTING RECRUITMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SY FOODS RETAIL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SYGNET GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SYLVAWOOD FURNITURE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SYMBIO ENERGY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `SYNC. HQ LTD`, 'company_status': `In Administration`},
        {'company_name': `SYNC.MONEY UK LTD`, 'company_status': `In Administration`},
        {'company_name': `SYSTEM GROUP LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `SYSTEM WESSEX LTD`, 'company_status': `In Administration`},
        {'company_name': `T & A KERNOGHAN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `T AND P METCALFE AND SON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `T F S LOANS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `T G STAMPING AND MACHINING LTD.`, 'company_status': `In Administration`},
        {'company_name': `T T DEVELOPMENTS LTD`, 'company_status': `In Administration`},
        {'company_name': `T. WILSON & SONS (FARMERS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `T.A. BOXALL & COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `T4051 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TAILORED FIRE & SECURITY (LEEDS) LTD.`, 'company_status': `In Administration`},
        {'company_name': `TAILORED FIRE & SECURITY (MANCHESTER) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TAILORED FIRE & SECURITY GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TAISON CONTRACT LIGHTING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TAL REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TALKER TAILOR TROUBLE MAKER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TANNER BRODIN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TANREC LTD`, 'company_status': `In Administration`},
        {'company_name': `TAPPIT TECHNOLOGIES (UK) LTD.`, 'company_status': `In Administration`},
        {'company_name': `TARGETFOLLOW (CORNWALL) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `TARGETFOLLOW (HADEN)`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `TARGETFOLLOW (NEWARK) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `TAYLOR MARTIN RECRUITMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TAYLOR ROOFING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TAYLORS (LEICS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TAYSIDE AVIATION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TC-IT LTD`, 'company_status': `In Administration`},
        {'company_name': `TCA HOLDCO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TEAM PRECISION PIPE ASSEMBLIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TEAM1664 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TEAMS ROOFING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TECH-NOL (MIDLANDS) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `TECHNIQUE LEARNING SOLUTIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `TECHPLAS COLOURS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TEDIM INTERNATIONAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TEESSIDE CAR CENTRE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TEGAMUS LAW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TELEC NETWORKS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TELESTO MARKETS LLP`, 'company_status': `In Administration`},
        {'company_name': `TELEVIDEO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TELLING ARCHITECTURAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TEMPLAR CORPORATION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TEMPLE FINANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TESTERWORLD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TEXTILE ASSEMBLIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TG REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TGW DISTRIBUTION LTD`, 'company_status': `In Administration`},
        {'company_name': `THALIA INVESTMENTS LTD`, 'company_status': `In Administration`},
        {'company_name': `THAMES HOMES LTD`, 'company_status': `In Administration`},
        {'company_name': `THD REALISATIONS 2022 LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `THE AINSCOW HOTEL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE ALLBRIGHT GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE BAY HEALTH AND FITNESS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE BIG PRAWN CO. LTD.`, 'company_status': `In Administration`},
        {'company_name': `THE BLACK SHEEP BREWERY PLC`, 'company_status': `In Administration`},
        {'company_name': `THE BRACEBRIDGE RESTAURANT (2020) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE BRITISH HONEY COMPANY PLC`, 'company_status': `In Administration`},
        {'company_name': `THE BUTCHERY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE CHEESE MERCHANT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE COLLECTIVE (LIVING) GROUP LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `THE COLLECTIVE (LIVING) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `THE COLLECTIVE ACTON LTD`, 'company_status': `In Administration`},
        {'company_name': `THE COLLECTIVE ACTON RESIDENTIAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE COLLECTIVE KENTISH TOWN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE CORE COLLECTIVE HEALTH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE COVENTRY AND RUGBY HOSPITAL COMPANY (HOLDINGS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE CRAB COMPANY (SCOTLAND) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE DATEK GROUP LTD`, 'company_status': `In Administration`},
        {'company_name': `THE DELTIC GROUP HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE DELTIC GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE EDINBURGH KNITWEAR COMPANY LTD`, 'company_status': `In Administration`},
        {'company_name': `THE ENERGY GROUP LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `THE ENVIRONMENTAL & PROCESS ENGINEERING GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE FUNKY APPLIANCE COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE G & A PETTIGREW LLP`, 'company_status': `In Administration`},
        {'company_name': `THE GARDEN CIDER COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE GARDEN TRADING COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE GREAT ANNUAL SAVINGS COMPANY LTD`, 'company_status': `In Administration`},
        {'company_name': `THE GREAT BRITISH CHARCUTERIE CO. LTD`, 'company_status': `In Administration`},
        {'company_name': `THE GREAT GEORGE STREET PROJECT LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `THE GROUNDED EVENTS COMPANY LTD`, 'company_status': `In Administration`},
        {'company_name': `THE HAND MADE MATTRESS COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE HOUSE CROWD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE INCE GROUP PLC`, 'company_status': `In Administration`},
        {'company_name': `THE INDEPENDENT LIVING COMPANY (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE IRONGATE GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE ISLINGTON METAL WORKS LTD`, 'company_status': `In Administration`},
        {'company_name': `THE JOHN BANNER CENTRE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE LEEDS BREWERY COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE LONDON ACORN SCHOOL CIC`, 'company_status': `In Administration`},
        {'company_name': `THE MARINE & PROPERTY GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE MEATLESS FARM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE MERCURY RECRUITMENT NETWORK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE OPUS PARTNERSHIP LLP`, 'company_status': `In Administration`},
        {'company_name': `THE PEOPLE'S ENERGY COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE POLISH BAKERY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE PRINT FACTORY (LONDON) 1991 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE PRIORY HAYWARDS HEATH`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `THE PROTEIN ICE CREAM COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE PURE FEED COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE RACE CAR CENTRE LTD`, 'company_status': `In Administration`},
        {'company_name': `THE REDEMPTION FOOD COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE REGENERATIVE CLINIC LTD`, 'company_status': `In Administration`},
        {'company_name': `THE SEAT DESIGN COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE SIMON HORN NURSERY COLLECTION LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `THE SLIMMING CLINIC LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE SOPER GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE SOURCE (HASTINGS) HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE SOURCE (HASTINGS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE STEM GARDENS LTD`, 'company_status': `In Administration`},
        {'company_name': `THE SUSTAINABLE BATHROOM COMPANY LTD`, 'company_status': `In Administration`},
        {'company_name': `THE TRI STORE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THE UNDERWATER CENTRE`, 'company_status': `In Administration`},
        {'company_name': `THE VURGER CO LTD`, 'company_status': `In Administration`},
        {'company_name': `THE WATER CLUB LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `THE WEST BERKSHIRE BREWERY PLC`, 'company_status': `In Administration`},
        {'company_name': `THE WILD BEER CO LTD`, 'company_status': `In Administration`},
        {'company_name': `THE WINDOW GLASS COMPANY (BRISTOL) LTD`, 'company_status': `In Administration`},
        {'company_name': `THEATRE LINE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THEOBALDS PARK OP CO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THEON DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THESSCO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THEVEGANKIND LTD`, 'company_status': `In Administration`},
        {'company_name': `THGL REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THINK AGGREGATES & RECYCLING LTD`, 'company_status': `In Administration`},
        {'company_name': `THOMAS DORNAN (PRINTERS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THOMSON: HAYES RETAIL DISPLAY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `THOUGHT FASHION LTD`, 'company_status': `In Administration`},
        {'company_name': `THREXTON ROAD 123 LTD.`, 'company_status': `In Administration`},
        {'company_name': `THRIVE 24 LTD.`, 'company_status': `In Administration`},
        {'company_name': `TIBER CAPITAL LLP`, 'company_status': `In Administration`},
        {'company_name': `TIC TRADING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TIDAL ENERGY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TILE & FLOORING CENTRE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TILEWOOD PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TILLERY VALLEY FOODS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TILLINGBOURNE RYEWOOD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TILON C G LTD`, 'company_status': `In Administration`},
        {'company_name': `TIME & TIDE (BEDFORD) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `TIME GB (DR) LTD`, 'company_status': `In Administration`},
        {'company_name': `TIME GB (NORTH LODGE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TIME GB (SGC) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TIME GB (SHP) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TIME GB (SHP) OPCO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TIME GB GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TIME GB PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TIME GB PROPERTY GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TIME GB STOCK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TIME GROUP HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TIME RAINHAM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TITAN HOMES (PARTICK) LTD`, 'company_status': `In Administration`},
        {'company_name': `TMC GAS SERVICES LTD`, 'company_status': `In Administration`},
        {'company_name': `TMDP (SPECIAL PROJECTS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TML REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TNBC REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOATEE LTD`, 'company_status': `In Administration`},
        {'company_name': `TOBAR GROUP TRADING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TODS MURRAY LLP`, 'company_status': `In Administration`},
        {'company_name': `TOGETHER ENERGY (RETAIL) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOGETHER ENERGY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOKENHOUSE VB LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOLENT CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOLENT HOMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOLENT LIVING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOLENT PLC`, 'company_status': `In Administration`},
        {'company_name': `TOLENT SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOMLINSON'S DAIRIES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `TOMMY TUCKER PUBS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOOGOOD INTERNATIONAL TRANSPORT AND AGRICULTURAL SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOOL MANAGEMENT SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOOPLE.COM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOPART LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOPAZ DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOPSIDE GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TORPOINT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TORQUE BRANDS GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TORUS LOGISTICAL SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOTAL GLASS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOTAL HIGHWAY MAINTENANCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOTAL QUALITY SECURITY LTD`, 'company_status': `In Administration`},
        {'company_name': `TOTO ENERGY LTD.`, 'company_status': `In Administration`},
        {'company_name': `TOUCAN ENERGY HOLDINGS 1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOWER DEMOLITION (HOLDINGS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOWER PET PRODUCTS UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOWERINPUT RAIL SUPPLIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOWN MANOR DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TOWNHOUSE LONDON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TP REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TPM CONTRACTING  LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TPOINT SOLUTIONS  LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRACKWISE DESIGNS PLC`, 'company_status': `In Administration`},
        {'company_name': `TRAIDCRAFT PUBLIC LIMITED COMPANY`, 'company_status': `In Administration`},
        {'company_name': `TRAMPS (GY) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRANSFORM MEDICAL GROUP (CS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRANSTHOMAS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRAXX (AGGREGATES) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TREE OF LIFE UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TREKA BUS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRENCH NETWORKS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRENCHARD AVIATION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRENDBOLD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRESOR HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRG WIND LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRIB3 (LEEDS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRIB3 BRISTOL ONE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRIB3 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRIBUS (WATCHMAKERS) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `TRIBUS ENERGY SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRICON SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRICORN GROUP PLC`, 'company_status': `In Administration`},
        {'company_name': `TRICORN UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRIDENT (SCOTLAND) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRIDENT MACHINES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRIMOUNT PROPERTIES (BRICKFIELDS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRIMOUNT RESIDENTIAL (ASCOT) 2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRINITEQ SERVICE & SUPPLIES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `TRINITY HOTEL LTD`, 'company_status': `In Administration`},
        {'company_name': `TRINITY HOUSE PAINTINGS LTD`, 'company_status': `In Administration`},
        {'company_name': `TRINITY NURSING SERVICES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `TRINITY SHADE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRIPLE C FUNDING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRIYOGA (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TROIKA CONSTRUCT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TROSSACHS DISTILLERY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TROY FOODS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRU SPAS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRUCK AND TRAILER (BLACKBURN) LTD`, 'company_status': `In Administration`},
        {'company_name': `TRUCKCRAFT (NOTTINGHAM) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRUFAB TECHNOLOGIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRUMARK PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TRUPHONE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TTR PT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TTRC LTD`, 'company_status': `In Administration`},
        {'company_name': `TUDOR LANDSCAPES LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `TUDOR ROOFING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TUFFNELLS PARCELS EXPRESS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TURBINE EFFICIENCY GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TURBINE EFFICIENCY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TURBINE ENGINEERING DEVELOPMENTS LTD`, 'company_status': `In Administration`},
        {'company_name': `TURBO SYSTEMS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TURPIN DISTRIBUTION SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TUSCARAWAS 2 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TUTTONS BRASSERIE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TVIS REALISATIONS 2022 LTD`, 'company_status': `In Administration`},
        {'company_name': `TVM FASHION LAB LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `TWENTY 1 CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TWENTY 1 INTERIORS LTD`, 'company_status': `In Administration`},
        {'company_name': `TWIN TRADING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `TXU ACQUISITIONS LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `TXU EASTERN FINANCE (A) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `TXU EASTERN FINANCE (B) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `TXU EASTERN FUNDING COMPANY`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `TXU EUROPE LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `TXU FINANCE (NO.2) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `TYNE BANK BREWERY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UAVEND INVESTMENTS LLP`, 'company_status': `In Administration`},
        {'company_name': `UGO STORES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `UK ADDICTION TREATMENT GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UK CEMETERY HOLDINGS 1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UK ENERGY INCUBATOR HUB LTD`, 'company_status': `In Administration`},
        {'company_name': `UK HAIRDRESSERS 2109 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UK HAIRDRESSERS 2190 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UK HAIRDRESSERS 2910 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UK HAIRDRESSERS 2911 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UK PROTECTED LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UK WINDOWS & DOORS GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UKCPS REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UKFD REALISATIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `ULTRA SPORTS CLINIC LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ULTRA SUREFIRE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UNCLE BUCK FINANCE LLP`, 'company_status': `In Administration`},
        {'company_name': `UNDERHILL CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UNDERPINNED LTD`, 'company_status': `In Administration`},
        {'company_name': `UNICORN NUMBER 1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UNION DISTILLERS LTD`, 'company_status': `In Administration`},
        {'company_name': `UNION SQUARE MANCHESTER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UNIT STUDIOS LONDON LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UNITED PERFUMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UNITED PLASTICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UNIVERSAL SERVICES AND EQUIPMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UNIVERSE MEDIA GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `URBAN SPLASH HOUSE HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `URBAN SPLASH HOUSE INVESTMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `URBAN SPLASH HOUSE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `URBAN SPLASH MODULAR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UTILITIES MAINTENANCE CONCEPTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `UTILITY ALLIANCE LTD`, 'company_status': `In Administration`},
        {'company_name': `UTILITY POINT - TELECOMS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VALE BROTHERS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VALE OF MOWBRAY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VALESIDE CATERING CONTRACTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VALLEY ESTATES SOUTH WEST LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VALLEY PRODUCE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VANCOUVER GENERAL PARTNER LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `VANQUISH OPERATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VANTAGE POINT VENTURES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VAPORETTO LTD`, 'company_status': `In Administration`},
        {'company_name': `VAPOURCORE RETAIL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VATIC HEALTH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VAUGHAN ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VAULT LUXURY LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `VCPR LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VDPL REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VE GLOBAL UK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VECTOR PRECISION ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VEHICLE CONVERSION SPECIALISTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VEHVAC LTD`, 'company_status': `In Administration`},
        {'company_name': `VERSANT (SALCOMBE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VFS LEGAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VIA PROJECT 4 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VICTORIA OIL & GAS PLC`, 'company_status': `In Administration`},
        {'company_name': `VICTORIA PLUM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VIDYA HOLDINGS LTD`, 'company_status': `In Administration`},
        {'company_name': `VIOLA MONEY (EUROPE) LTD`, 'company_status': `In Administration`},
        {'company_name': `VIRTUAL BUSINESS OPERATION SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VITAL INFRASTRUCTURE ASSET MANAGEMENT (VIAM) LTD`, 'company_status': `In Administration`},
        {'company_name': `VIVARAIL LTD`, 'company_status': `In Administration`},
        {'company_name': `VIVIERO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VMOTO UK DISTRIBUTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VOGUE CONTRACTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VOLTA TRUCKS LTD`, 'company_status': `In Administration`},
        {'company_name': `VSC NO.1 LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `VSC NO.2 LIMITED`, 'company_status': `In Administration/Receiver Manager`},
        {'company_name': `VTB CAPITAL PLC`, 'company_status': `In Administration`},
        {'company_name': `VTL REALISATIONS 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `VU REALISATIONS 2022 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `W RESOURCES PLC`, 'company_status': `In Administration`},
        {'company_name': `W SERIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `W&C 2023 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `W.COM REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `W.STIRLAND LIMITED`, 'company_status': `In Administration`},
        {'company_name': `W2D2 LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `WACK WHOLESALE (BUILDING SUPPLIES) LTD`, 'company_status': `In Administration`},
        {'company_name': `WADE CERAMICS LTD.`, 'company_status': `In Administration`},
        {'company_name': `WAINWRIGHT DIRECT (TRADE) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WALKER FLOORING SOLUTIONS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `WALKERWELD ENGINEERING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WALL STREET PROPERTY INVESTMENT LTD`, 'company_status': `In Administration`},
        {'company_name': `WALLACE (GROUNDWORKS & CIVILS) LTD`, 'company_status': `In Administration`},
        {'company_name': `WALLS BAKERY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WALWORTH HOUSE PUB LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WARD BROS. (STEEL) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WAREHOUSE FASHIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WARMAWAY LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `WARMECH LTD`, 'company_status': `In Administration`},
        {'company_name': `WARMFIELD PROPERTIES LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `WASPS HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WATERFRONT HOTELS (SOUTHPORT) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WATERFRONT SOUTHPORT PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WATERLOO QUAY PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WATERLOO SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WATERSIDE CONSTRUCTION SOUTHWEST LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WATERSIDE CORNWALL GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WATERSIDE CORNWALL OPERATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WATERSIDE COUNTRY PARK LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WATERSIDE DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WATERSIDE VILLAGES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WATERSTONE HOMES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WATTLEHURST DEVELOPMENTS LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `WAVE REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WAVENEY FORK TRUCKS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WAY-ON FOODS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WB 2019 REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WBC 2019 REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WBED 2019 REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WBM 2019 REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WE ARE ASPIRE LTD`, 'company_status': `In Administration`},
        {'company_name': `WE ARE BAR GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WE WILL FILL IT LTD`, 'company_status': `In Administration`},
        {'company_name': `WEALTHTEK LIMITED LIABILITY PARTNERSHIP`, 'company_status': `In Administration`},
        {'company_name': `WEIR & MCQUISTON (SCOTLAND) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WEJO LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WELCOME NURSERIES LTD`, 'company_status': `In Administration`},
        {'company_name': `WELLINGTON PROPERTY COMPANY LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `WELLPAK (UK) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WELWYN H.P. FACILTIES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `WEM MARINE LTD`, 'company_status': `In Administration`},
        {'company_name': `WEMS REALISATIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `WENSO LTD`, 'company_status': `In Administration`},
        {'company_name': `WENSUM TAILORING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WERRENS LTD`, 'company_status': `In Administration`},
        {'company_name': `WESSEX BINDING (GUERNSEY) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WESSEX DRAWING SERVICES LTD`, 'company_status': `In Administration`},
        {'company_name': `WESSEX LABELS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WEST ONE TECHNOLOGY LTD`, 'company_status': `In Administration`},
        {'company_name': `WEST POINT DERIVATIVES LTD`, 'company_status': `In Administration`},
        {'company_name': `WEST YORKSHIRE WINDOWS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WESTBROOK INDUSTRIAL LTD`, 'company_status': `In Administration`},
        {'company_name': `WESTCARE (SOMERSET) LTD`, 'company_status': `In Administration`},
        {'company_name': `WESTFIELD SPORTS CARS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WESTLAKE CIVILS LTD`, 'company_status': `In Administration`},
        {'company_name': `WESTMINSTER & COUNTRY CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WESTPORT HOUSE (SCOTLAND) LTD.`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `WESTRIDGE CONSTRUCTION LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WESTWOOD GARDENS LTD`, 'company_status': `In Administration`},
        {'company_name': `WETHERALDS PAINTERS & BUILDING SERVICES LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `WFS TECHNOLOGIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WHARFSIDE REGENERATION (IPSWICH) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WHICHDEAL LTD`, 'company_status': `In Administration`},
        {'company_name': `WHICHDEAL PROPERTIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WHITE HORSE ELECTRICAL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WHITE HORSE SURVEYORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WHITE STONE ADVENTURE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WHITELEAF FURNITURE LTD`, 'company_status': `In Administration`},
        {'company_name': `WHITEMAN CONSTRUCTION (I.W.) LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `WHITTINGTON FACILITIES (HOLDINGS) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WHITTINGTON FACILITIES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WHITTLE  INTERIORS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WHOLESALE REALISATIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `WHSL REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WHYTE CRANE SERVICES LTD`, 'company_status': `In Administration`},
        {'company_name': `WIDDOWSON LOGISTICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WIGGLE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WILD-LIFE DAY CAMPS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WILDFIRE COMMS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WILDFIRE MEDIA LTD`, 'company_status': `In Administration`},
        {'company_name': `WILLIAM COATES LTD.`, 'company_status': `In Administration`},
        {'company_name': `WILLIAMS INDUSTRIAL SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WILMSLOW ELECTRICAL SUPPLIES LTD`, 'company_status': `In Administration`},
        {'company_name': `WILSON'S EXPRESS SAME DAY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WILTON PARK DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WILTON UK (GROUP) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WIN WIN PROPTECH LTD`, 'company_status': `In Administration`},
        {'company_name': `WINCHESTER MD LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `WINDHOIST LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WINDOW MACHINERY SALES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WINDSOR PROPERTIES (CARDIFF) LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `WINDWARD PROSPECTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WINEBUYERS GROUP LTD`, 'company_status': `In Administration`},
        {'company_name': `WINGMANS CHICKEN LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WINREM LTD`, 'company_status': `In Administration`},
        {'company_name': `WISEMANN LAW LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WJ LAW & CO LLP`, 'company_status': `In Administration`},
        {'company_name': `WL REALISATIONS (2023) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WL REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WN VTECH HOLDINGS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WN VTECH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WOLSINGHAM PHARMACY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WOOD FOR TREES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WOOD TREATMENT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WOODMACE LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WOODMACE PLANT LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WORKING ENVIRONMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WORLD CHAMPIONSHIP AIR RACE LTD`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `WORLDWIDE POWER LIMITED`, 'company_status': `Administration Order`},
        {'company_name': `WOVEN RUGS LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `WOVEN SOLUTIONS GROUP LTD`, 'company_status': `In Administration`},
        {'company_name': `WPHL 2009 REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WPHV LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WRB SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WRFC TRADING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WRIGHT EVENTS UK LTD`, 'company_status': `In Administration`},
        {'company_name': `WRIGHT VEHICLE SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WRIGHTS GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WRLD3D LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WRW CONSTRUCTION LTD`, 'company_status': `In Administration`},
        {'company_name': `WRYCOM GROUP LTD`, 'company_status': `In Administration`},
        {'company_name': `WSL REALISATIONS LTD`, 'company_status': `In Administration`},
        {'company_name': `WSPI PADLESWORTH LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WSPI WOODWARD LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WSTO REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WTS REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `WYG ENGINEERING (NORTHERN IRELAND) LIMITED`, 'company_status': `In Administration`},
        {'company_name': `XB REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `XPO APP LTD`, 'company_status': `In Administration`},
        {'company_name': `XPRESS CENTRES LTD`, 'company_status': `In Administration`},
        {'company_name': `XPRESS MONEY SERVICES LIMITED`, 'company_status': `In Administration`},
        {'company_name': `XPRESS VEHICLE SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `XYZ RAIL LIMITED`, 'company_status': `In Administration`},
        {'company_name': `YACHT SOLUTIONS WORLDWIDE LTD`, 'company_status': `In Administration`},
        {'company_name': `YE OLDE DOLPHIN LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `YES RECYCLING (FIFE) LTD`, 'company_status': `In Administration`},
        {'company_name': `YIELD (UK) LTD`, 'company_status': `In Administration`},
        {'company_name': `YM CHANTRY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `YM GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `YONA DEVELOPMENTS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `YORK GIN COMPANY LIMITED`, 'company_status': `In Administration`},
        {'company_name': `YORK MAILING (HOLDINGS) LTD`, 'company_status': `In Administration`},
        {'company_name': `YORK MAILING GROUP LIMITED`, 'company_status': `In Administration`},
        {'company_name': `YORK MAILING LIMITED`, 'company_status': `In Administration`},
        {'company_name': `YORKSHIRE & HUMBER LIMITED`, 'company_status': `In Administration`},
        {'company_name': `YORKSHIRE BOTTLE SOLUTIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `YORKSHIRE FARMS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `YORKSHIRE FUR FABRICS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `YOUNGS LAW LTD`, 'company_status': `In Administration`},
        {'company_name': `YOUR PRINT PARTNER LTD`, 'company_status': `In Administration`},
        {'company_name': `YOUR WELCOME LIMITED`, 'company_status': `In Administration`},
        {'company_name': `YPG CARPATHIA LTD`, 'company_status': `In Administration`},
        {'company_name': `YPG FABRIC RESIDENCE LTD`, 'company_status': `In Administration`},
        {'company_name': `YPG INVESTAR ISLINGTON HOUSE LTD`, 'company_status': `In Administration`},
        {'company_name': `YPP GROUNDWORKS & CIVILS LTD`, 'company_status': `In Administration`},
        {'company_name': `YUZU LTD`, 'company_status': `In Administration`},
        {'company_name': `Z2 REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ZAP 1 REALISATIONS LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ZENITH NUMBER 1 LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ZENITH REFITMENT SERVICES LIMITED`, 'company_status': `In Administration / Administrative Receiver`},
        {'company_name': `ZETA-PDM LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ZHALU LIMITED`, 'company_status': `In Administration`},
        {'company_name': `ZIDAC LABORATORIES LTD`, 'company_status': `In Administration`},
        {'company_name': `ZIPABOUT LIMITED`, 'company_status': `In Administration`},
        
    ], []);




    return (
        <div className={AdministrationCSS.div}>

            <div className={AdministrationCSS.content}>
    
            <h3 className={AdministrationCSS.displayCompanies}>{`Displaying companies ${currentCompanyIndex + 1} to ${Math.min(currentCompanyIndex + 20, companyData.length)} out of ${companyData.length}`}</h3>


             <table className={`${AdministrationCSS.adminTable} table`}>
                <thead>
                    <tr>
                        <th scope="col">Company Name</th>
                        <th scope="col">Company Status</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Loop through your data to generate rows */}
                    {Array.from({ length: 20 }, (_, index) => {
                        const dataIndex = currentCompanyIndex + index;
                        const company = companyData[dataIndex];

                        return (
                            <tr key={index}>
                                <td>{company?.company_name || 'N/A'}</td>
                                <td>{company?.company_status || 'N/A'}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <h4>Last data collection occured in Dec 2023.</h4>

            <div className={AdministrationCSS.navButtons}>
                <MyButton onClick={handleBackClick}>BACK</MyButton>    
                <MyButton onClick={handleNextClick}>NEXT</MyButton>   
            </div>

            



    
          
              
              
              

            </div>
        </div>
    )
}

