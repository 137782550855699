import SectionHeaderCSS from "./SectionHeader.module.css";


type SectionHeaderProps = {
    title: string;
}

export const SectionHeader = (props: SectionHeaderProps) => {
    return (
        <div className={SectionHeaderCSS.headerContainer}>
            
            <h2 className={SectionHeaderCSS.headerText}>{props.title}</h2>
            <div className={SectionHeaderCSS.headerLine}></div>
            
            
        </div>
    )
}