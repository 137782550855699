import styles from './SectionHeaderWithSub.module.css';
import { SectionHeader } from './SectionHeader';


type SectionHeaderWithSubProps = {
    title: string;
    subheading: string;
}



export const SectionHeaderWithSub = (props: SectionHeaderWithSubProps) => {
    return (
        <div className={styles.headerWithSubContainer}>
            <SectionHeader title={props.title}/>

            <p className={styles.headerSubText}>{props.subheading}</p>
        </div>
    )
}