import styles from './ProjectDescriptionNegotiation.module.css';
import { SectionHeader } from '../../SectionHeader';




type ProjectDescriptionProps = {    
    children?: any;
}
  
  

export const ProjectDescriptionNegotiation = (props: ProjectDescriptionProps) => {

    return (
        <div className={styles.div}>
            <SectionHeader title="Project Description"/>
            {/*Create div from projectDescriptions*/}


            <div className={styles.content}>

                <div>
                    <h3>Outline</h3>
                    <p>
                    For this project, I was hired by a venture capital firm to design and develop 
                    a landing page for a salary negotiation consulting service. To meet 
                    their demand for rapid development, I used Webflow, as required by the client.
                    The website features a robust analytics platforms that allows users to 
                    compare their current salary against industry and company benchmarks. It also
                    offers personalised negotiation services, empowering users to secure fairer
                    compensation based on data-driven insights. The end result is a clean, 
                    user-friendly interface with a focus on providing value through actionable
                    salary comparisons and negotiation assistance.

                    </p>
                </div>
                <div>
                    <h3>Performance Metric</h3>
                    <p>
                    The performance metric for this project was centered on meeting all the client's
                    requirements outlined in their specification. This involved delivering a 
                    design and a corresponding developed landing page. 
                    </p>
                </div>

                <div>
                    <h3>Pacted Website</h3>
                    <p>
                    By pressing the link, you can explore the fully developed site, showcasing how salary benchmarking and negotiation services are presented in a clean, user-friendly format.   </p>
                    
                    <a href="https://pacted.webflow.io/" className={styles.projectLink}>
                        <h5>Explore the Pacted Landing Page</h5>
                    </a>
                  
                </div>

        
              
            </div>            
            {props.children}
        </div>
    )
}