import AboutContainerCSS from './AboutContainer.module.css';

type AboutContainerProps = {
    children?: any;
}

export const AboutContainer = (props: AboutContainerProps) => {
    return (
        <div className={AboutContainerCSS.div}>
            {props.children}    
        </div>
    )
}