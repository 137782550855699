import FooterCSS from './FooterContainer.module.css';
import {NavBar} from '../header/NavBar';


type FooterContainerProps = {
    children?: any;
}

const sectionNames = [
    {name: "Terms of Use"},
    {name: "Privacy Policy"},
]

const copyRight = [{name: "Copyright @ Nikodem czarlinski 2023"},]

export const FooterContainer = (props: FooterContainerProps) => {
    return (
        <div className={FooterCSS.div}>
            <NavBar sectionNames={copyRight}/>
            <NavBar sectionNames={sectionNames}/>
            {props.children}
        </div>
    )
}