import styles from './Photo.module.css';

type PhotoProps = {
    children?: any;
}


export const Photo = (props: PhotoProps) => {
    return (
            
        <div className={styles.photoContainer}>
            <div className={styles.photo}>
                <img className={styles.profilePicture} src={require('../../assets/profile-picture.webp')} width="400px" height="400px" alt="Profile" />
            </div>
            <div className={styles.shadow}></div>
            <div className={styles.dotsOffset}>
                    {props.children}
            </div>
        </div>
        
    )
}