import React, { useEffect } from "react";
import WebFont from "webfontloader";



import "../../App.css"

import { MainContent } from "../../component/MainContent";
import { Background } from "../../component/Background";
import { Initials } from "../../component/header/Initials";
import { NavBar } from "../../component/header/NavBar";
import { HeaderContainer } from "../../component/header/HeaderContainer";
import { CircleContact } from "../../component/contact/CircleContact";
import { FooterContainer } from "../../component/footer/FooterContainer";
import { FrontPageContainer } from "../../component/projects/FrontPageContainer";
import { ProjectConclusion } from "../../component/projects/ProjectConclusions";
import { FrontPageInfoNegotiationService } from "../../component/projects/negotiation-assistance/FrontPageInfoNegotiation";
import { ProjectDescriptionNegotiation} from "../../component/projects/negotiation-assistance/ProjectDescriptionNegotiation";

const NegotiationAssistance = () => {
  // Async load fonts
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Inter:300,600,800']
      },
    });
  }, []);


  // Async load fonts
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Inter:300,600,800']
      },
    });
  }, []);

  const sectionNames = [
    { name: "About" },
    { name: "Experience" },
    { name: "My Service" },
    { name: "Portfolio" },
    { name: "Contact" },
  ]


  const projectConclusions = [
    {
      "title": "Results",
      "description": `The client was satisfied with the outcome after two design alterations. Overall, Webflow proved to be effective for rapid development and establishing product-market fit. However, it has limitations when it comes to scalability and optimizing website load times. Webflow’s approach—where many interactions are hidden under components—can make maintenance challenging and optimization difficult. This contrasts with coding websites from scratch, where the functionality of each part of the codebase is clear, and performance tuning is more straightforward.`
    },

  ]




  return (

    // <Button as="a" variant="primary">
    //   Button as link
    // </Button>
    <Background>

      <MainContent>

        <HeaderContainer>
          <Initials>N.C</Initials>
          <NavBar sectionNames={sectionNames} />
        </HeaderContainer>

        <FrontPageContainer>
          <FrontPageInfoNegotiationService />
        </FrontPageContainer>
      </MainContent>



      <div id="ProjectDescription">
        <div className="greyBackground">
          <MainContent>
            <ProjectDescriptionNegotiation>
            </ProjectDescriptionNegotiation>
          </MainContent>
        </div>
      </div>

      <div id="ProjectConclusions">
        <div className="whiteBackground">
          <CircleContact />
          <MainContent>
            <ProjectConclusion projectConclusions={projectConclusions}>
            </ProjectConclusion>
          </MainContent>
        </div>
      </div>

      <MainContent>
        <FooterContainer>
        </FooterContainer>
      </MainContent>

    </Background>




  )

};
export default NegotiationAssistance; 