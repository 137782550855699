import MainContentCSS from './MainContent.module.css'

type MainContentProps = {
    children: any;
}

export const MainContent = (props: MainContentProps) => {
    return (
    <div className={MainContentCSS.div}>
        {props.children}
    </div>
    )
}