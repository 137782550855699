import FrontPageCSS from '../FrontPageInfo.module.css';
import { MyButton } from '../../MyButton';


type FrontPageProps = {
    children?: any;
}

export const FrontPageInfoNegotiationService = (props: FrontPageProps) => {
    const handleClickViewDemo = () => {
        const section = document.getElementById("ProjectDescription");
        if (section){
            console.log(section);
            section.scrollIntoView({behavior: "smooth"} );
            console.log("scrolling to Portfolio");
        }
    }


    

    return (
       
            <div className={FrontPageCSS.div}>
                
                <div className={FrontPageCSS.verticalGap}></div>
                
                <div className={FrontPageCSS.jobTitle}>
                    <h1>
                        <span >Do you think you might be being paid less than you  </span>
                        <div></div>
                        <span className={FrontPageCSS.highlightContainer}>
                            <span className={FrontPageCSS.highlight}>
                            should?
                            </span>
                        </span>
                    </h1>

                </div>
                
                {props.children}
                
                <div className={FrontPageCSS.interests}>
                    <h2 className={FrontPageCSS.subheading}>
                    We compare your salary against millions of others in our database to work out what you should be paid.
                    </h2>
                    <div className={FrontPageCSS.buttonList}>
                        <MyButton onClick={handleClickViewDemo}>Let's take a look</MyButton>                             
                    </div>
                </div>
            </div>
       
         
    )
}