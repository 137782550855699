import HeaderContainerCSS from './HeaderContainer.module.css';

type HeaderContainerProps = {
    children?: any;
}

export const HeaderContainer = (props: HeaderContainerProps) => {
    return (
        <div id="header" className={HeaderContainerCSS.div}>
            {props.children}
        </div>
    )
}