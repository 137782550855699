import React, { useEffect } from "react";
import WebFont from "webfontloader";



import "../../App.css"

import { MainContent } from "../../component/MainContent";
import { Background } from "../../component/Background";
import { Initials } from "../../component/header/Initials";
import { NavBar } from "../../component/header/NavBar";
import { HeaderContainer } from "../../component/header/HeaderContainer";
import { CircleContact } from "../../component/contact/CircleContact";
import { FooterContainer } from "../../component/footer/FooterContainer";
import { CircleWithChildren } from "../../component/about/CircleWithChildren";
import { FrontPageContainer } from "../../component/projects/FrontPageContainer";
import { DemoContainer } from "../../component/projects/option-pricing/DemoContainer";
import { ProjectConclusion } from "../../component/projects/ProjectConclusions";
import { FrontPageInfoOptioPricing } from "../../component/projects/option-pricing/FrontPageInfoOptionPricing";
import { ProjectDescriptionOptions } from "../../component/projects/option-pricing/ProjectDescriptionOptions";



const OptionPricing = () => {
  // Async load fonts
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Inter:300,600,800']
      },
    });
  }, []);


  // Async load fonts
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Inter:300,600,800']
      },
    });
  }, []);

  const sectionNames = [
    { name: "About" },
    { name: "Experience" },
    { name: "My Service" },
    { name: "Portfolio" },
    { name: "Contact" },
  ]


  const projectConclusions = [
    {
      "title": "Results",
      "description": `The project successfully demonstrates real-time option pricing using the Black-Scholes model. It visualizes price sensitivity through a dynamic heatmap, offering insights into how volatility and strike price affect option values. Notably, the application runs efficiently on mobile devices, with real-time UI updates and responsive performance.`
    },
    {
      "title": "Future Work",
      "description": `In future work, the project will expand its capabilities by incorporating additional option pricing models, such as binomial models and Monte Carlo simulations. Integration of live market data will enable real-time input updates, enhancing the system's responsiveness to current market conditions. To improve the user experience, the platform will offer more customizable visualizations and a wider array of analytical tools, empowering users with greater flexibility in analysis. Performance optimization will also be a priority, focusing on handling larger datasets and more complex financial scenarios with increased efficiency.`
    },
  ]




  return (

    // <Button as="a" variant="primary">
    //   Button as link
    // </Button>
    <Background>

      <MainContent>

        <HeaderContainer>
          <Initials>N.C</Initials>
          <NavBar sectionNames={sectionNames} />
        </HeaderContainer>

        <FrontPageContainer>
          <FrontPageInfoOptioPricing />
        </FrontPageContainer>
      </MainContent>




      <div id="OptionsPricing">
        <CircleWithChildren>
          <MainContent>
            <DemoContainer>
            </DemoContainer>
          </MainContent>
        </CircleWithChildren>
      </div>

      <div id="ProjectDescription">
        <div className="greyBackground">
          <MainContent>
            <ProjectDescriptionOptions>
            </ProjectDescriptionOptions>
          </MainContent>
        </div>
      </div>

      <div id="ProjectConclusions">
        <div className="whiteBackground">
          <CircleContact />
          <MainContent>
            <ProjectConclusion projectConclusions={projectConclusions}>
            </ProjectConclusion>
          </MainContent>
        </div>
      </div>

      <MainContent>
        <FooterContainer>
        </FooterContainer>
      </MainContent>

    </Background>




  )

};
export default OptionPricing; 