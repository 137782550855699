import myServiceCSS from './MyService.module.css';
import { SectionHeader } from '../SectionHeader';
import React from 'react';
import { ReactComponent as IconMachineLearning } from '../../assets/icons/machine-learning.svg';
import { ReactComponent as IconDataAnalysis } from '../../assets/icons/data-analysis.svg';
import { ReactComponent as IconSoftwareEngineering } from '../../assets/icons/software-engineering.svg';
// import { ReactComponent as IconArrow} from '../../assets/icons/arrow-right.svg';

type MyServiceContainerProps = {
    children?: any;
}

export const MyServiceContainer = (props: MyServiceContainerProps) => {
    return (
        <div className={myServiceCSS.div}>
            <SectionHeader title="Services"/>
            <div className={myServiceCSS.content}>
                
                    <div className={myServiceCSS.service}>
                        <div className={myServiceCSS.icon}>
                            <IconMachineLearning/>
                        </div>    
                        <div className={myServiceCSS.serviceInfo}>
                            <h3 className={myServiceCSS.serviceTitle}>Machine Learning</h3>
                            <div className={myServiceCSS.line} />
                            {/* <IconArrow/> */}
                        </div>
                    </div>
               
                
                    <div className={myServiceCSS.serviceMiddle}>
                        <div className={myServiceCSS.icon}>
                        <IconDataAnalysis/>
                        </div>    
                        <div className={myServiceCSS.serviceInfo}>
                            <h3 className={myServiceCSS.serviceTitle}>Data Analysis</h3>
                            <div className={myServiceCSS.line} />
                            {/* <IconArrow/> */}
                        </div>
                    </div>
                
                
                    <div className={myServiceCSS.service}>
                        <div className={myServiceCSS.icon}>
                        <IconSoftwareEngineering/>
                        </div>    
                        <div className={myServiceCSS.serviceInfo}>
                            <h3 className={myServiceCSS.serviceTitle}>Software Engineering</h3>
                            <div className={myServiceCSS.line} />
                            {/* <IconArrow/> */}
                        </div>
                    </div>
                
                {/* <div className={myServiceCSS.service}>Service 3</div> */}
            </div>
        </div>

    )
}