import FrontPageCSS from '../FrontPageInfo.module.css';
import { MyButton } from '../../MyButton';


type FrontPageProps = {
    children?: any;
}

export const FrontPageInfoAdminData = (props: FrontPageProps) => {
    const handleClickViewQuiz = () => {
        const section = document.getElementById("AdministrationData");
        if (section){
            console.log(section);
            section.scrollIntoView({behavior: "smooth"} );
            console.log("scrolling to Portfolio");
        }
    }


    

    return (
       
            <div className={FrontPageCSS.div}>
                
                <div className={FrontPageCSS.verticalGap}></div>
                
                <div className={FrontPageCSS.jobTitle}>
                    <h1>
                        <span >Which companies are in </span>
                        <div></div>
                        <span className={FrontPageCSS.highlightContainer}>
                            <span className={FrontPageCSS.highlight}>
                            administration?
                            </span>
                        </span>
                    </h1>

                </div>
                
                {props.children}
                
                <div className={FrontPageCSS.interests}>
                    <h2 className={FrontPageCSS.subheading}>
                    When companies are in financial trouble and need to sell off their assets, 
                    buyers hold a strong position in negotiations. The attractiveness of these 
                    assets depends on how much debt and liabilities the company has. So, it's 
                    crucial to stay informed about companies going through financial difficulties.
                    </h2>
                    <div className={FrontPageCSS.buttonList}>
                        <MyButton onClick={handleClickViewQuiz}>Let's take a look</MyButton>                             
                    </div>
                </div>
            </div>
       
         
    )
}