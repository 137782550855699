import PortfolioCSS from "./PortfolioContainer.module.css";
import { SectionHeaderWithSub } from "../SectionHeaderWithSub";
import { ReactComponent as IconArrowWhite} from '../../assets/icons/arrow-right-white.svg';
import { ReactComponent as IconArrowBlue} from '../../assets/icons/arrow-right-hippie-blue.svg';
import { Link } from 'react-router-dom';

type PortfolioContainerProps = {
    children?: any;
}

export const PortfolioContainer = (props: PortfolioContainerProps) => {


    return (
        <div className={PortfolioCSS.div}>
            <SectionHeaderWithSub title="Portfolio" subheading="A glimpse of the projects I’ve been working on."/>
            <div className={PortfolioCSS.content}>
                {/* Hercules Cambridge */}
                <a href="https://www.herculescambridge.org.uk/" className={PortfolioCSS.projectLink}>
                    <div className={PortfolioCSS.project}>
                        <div className={PortfolioCSS.projectImageContainer}>
                            <img className={PortfolioCSS.projectImage} src={require('../../assets/portfolio/hercules-cambridge.webp')} width="400px" height="313px" alt="Hercules Cambridge" />
                        </div>
                        <div className={PortfolioCSS.projectInfoBlue}>
                            <h5 className={PortfolioCSS.projectTitle}>Hercules Cambridge</h5>
                            <br/>
                            <IconArrowWhite/>
                        </div>
                    </div> 
                </a>   
                {/* Autonomous Training in X-Ray Imaging Systems */}
                <a href="https://github.com/nczarli/autonomous-training-xray" className={PortfolioCSS.projectLink}>
                    <div className={PortfolioCSS.project}>
                        <div className={PortfolioCSS.projectImageContainer}>
                            <img className={PortfolioCSS.projectImage} src={require('../../assets/portfolio/x-ray.webp')} width="400px" height="313px" alt="Autonomous Training in X-Ray Imaging Systems" />
                        </div>
                        <div className={PortfolioCSS.projectInfoWhite}>
                            <h5 className={PortfolioCSS.projectTitle}>Autonomous Training in X-Ray Imaging Systems</h5>
                            <IconArrowBlue/>
                        </div>
                    </div>
                </a>
                {/* Finance Focused */}
                <a href="https://finfoc.com/" className={PortfolioCSS.projectLink}>
                    <div className={PortfolioCSS.project}>
                        <div className={PortfolioCSS.projectImageContainer}>
                        <img className={PortfolioCSS.projectImage} src={require('../../assets/portfolio/fin-foc.webp')} width="400px" height="313px" alt="Finance Focused" />
                        </div>
                        <div className={PortfolioCSS.projectInfoBlue}>
                            <h5 className={PortfolioCSS.projectTitle}>Finance Focused</h5>
                            <br/>
                            <IconArrowWhite/>
                        </div>
                    </div>
                </a>
                {/* LLM Science Exam */}
                <Link to="/projects/llm-science-exam" className={PortfolioCSS.projectLink}>
                    <div className={PortfolioCSS.project}>
                        <div className={PortfolioCSS.projectImageContainer}>
                        <img className={PortfolioCSS.projectImage} src={require('../../assets/portfolio/llm-science-exam.webp')} width="400px" height="313px" alt="Large language model science exam" />
                        </div>
                        <div className={PortfolioCSS.projectInfoWhite}>
                            <h5 className={PortfolioCSS.projectTitle}>Self Benchmarking in Large Language Models</h5>
                            <IconArrowBlue/>
                        </div>
                    </div>
                </Link>
                {/* Options Pricing */}
                <Link to="/projects/option-pricing" className={PortfolioCSS.projectLink}>
                    <div className={PortfolioCSS.project}>
                        <div className={PortfolioCSS.projectImageContainer}>
                        <img className={PortfolioCSS.projectImage} src={require('../../assets/portfolio/option-pricing.webp')} width="400px" height="313px" alt="Insolvency monitor" />
                        </div>
                        <div className={PortfolioCSS.projectInfoBlue}>
                            <h5 className={PortfolioCSS.projectTitle}>Option Pricer</h5>
                            <br />
                            <IconArrowWhite/>
                        </div>
                    </div>
                </Link>
                {/* Administration Data */}
                <Link to="/projects/administration-data" className={PortfolioCSS.projectLink}>
                    <div className={PortfolioCSS.project}>
                        <div className={PortfolioCSS.projectImageContainer}>
                        <img className={PortfolioCSS.projectImage} src={require('../../assets/portfolio/administration-data.webp')} width="400px" height="313px" alt="Insolvency monitor" />
                        </div>
                        <div className={PortfolioCSS.projectInfoWhite}>
                            <h5 className={PortfolioCSS.projectTitle}>Insolvency Monitor</h5>
                            <br />
                            <IconArrowBlue/>
                        </div>
                    </div>
                </Link>
                {/* Salary Advocacy Service */}
                <Link to="/projects/negotiation-assistance" className={PortfolioCSS.projectLink}>
                    <div className={PortfolioCSS.project}>
                        <div className={PortfolioCSS.projectImageContainer}>
                        <img className={PortfolioCSS.projectImage} src={require('../../assets/portfolio/negotiation-assistance.webp')} width="400px" height="313px" alt="Insolvency monitor" />
                        </div>
                        <div className={PortfolioCSS.projectInfoBlue}>
                            <h5 className={PortfolioCSS.projectTitle}>Negotiation Assistance</h5>
                            <br />
                            <IconArrowWhite/>
                        </div>
                    </div>
                </Link>
                
                {/* <div className={PortfolioCSS.project}>
                    <div className={PortfolioCSS.projectImage}></div>
                    <div className={PortfolioCSS.projectInfoWhite}>
                        <h5 className={PortfolioCSS.projectTitle}>Hercules Cambridge</h5>
                        <IconArrowBlue/>
                    </div>
                </div>
                <div className={PortfolioCSS.project}>
                    <div className={PortfolioCSS.projectImage}></div>
                    <div className={PortfolioCSS.projectInfoBlue}>
                        <h5 className={PortfolioCSS.projectTitle}>Hercules Cambridge</h5>
                        <IconArrowWhite/>
                    </div>
                </div>
                <div className={PortfolioCSS.project}>
                    <div className={PortfolioCSS.projectImage}></div>
                    <div className={PortfolioCSS.projectInfoWhite}>
                        <h5 className={PortfolioCSS.projectTitle}>Hercules Cambridge</h5>
                        <IconArrowBlue/>
                    </div>
                </div> */}
            </div>    
        </div>
    )
}