import React, {useEffect} from "react"; 
import WebFont from "webfontloader";

import { MyParticles } from "../component/particles/MyParticles";
import { ParticleContainer } from "../component/particles/ParticleContainer";

import "../App.css"

import { MainContent } from "../component/MainContent";
import { Background } from "../component/Background";
import { Initials } from "../component/header/Initials";
import { NavBar } from "../component/header/NavBar";
import { HeroInfo } from "../component/Info/HeroInfo";
import { HeroInfoContainer } from "../component/Info/HeroInfoContainer";
import { HeaderContainer } from "../component/header/HeaderContainer";
import { AboutContainer } from "../component/about/AboutContainer";
import { Photo } from "../component/about/Photo";
import { AboutInfo } from "../component/about/AboutInfo";
import { Dots } from "../component/about/Dots";
import { ExperienceContainer } from "../component/experience/ExperienceContainer";
import { MyServiceContainer } from "../component/myService/MyServiceContainer";
import { PortfolioContainer } from "../component/portfolio/PortfolioContainer";
import { ContactContainer } from "../component/contact/ContactContainer";
import { CircleContact } from "../component/contact/CircleContact";
import { FooterContainer } from "../component/footer/FooterContainer";
import { CircleWithChildren } from "../component/about/CircleWithChildren";


const Home: React.FC = () => { 
  // Async load fonts
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Inter:300,600,800']
      },    });
  }, []);

  
  const sectionNames = [
    {name: "About"},
    {name: "Experience"},
    {name: "My Service"},
    {name: "Portfolio"},
    {name: "Contact"},
  ]


  return (
      // <Button as="a" variant="primary">
      //   Button as link
      // </Button>
      <Background>
        
        <MainContent> 

          
            <HeaderContainer>
              <Initials>N.C</Initials>
              <NavBar sectionNames={sectionNames}/>
            </HeaderContainer>
          

          
      
          <HeroInfoContainer>
            
            <HeroInfo name={"Nikodem"} jobTitle="Software Engineer" >
            <ParticleContainer>
              <MyParticles/>
            </ParticleContainer> 
            </HeroInfo>
          </HeroInfoContainer>    
      
          

        </MainContent> 

        

        
      <div id="About">
        <CircleWithChildren>
          <MainContent>
            <AboutContainer>
                <Photo>          
                  <Dots/>
                </Photo>
                <AboutInfo 
                  heading="About Me " 
                  subheading={
                    <>
                      Hello! My name is Nikodem and I enjoy creating things. My interest in machine learning started in 2020 when I decided to enter the Citadel Datathon. I was fascinated by the idea of using data to make predictions and I have been hooked ever since. I have recently graduated from the University of Cambridge with a Masters in Computer and Information Engineering.
                      <br /><br />
                      Join me in exploring the boundless opportunities that the intersection of creativity and technology offers. Let's connect and collaborate!
                    </>
                  }
                  yearsOfExperience={3}
                  completedProjects={10}
                ></AboutInfo>
              </AboutContainer>
          </MainContent>
        </CircleWithChildren>
      </div>

      <div id="Experience">
        <div className="greyBackground">
          <MainContent>
            <ExperienceContainer>
            </ExperienceContainer>
          </MainContent>
        </div>
      </div>

      <div id="My Service">
        <MainContent>
            <MyServiceContainer>
            </MyServiceContainer>
          </MainContent> 
      </div>


      <div id="Portfolio">
        <div className="greyBackground">
          <MainContent>
            <PortfolioContainer>
            </PortfolioContainer>
          </MainContent>
        </div>
      </div>

      <div id="Contact">
        <div className="whiteBackground"> 
          <CircleContact/>
          <MainContent>
            <ContactContainer>
            </ContactContainer>
          </MainContent>
        </div>
      </div>
        
        <MainContent>
          <FooterContainer>
          </FooterContainer>
        </MainContent>

      </Background>




  )
  
}; 
export default Home; 