import AboutInfoCSS from './AboutInfo.module.css';



type AboutInfoProps = {
    heading: string;
    subheading: any;
    yearsOfExperience: number;
    completedProjects: number;
}

export const AboutInfo = (props: AboutInfoProps) => {
    return (
        <div className={AboutInfoCSS.infoContainer}>
            
            <h1 className={AboutInfoCSS.h1}>{props.heading}</h1>
            <h2 className={AboutInfoCSS.subheading}>{props.subheading}</h2>
            <div className={AboutInfoCSS.flexWrap}>
                <div className={AboutInfoCSS.keyStatistic}>
                    <h1 className={AboutInfoCSS.keyStatisticHeading}>{props.yearsOfExperience}+</h1>
                    <h2 className={AboutInfoCSS.keyStatisticSubheading}>Years of Experience</h2>
                </div>
                <div className={AboutInfoCSS.keyStatistic}>
                    <h1 className={AboutInfoCSS.keyStatisticHeading}>{props.completedProjects}+</h1>
                    <h2 className={AboutInfoCSS.keyStatisticSubheading}>Completed Projects</h2>
                </div>
            </div>

            {/* <div className={AboutInfoCSS.showCaseStatistics}>
                
            </div> */}
    
        </div>
    )
}