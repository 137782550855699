import styles from './ChartContainer.module.css';
import React, { useEffect , useState } from 'react';




interface sliceSizeProps {
    dataNum: number;
    dataTotal: number;
}

function sliceSize({dataNum, dataTotal} : sliceSizeProps) {
    return (dataNum / dataTotal) * 360 ;
}

interface SliceProps {
    sliceSize: number;
    offset: number;
    sliceID: string;
    color: string;
}
  

function Slice({ sliceSize, offset, sliceID, color }: SliceProps) {
    const sizeRotation = -179 + sliceSize;
    var offsetAccounted = offset - 1;

    const sliceStyle = {
        transform: `rotate(${offsetAccounted}deg) translate3d(0,0,0)`,
    };

    const spanStyle = {
        transform: `rotate(${sizeRotation}deg) translate3d(0,0,0)`,
        backgroundColor: color,
    };

    return (
        <div className={`${styles.slice} ${sliceID}`} style={sliceStyle}>
            <span style={spanStyle}></span>
        </div>
    );
}

interface PieChartProps {
    data: number[];
    legend: string[];
  }
  
function generateSlices(data: number[], colors: string[]): JSX.Element[] {
    var listTotal = data.reduce((total, value) => total + value, 0);
    let offset = 0;

    const slices: JSX.Element[] = [];
  
    if (listTotal === 0) {
        listTotal = 1;
        data = [0,1];
    }
    

    console.log('listTotal: ' + listTotal);

    data.forEach((dataNum, i) => {
        const size = sliceSize({dataNum, dataTotal: listTotal});
        var sliceID = `s${i}-0`;

        console.log(sliceID + ' ' + size); 
        

        if (size <= 179) {
            slices.push(<Slice key={sliceID} sliceSize={size} offset={offset} sliceID={sliceID} color={colors[i]} />);
            console.log('sliceSize: ' + size); 
            offset += size;
        } 
        else {
            slices.push(<Slice key={sliceID} sliceSize={179} offset={offset} sliceID={sliceID} color={colors[i]} />);
            // Next slice calculations
            let remainingSize = size - 179;
            offset += 179;
            let sliceCount = 1;

            while (remainingSize > 0) {
                const maxSize = Math.min(remainingSize, 179);
                const nextSliceID = `s${i}-${sliceCount}`;
                slices.push(<Slice key={nextSliceID} sliceSize={maxSize} offset={offset} sliceID={nextSliceID} color={colors[i]} />);
                remainingSize -= maxSize;
                offset += maxSize;
                sliceCount += 1;
            }
        }

    });
  
    return slices;
}
  
function PieChart({ data, legend }: PieChartProps) {

   

    const [primaryColor, setPrimaryColor] = useState('')
    const [secondaryColor, setSecondaryColor] = useState('')

    useEffect(() => {
        const root = document.documentElement;
        const primaryColorValue = getComputedStyle(root).getPropertyValue('--baltic-sea');
        const secondaryColorValue = getComputedStyle(root).getPropertyValue('--hippie-blue');

        // Set the value in the state
        setPrimaryColor(primaryColorValue);
        setSecondaryColor(secondaryColorValue);
    }, [])

    const colors = [
        primaryColor,
        secondaryColor,
    ];

    const slices = generateSlices(data, colors);

    

    return (
        <>
            <div  className={styles.pie}>
            {slices}
            </div>

            <div className={styles.divider}></div>

            {/* Iterate over the legend array and add the following  */}
            {/* Conditional rendering of the legend if the total value of 
            data is zero*/}
            {   
                data[0] + data[1] === 0 
                ?
                <ul className={styles.legend}>
                    {legend.map((legendItem, i) => (
                        <div key={`${i}-legend-item`}>  
                            <li  className={styles.legendItem}>
                                {/* Coloured circle */}
                                <div className={styles.circle} style={{ backgroundColor: colors[i] }}></div>
                                <h5 className={styles.legendText}>{legendItem}</h5>
                                <h5 className={styles.legendData}>0</h5>
                            </li>
                            <div className={styles.divider}></div>
                        </div> 
                    ))}
                </ul>
                :
                <>
                    <ul className={styles.legend}>
                        {legend.map((legendItem, i) => (
                            <div key={`${i}-legend-item`}>  
                                <li  className={styles.legendItem}>
                                    {/* Coloured circle */}
                                    <div className={styles.circle} style={{ backgroundColor: colors[i] }}></div>
                                    <h5 className={styles.legendText}>{legendItem}</h5>
                                    <h5 className={styles.legendData}>{data[i]}</h5>
                                </li>
                                <div className={styles.divider}></div>
                            </div> 
                        ))}
                    </ul>
                </>
            }
        </>
        
    );
}





type ChartContainerProps = {
    data?: number[];
    legend?: string[];
    children?: any;
}

export const ChartContainer = (props: ChartContainerProps) => {



    if (props.data == null) {
        var data = [10, 20];
    }
    else if (props.data[0] + props.data[1] === 0) {
        data = [0,0];
    }
    else {
        data = props.data;
    }

    if (props.legend == null) {
        var legend = ['Correct', 'Incorrect'];
    }
    else {
        legend = props.legend;
    }

    return (
        <div className={styles.div}>
            <div className={styles.header}>
                <h1>Test Scores</h1>
            </div>

            <div className={styles.divider}></div>

            <PieChart data={data} legend={legend}/>

            {props.children}
        </div>
    )
}