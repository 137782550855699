import CircleWChildrenCSS from './CircleWithChildren.module.css';

type CircleWithChildrenProps = {
    children: any;
};

export const CircleWithChildren = ({children}: CircleWithChildrenProps) => {
    return (
        <div>
            <div className={CircleWChildrenCSS.greyBackground}>
                <div className={CircleWChildrenCSS.circle}></div>
                {children}
            </div>
            
        </div>
        
    );
};
