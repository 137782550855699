import CircleCSS from './CircleContact.module.css';
import { DotsContact } from './DotsContact';

type CircleContactProps = {
    children?: any;
}

export const CircleContact = (props: CircleContactProps) => {
    return (
        <div className={CircleCSS.circleContainer}>
            <div className={CircleCSS.circleLeft}/>
            <div className={CircleCSS.circleRight}>
                <DotsContact></DotsContact>
            </div>     
           
        </div>

        

        
    )
}
