import NavBarCSS from "./NavBar.module.css"
import React from "react";


type SectionName = {
    name: string;
}

type ButtonListProps = {
    sectionNames: SectionName[];
}



export const NavBar = (props: ButtonListProps) => {
    const handleClick = (sectionName: string) => {
        const section = document.getElementById(sectionName);
        

        // Scroll to section if it exists on current page.
        // Else redirect to root page and open page at section.
        if (section){
            console.log(section);
            section.scrollIntoView({behavior: "smooth"});
            console.log("scrolling to " + sectionName);
        }
        else {
            // Redirect to the root page and open the page at the section using the hash fragment
            window.location.href = "/";
        }
    }

    return (
        <div className={NavBarCSS.divLarge}>
            {props.sectionNames.map((sectionName) => 
                <button 
                        key={sectionName.name}
                        className={NavBarCSS.button}
                        onClick={() => handleClick(sectionName.name)}
                >
                    {sectionName.name}
                </button>
            )}
        </div>
    )
}