import FrontPageCSS from '../FrontPageInfo.module.css';
import { MyButton } from '../../MyButton';


type FrontPageProps = {
    children?: any;
}

export const FrontPageInfoOptioPricing = (props: FrontPageProps) => {
    const handleClickViewDemo = () => {
        const section = document.getElementById("OptionsPricing");
        if (section){
            console.log(section);
            section.scrollIntoView({behavior: "smooth"} );
            console.log("scrolling to Portfolio");
        }
    }


    

    return (
       
            <div className={FrontPageCSS.div}>
                
                <div className={FrontPageCSS.verticalGap}></div>
                
                <div className={FrontPageCSS.jobTitle}>
                    <h1>
                        <span >Can This Toolkit Enhance Your Financial Analysis and Options  </span>
                        <div></div>
                        <span className={FrontPageCSS.highlightContainer}>
                            <span className={FrontPageCSS.highlight}>
                            Pricing Skills?
                            </span>
                        </span>
                    </h1>

                </div>
                
                {props.children}
                
                <div className={FrontPageCSS.interests}>
                    <h2 className={FrontPageCSS.subheading}>
                    This project employs the Black-Scholes model to 
                    calculate option prices using five key inputs: 
                    underlying asset price, strike price, time to expiration, 
                    volatility, and the risk-free interest rate. By compiling 
                    C++ code into WebAssembly, the model achieves an execution 
                    time of just <b>9 microseconds</b>.
                    </h2>
                    <div className={FrontPageCSS.buttonList}>
                        <MyButton onClick={handleClickViewDemo}>Let's take a look</MyButton>                             
                    </div>
                </div>
            </div>
       
         
    )
}