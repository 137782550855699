import DotsCSS from  './Dots.module.css';


export const Dots = () => {
    return (
        <div className={DotsCSS.dotsContainer}>
        </div>
    )
}

