import React, {useEffect} from "react"; 
import WebFont from "webfontloader";



import "../../App.css"

import { MainContent } from "../../component/MainContent";
import { Background } from "../../component/Background";
import { Initials } from "../../component/header/Initials";
import { NavBar } from "../../component/header/NavBar";
import { HeaderContainer } from "../../component/header/HeaderContainer";
import { CircleContact } from "../../component/contact/CircleContact";
import { FooterContainer } from "../../component/footer/FooterContainer";
import { CircleWithChildren } from "../../component/about/CircleWithChildren";
import { FrontPageContainer } from "../../component/projects/FrontPageContainer";
import { FrontPageInfoAdminData } from "../../component/projects/administration-data/FrontPageInfoAdminData";
import { AdministrationDataContainer } from "../../component/projects/administration-data/AdministrationDataContainer";
import { ProjectDescription } from "../../component/projects/ProjectDescription";
import { ProjectConclusion } from "../../component/projects/ProjectConclusions";




const AdministrationData = () => { 
  // Async load fonts
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Inter:300,600,800']
      },    });
  }, []);


  // Async load fonts
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Inter:300,600,800']
      },    });
  }, []);

  const sectionNames = [
    {name: "About"},
    {name: "Experience"},
    {name: "My Service"},
    {name: "Portfolio"},
    {name: "Contact"},
  ]

  const projectDescriptions = [
    {
        "title" : "Outline",
        "description" : `This project aims at displaying which companies have gone into administration.
                         While there is a possibility that assets may be sold at lower prices during administration, 
                         it's not a guaranteed outcome. The goal of the administrator is to achieve the best possible
                          outcome for creditors. Additionally, the sale of assets in administration is often conducted
                           through a transparent and legally regulated process to ensure fairness. Investors or buyers
                            looking to acquire assets from a company in administration should carefully assess the 
                            specific circumstances, conduct due diligence, and be aware of the associated risks and
                             opportunities. This information provides a valuable resource for stakeholders seeking 
                             insights into the dynamics of companies undergoing administration and facilitates
                              informed decision-making in the business landscape. `
    },
    {
      "title" : "Dataset",
      "description" : `The process begins with the submission of data requests to government sources, occurring at
                         a frequency of 600 requests per 5 minutes. As a result, it requires a substantial time 
                         investment of 14 hours to complete the comprehensive update of the entire database. 
                         Following this update, a comparison between the current database and its predecessor is conducted. 
                         The subsequent step involves filtering the updated database using criteria derived from the
                          information present in the previous version. In this way, we are able to retrieve which 
                          companies are currently in administration.
                       `
    }
]

const projectConclusions = [
    {
        "title" : "Results",
        "description" : `Our December findings revealed that approximately 3,500 out of 5.2 million UK firms
                         entered administration, shedding light on a notable aspect of the economic landscape 
                        and prompting a closer examination of the factors influencing this significant number.
                         In-depth analysis suggests that poor management decisions and intense market competition 
                         emerged as the most common contributing factors. Additionally, changes in consumer 
                         behaviour within the dynamically evolving market also played a pivotal role in shaping 
                         the outcomes observed. Understanding these dynamics is crucial for policymakers, 
                         businesses, and stakeholders to implement informed strategies and navigate the
                          challenges posed by the ever-changing economic environment.
        
        `
    },
    {
      "title" : "Future Work",
      "description" : `Looking forward, we aim to enhance the project's efficiency by optimizing the API's speed. 
                        One approach involves integrating the paid Datasetream service, ensuring live updates to 
                        the consumer API with any changes in the monitored database field. This enhancement will 
                        significantly reduce latency, providing users with real-time information more promptly.
                         This proactive step not only improves user experience but also positions the project to
                         adapt to evolving data demands efficiently, aligning with cutting-edge technologies.`
    },
]

 


  return (

      <Background>
        
        <MainContent> 

          <HeaderContainer>
            <Initials>N.C</Initials>
            <NavBar sectionNames={sectionNames}/>
          </HeaderContainer>
          
          <FrontPageContainer>
            <FrontPageInfoAdminData />
          </FrontPageContainer>   
        </MainContent> 

        

        
      <div id="AdministrationData">
        <CircleWithChildren>
          <MainContent>
            <AdministrationDataContainer>
              </AdministrationDataContainer>
          </MainContent>
        </CircleWithChildren>
      </div>

      <div id="ProjectDescription">
        <div className="greyBackground">
          <MainContent>
            <ProjectDescription projectDescriptions={projectDescriptions}>
            </ProjectDescription>
          </MainContent>
        </div>
      </div>

      <div id="ProjectConclusions">
        <div className="whiteBackground"> 
          <CircleContact/>
          <MainContent>
            <ProjectConclusion projectConclusions={projectConclusions}>
            </ProjectConclusion>
          </MainContent>
        </div>
      </div>
        
        <MainContent>
          <FooterContainer>
          </FooterContainer>
        </MainContent>

      </Background>




  )
  
}; 
export default AdministrationData; 