import styles from './HeatMap.module.css';
import React, { useEffect, useState,  } from 'react';

import Chart from 'react-apexcharts';



type DataInputsProps = {
    data: any;
    updateChartData: any;
    callData: number[];
    putData: number[];
    spotPriceArray: number[];
    volatilityPriceArray: number[];
    callDataSingle: number[];
    putDataSingle: number[];
    children?: any;
}

// Define types for Series
type SeriesData = {
    name: string;
    data: number[];
};

export const HeatMap = (props: DataInputsProps) => {
    const [callChartData, setCallChartData] = useState({

        series: [
                  {
                    name: 'Series 1',
                    data: [13, 29, 13, 32, 18] // Corresponding volatility values
                  },
                  {
                    name: 'Series 2',
                    data: [43, 35, 47, 38, 50] // Corresponding volatility values
                  },
                  {
                    name: 'Series 3',
                    data: [12, 22, 19, 26, 31] // Corresponding volatility values
                  },
                  {
                    name: 'Series 4',
                    data: [20, 33, 41, 29, 35] // Corresponding volatility values
                  },
                  {
                    name: 'Series 5',
                    data: [20, 13, 24, 30, 27] // Corresponding volatility values
                  }
                ],
        options: {
            chart: {
                id: 'call-spot-price-volatility-chart',
            },
            xaxis: {
                title: {
                    text: 'Spot Price',
                },
                categories: props.spotPriceArray, // Corresponding spot prices
            },
            yaxis: {
                title: {
                    text: 'Volatility',
                },
            },
            colors: ['#128FD9'], // Shades of blue from light to dark
            colorScale: {
                // Calculate the minimum and maximum values

                min: Math.min(...props.callData), // Set the minimum value for the color scale
                max: Math.max(...props.callData), // Set the maximum value for the color scale based on all series
                colors: ['#d4f0ff', '#128FD9', '#005f91'], // Shades of blue from light to dark
            },
            dataLabels: {
                enabled: true, // Enable data labels
                style: {
                    colors: ['#000'], // Set text color to black
                    fontSize: '12px', // Optional: adjust font size
                }
            },
        },
    });

    const [putChartData, setPutChartData] = useState({
        series: [
                  {
                    name: 'Series 1',
                    data: [13, 29, 13, 32, 18] // Corresponding volatility values
                  },
                  {
                    name: 'Series 2',
                    data: [43, 35, 47, 38, 50] // Corresponding volatility values
                  },
                  {
                    name: 'Series 3',
                    data: [12, 22, 19, 26, 31] // Corresponding volatility values
                  },
                  {
                    name: 'Series 4',
                    data: [20, 33, 41, 29, 35] // Corresponding volatility values
                  },
                  {
                    name: 'Series 5',
                    data: [20, 13, 24, 30, 27] // Corresponding volatility values
                  }
                ],
        options: {
            chart: {
                id: 'put-spot-price-volatility-chart',
            },
            xaxis: {
                title: {
                    text: 'Spot Price',
                },
                categories: props.spotPriceArray, // Corresponding spot prices
            },
            yaxis: {
                title: {
                    text: 'Volatility',
                },
            },
            colors: ['#FF5733'], // Shades of red for the Put heatmap
            colorScale: {
                min: Math.min(...props.putData), // Set the minimum value for the color scale
                max: Math.max(...props.putData), // Set the maximum value for the color scale based on all series
                colors: ['#fde2e2', '#FF5733', '#b62d2d'], // Shades of red from light to dark
            },
            dataLabels: {
                enabled: true, // Enable data labels
                style: {
                    colors: ['#000'], // Set text color to black
                    fontSize: '12px', // Optional: adjust font size
                }
            },
            
        },
    });

    // Update Call Heatmap data
    useEffect(() => {
        if (props.spotPriceArray.length === 0 || props.callData.length === 0) {
            return; // Do nothing if there's no data
        }

        const numSeries = props.volatilityPriceArray.length;
        const seriesData: SeriesData[] = [];

        for (let i = 0; i < numSeries; i++) {
            const data = props.callData
                .slice(i * props.spotPriceArray.length, (i + 1) * props.spotPriceArray.length)
                .map((value) => parseFloat(value.toFixed(2)));

            seriesData.push({
                name: `${props.volatilityPriceArray[i].toFixed(2)}`,
                data: data,
            });
        }

        setCallChartData((prevChartData) => ({
            ...prevChartData,
            series: seriesData,
            options: {
                ...prevChartData.options,
                xaxis: {
                    ...prevChartData.options.xaxis,
                    categories: props.spotPriceArray,
                },
            },
        }));
    }, [props.spotPriceArray, props.callData, props.volatilityPriceArray]);

    // Update Put Heatmap data
    useEffect(() => {
        if (props.spotPriceArray.length === 0 || props.putData.length === 0) {
            return; // Do nothing if there's no data
        }

        const numSeries = props.volatilityPriceArray.length;
        const seriesData: SeriesData[] = [];

        for (let i = 0; i < numSeries; i++) {
            const data = props.putData
                .slice(i * props.spotPriceArray.length, (i + 1) * props.spotPriceArray.length)
                .map((value) => parseFloat(value.toFixed(2)));

            seriesData.push({
                name: `${props.volatilityPriceArray[i].toFixed(2)}`,
                data: data,
            });
        }

        setPutChartData((prevChartData) => ({
            ...prevChartData,
            series: seriesData,
            options: {
                ...prevChartData.options,
                xaxis: {
                    ...prevChartData.options.xaxis,
                    categories: props.spotPriceArray,
                },
            },
        }));
    }, [props.spotPriceArray, props.putData, props.volatilityPriceArray]);


   

    return (
        <div className={styles.div}>
            <div className={styles.heatmapContainer}>
                <div className={styles.header}>
                    <h1>Call Price Heatmap</h1>
                    <Chart
                        options={callChartData.options}
                        series={callChartData.series}
                        type="heatmap"
                        height={400}
                    />
                </div>
            </div>
            <div className={styles.heatmapContainer}>
                <div className={styles.header}>
                    <h1>Put Price Heatmap</h1>
                    <Chart
                        options={putChartData.options}
                        series={putChartData.series}
                        type="heatmap"
                        height={400}
                    />
                </div>
            </div>

            <div className={styles.prices}>
                <div className={styles.callPrice}>
                    <h2>Call Price</h2>
                    <h2>${(props.callDataSingle && props.callDataSingle[0] !== undefined ? props.callDataSingle[0].toFixed(2) : 0)} </h2>
                </div>

                <div className={styles.putPrice}>
                    <h2>Put Price</h2>
                    <h2>${(props.putDataSingle && props.putDataSingle[0] !== undefined ? props.putDataSingle[0].toFixed(2) : 0)}</h2>
                </div>

            </div>

           

          
            
            
       

     

            {props.children}
        </div>
    )
}