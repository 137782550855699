import React, {useEffect} from "react"; 
import WebFont from "webfontloader";



import "../../App.css"

import { MainContent } from "../../component/MainContent";
import { Background } from "../../component/Background";
import { Initials } from "../../component/header/Initials";
import { NavBar } from "../../component/header/NavBar";
import { HeaderContainer } from "../../component/header/HeaderContainer";
import { CircleContact } from "../../component/contact/CircleContact";
import { FooterContainer } from "../../component/footer/FooterContainer";
import { CircleWithChildren } from "../../component/about/CircleWithChildren";
import { FrontPageContainer } from "../../component/projects/FrontPageContainer";
import { FrontPageInfo } from "../../component/projects/FrontPageInfo";
import { ExamContainer } from "../../component/projects/science-exam/ExamContainer";
import { ProjectDescription } from "../../component/projects/ProjectDescription";
import { ProjectConclusion } from "../../component/projects/ProjectConclusions";




const LLMScienceExam = () => { 
  // Async load fonts
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Inter:300,600,800']
      },    });
  }, []);


  // Async load fonts
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Inter:300,600,800']
      },    });
  }, []);

  const sectionNames = [
    {name: "About"},
    {name: "Experience"},
    {name: "My Service"},
    {name: "Portfolio"},
    {name: "Contact"},
  ]

  const projectDescriptions = [
    {
        "title" : "Outline",
        "description" : `Driven by the inspiration drawn from the remarkable self-benchmarking potential of 
                         Large Language Models (LLMs), this project embarks on an exploration of a captivating
                         question: Can question-answering models of smaller structure surpass their significantly
                         larger counterparts that specialize in question creation? `
    },
    {
      "title" : "Dataset",
      "description" : `The dataset for this project was generated by giving GPT 3.5 snippets of text on a range of
                       scientific topics pulled from Wikipedia, and asking it to write multiple choice questions
                       and providing the correct answer. <br/><br/>
                       
                       To further enhance the performance of the smaller model, additional datasets were collected 
                       and used for fine-tuning. These additional datasets significantly expanded the pool of sample 
                       questions, increasing the total number of questions from the initial 200 to an impressive 142,338. 
                       This extensive dataset allowed for more comprehensive training and refinement of the smaller 
                       model's ability to generate and answer multiple-choice questions across various scientific domains.`
    },
    {
      "title" : "Performance Metric",
      "description" : `The evaluation metric is the Mean Average Precision at 3 (MAP@3), which is the average of the
                       precision at the first three positions of the ranked list of answers.
                       

                       
                       The MAP@3 is a measure of
                       how well the model performs at the task of ranking the correct answer in the top three positions.`,
      "equation" : `\\text{MAP@3} = \\frac{1}{U} \\sum_{u=1}^U \\sum_{k=1}^{min(n,3)} P(k) \\times rel(k)`,

    }
]

const projectConclusions = [
    {
        "title" : "Results",
        "description" : `The smaller question-answering model achieved a notable MAP@3 score of 0.757,
                         showcasing its impressive performance in ranking correct answers within the top
                         three positions. The success of this smaller model indicates that it is possible
                         to distill the knowledge and capabilities of larger models into more compact 
                         versions without sacrificing performance and generalisability. <br/><br/>
                         
                         The most important takeaway from this project is that LLMs are capable of 
                         self-benchmarking. Therefore, it is possible to continously improve the
                         performance of LLMs by using adaptive self-evaluation in model training.`
    },
    {
      "title" : "Future Work",
      "description" : `The achievement of the smaller question-answering model represents a 
                       significant milestone in our exploration of model compression and self-benchmarking.   
                       This suggests that the future of LLM development lies in 
                       harnessing the power of adaptive self-evaluation. Moving forward,
                       our research will focus on refining and automating the self-benchmarking 
                       process, enabling models to continously enhance their performance across
                       diverse taksks and domains. We aim to investigate how self-benchmarking
                       can be applied not only to question-answering models, but also to a 
                       broader spectrum of natural language processing tasks. Ultimately, we
                       hope to develop an AI agent that can address a wide range of real-world
                       challenges while minimizing human intervention.`
    },
]

 


  return (

      // <Button as="a" variant="primary">
      //   Button as link
      // </Button>
      <Background>
        
        <MainContent> 

          <HeaderContainer>
            <Initials>N.C</Initials>
            <NavBar sectionNames={sectionNames}/>
          </HeaderContainer>
          
          <FrontPageContainer>
            <FrontPageInfo />
          </FrontPageContainer>   
        </MainContent> 

        

        
      <div id="ScienceExam">
        <CircleWithChildren>
          <MainContent>
            <ExamContainer>
              </ExamContainer>
          </MainContent>
        </CircleWithChildren>
      </div>

      <div id="ProjectDescription">
        <div className="greyBackground">
          <MainContent>
            <ProjectDescription projectDescriptions={projectDescriptions}>
            </ProjectDescription>
          </MainContent>
        </div>
      </div>

      <div id="ProjectConclusions">
        <div className="whiteBackground"> 
          <CircleContact/>
          <MainContent>
            <ProjectConclusion projectConclusions={projectConclusions}>
            </ProjectConclusion>
          </MainContent>
        </div>
      </div>
        
        <MainContent>
          <FooterContainer>
          </FooterContainer>
        </MainContent>

      </Background>




  )
  
}; 
export default LLMScienceExam; 