import styles from './ProjectDescription.module.css';
import { SectionHeader } from '../SectionHeader';
import { BlockMath } from 'react-katex';




type ProjectDescriptionProps = {    
    projectDescriptions?: {
        title: string;
        description: string;
        equation?: string;
    }[];
    children?: any;
}


function StringToBlockMath(props: any){
    return(
        <BlockMath math={props.string}/>
    )
}
  
  

export const ProjectDescription = (props: ProjectDescriptionProps) => {

    return (
        <div className={styles.div}>
            <SectionHeader title="Project Description"/>
            {/*Create div from projectDescriptions*/}



            <div className={styles.content}>
                {props.projectDescriptions?.map((item, index) => (
                    <div key={index}>
                        <h3>{item.title}</h3>
                        {/* If <br/><br/> found in item.description add a
                            paragraph break else return standard paragraph.*/}
                        {item.description.includes("<br/><br/>") ? (
                            item.description.split("<br/><br/>").map((item, index) => (
                                <p key={index}>{item}</p>
                            ))
                        ) : (
                            <p>{item.description}</p>
                        )}
                        {/* If item.equation is not null then return a
                            BlockMath component with the equation.*/}
                        {item.equation != null && (

                            <div className={styles.equation}>
                                <StringToBlockMath string={item.equation} />
                            </div>
                            
                            
                        )}
                    </div>
                ))}
            </div>            
            {props.children}
        </div>
    )
}